import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

import { useTheme } from '@mui/system';

export type VariantBoxProps = BoxProps & {
  variant?: 'item' | 'box' | 'row-nowrap' | 'row-joined' | 'column'; // Define the allowed variant values
};

const VariantBox: React.FC<VariantBoxProps> = ({ variant = 'default', sx = {}, ...props }) => {
  const theme = useTheme();

  // Safely access styleOverrides and variants
  const componentConfig = theme.components?.VariantBox || {};
  const styleOverrides = componentConfig.styleOverrides || {};
  const variants = componentConfig.variants || [];

  // Find the specific variant style if it exists
  const variantStyle = variants.find((v: any) => v.props.variant === variant)?.style || {};
  // console.log(`Warning! No variant defined for ${variant}`)

  // Merge styles: styleOverrides -> variant-specific -> prop sx
  const styles = {
    ...styleOverrides.root,
    ...variantStyle,
    ...sx,
  };
  return <Box sx={styles} {...props} />;
};

export default VariantBox;