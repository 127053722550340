import React, { useState, useMemo } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { BusinessExtended, MaturityModel } from "../../model/ScaleTypes";
import { PlotlyChart, PlotlyChartParams } from "./PlotlyCharts";
import { Layout } from "plotly.js";
import dayjs from "../../configuration/configuredDayjs";
import { SET_COLORS } from "../Colors";

type OptionKey = 'Weighted Potential' | 'Maturity' | 'Business Potential';

interface ValueFunctionSelectorProps {
  onChange: (valueFunctionName: string) => void;
  value: string;
}

export const PortfolioBarChartOptions: { [key: string]: BusinessToNumber } = {
  'Weighted Potential': (business: BusinessExtended, maturityModel: MaturityModel) => {
    if (!business) return 0;
    return (business.score ?? 0) / maturityModel.maxLevel * (Number(business.businessPotential) ?? 0);
  },
  'Maturity': (business: BusinessExtended, maturityModel: MaturityModel) => {
    if (!business) return 0;
    return business.score ?? 0;
  },
  'Business Potential': (business: BusinessExtended, maturityModel: MaturityModel) => {
    if (!business) return 0;
    return Number(business.businessPotential) ?? 0;
  },
};

export const ValueFunctionSelector: React.FC<ValueFunctionSelectorProps> = ({
  onChange,
  value,
}) => {
  const options = PortfolioBarChartOptions;

  // Type guard function
  function isValidOptionKey(key: string): key is OptionKey {
    return Object.keys(options).includes(key);
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newSelectedOption = event.target.value as string;
    onChange(newSelectedOption);
  };

  return (
    // same code here
    <FormControl fullWidth>
      <InputLabel id="value-function-select-label">Value Function</InputLabel>
      <Select
        labelId="value-function-select-label"
        id="value-function-select"
        value={value}
        label="Value Function"
        //@ts-ignore
        onChange={handleChange}
      >
        {/* Use OptionKey for mapping too */}
        {Object.keys(options).map((optionKey) => (
          <MenuItem key={optionKey} value={optionKey as OptionKey}>
            {optionKey}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// --- Portfolio Bar Chart and Other Components (No Changes Needed Here) ---
//Keep PortfolioChartWithSelector and createPortfolioBarChartData as they are in the previous example.

interface PortfolioChartWithSelectorProps {
  maturityModel: MaturityModel;
  projectsByMonth: { [key: string]: (BusinessExtended | null)[] };
  initialValueFunction: string;
}

export const PortfolioChartWithSelector: React.FC<PortfolioChartWithSelectorProps> = ({
  maturityModel,
  projectsByMonth,
  initialValueFunction
}) => {
  const [selector, setSelector] = useState<string>(initialValueFunction);

  const barData = useMemo(() => {
    return createPortfolioBarChartData({
      maturityModel,
      businessValuesByDate: projectsByMonth,
      valueFunction: PortfolioBarChartOptions[selector],
    });
  }, [maturityModel, projectsByMonth, selector]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ValueFunctionSelector
          onChange={setSelector}
          value={initialValueFunction}
        />
      </Grid>
      <Grid item xs={12}>
        <PlotlyChart params={barData} />
      </Grid>
    </Grid>
  );
};

export type BusinessToNumber = (business: BusinessExtended, maturityModel: MaturityModel) => number;

export interface PortfolioBarChartProps {
  width?: string,
  height?: string,
  /**
   * Maturity Model information
   */
  maturityModel: MaturityModel,
  businessValuesByDate: {
    [dateString: string]: (BusinessExtended | null)[];
  },
  valueFunction: BusinessToNumber
}

/**
 * Returns data and layout needed for MaturityChart
 * @param param0 
 * @returns 
 * @throws Error business.assessment_missing if there is no assessment data available.
 */
export const createPortfolioBarChartData = ({
  maturityModel,
  businessValuesByDate,
  valueFunction,
}: PortfolioBarChartProps): PlotlyChartParams => {
  const data: Plotly.Data[] = [];
  const dates = Object.keys(businessValuesByDate);
  const phase = maturityModel.phases
  const usedFunction = valueFunction;

  phase?.sort((a, b) => a.firstLevel - b.firstLevel).forEach((phase, index) => {

    const inPhaseFilter = (business: BusinessExtended | null) => {
      if (!business) return false;
      if (business.score >= phase.firstLevel && business.score < phase.lastLevel) return true;
      return false;
    }

    var trace: Plotly.Data = {
      x: dates.reverse().map(date => dayjs(date).format("YYYY-MMM")),
      y: dates.map(date => {
        var total = 0;
        businessValuesByDate[date].filter(inPhaseFilter).forEach((business) => {
          if (business === null) return;
          if (!business.score) return;
          total += usedFunction(business, maturityModel);
        })
        return total;
      }),
      name: phase.name,
      type: 'bar',
      marker: {
        color: SET_COLORS[index]
      }
    };
    data.push(trace);
  })

  var layout: Partial<Layout> = {
    barmode: 'stack',
    height: 400
  }

  return {
    data,
    layout
  }

}