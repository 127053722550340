import dayjs from "../configuration/configuredDayjs";


export const FORMAT_QUARTER = "YYYY [Q]Q";
export const FORMAT_DAY = "YYYY-MM-DD";


export function addMonths(date: Date, count: number): Date {
  return dayjs(date).add(count, "months").toDate();
}

export function subMonths(date: Date, count: number): Date {
  return dayjs(date).subtract(count, "months").toDate();
}

export function endOfMonth(date: Date): Date {
  return dayjs(date).endOf("month").toDate();
}

export function generateEndOfPeriodArray(n: number, period: dayjs.ManipulateType, until: dayjs.Dayjs = dayjs()): Date[] {
  const endsOfQuarters: Date[] = [];
  const date = until.endOf(period);

  for (let i = 0; i < n; i++) {
    endsOfQuarters.push(date.subtract(i + 1, period).toDate());
  }
  return endsOfQuarters;
}

export function differenceInDays(date1: Date, date2: Date): number {
  return dayjs(date1).diff(date2, "days")
}

export function closestIndexTo(targetDate: Date, datesArray: Date[]) {
  if (!targetDate || !datesArray || datesArray.length === 0) {
    return -1; // Return -1 if input is invalid
  }

  let closestIndex = -1;
  let closestDiff = Infinity;

  for (let i = 0; i < datesArray.length; i++) {
    const diff = Math.abs(dayjs(targetDate).diff(dayjs(datesArray[i])));
    if (diff < closestDiff) {
      closestDiff = diff;
      closestIndex = i;
    }
  }

  return closestIndex;
}

export function max(datesArray: Date[]): Date {
  // Use a far future date as the target date
  const farFutureDate = '2300-12-31';

  const closestIndex = closestIndexTo(new Date(farFutureDate), datesArray);

  if (closestIndex === -1) {
    return new Date();
  }

  return dayjs(datesArray[closestIndex]).toDate();
}

export function min(datesArray: Date[]): Date {
  const farFutureDate = '1900-12-31';

  const closestIndex = closestIndexTo(new Date(farFutureDate), datesArray);

  if (closestIndex === -1) {
    return new Date();
  }

  return dayjs(datesArray[closestIndex]).toDate();
}

export function average(...dates: string[]): Date {
  var totalMillis = 0;

  dates.forEach(date => {
    totalMillis += dayjs(date).valueOf();
  });

  return dayjs(totalMillis / dates.length).toDate();
}

export function formatDateRange(pastDate: dayjs.Dayjs, shownDate: dayjs.Dayjs) {
  if (pastDate.year() === shownDate.year()) return pastDate.format('M') + " - " + shownDate.format('M/YYYY')
  return pastDate.format('M/YYYY') + " - " + shownDate.format('M/YYYY')
}
