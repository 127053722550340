import React from 'react';
import { MaturityModel, MaturityMilestone, MaturityPhase, BusinessHistory, ScaleStatus } from '../../model/ScaleTypes';
import { groupByReduce, flattenGroupBy, toNumber, latestBeforeUnsorted } from '../../util/ScaleUtils';
import { theme } from '../../layout/theme';
import { UsesMaturityModelProps } from './ScaleGraphTypes';
import Plot from 'react-plotly.js'
import chroma from 'chroma-js';
import { Alert, Box, Typography } from '@mui/material';
import { Annotations, Shape } from 'plotly.js';
import { useTranslate } from 'react-admin';
import dayjs from '../../configuration/configuredDayjs';
import { nameToColor } from '../Colors';
import { NumberedCircle } from '../common/NumberedCircle';
import { Property } from 'csstype';

const COMPARISON_OPACITY = 0.3

export interface PortfolioBubbleChartProps extends UsesMaturityModelProps {
  width?: string;
  height?: string;
  /**
   * The property name to use to distinguish different series that canbe filtered-
   */
  seriesProperty: keyof BusinessHistory;
  /**
   * data containing BusienssExtended Records, data can contain duplicates of 
   */
  data: BusinessHistory[];

  /**
   * Comparison data
   */
  comparisonData?: BusinessHistory[];

  /**
   * Months ago the comparison is done (if available).
   */
  compareMonthsAgo?: number;
  /**
   * Returns the closest data point from the data series when clicked.
   */
  onClickDataPoint?: (series: any, dataPoint: BusinessHistory) => void;
}

interface PortfolioBubbleLegendProps {
  data: BusinessHistory[];
  seriesProperty: keyof BusinessHistory;
  padding?: number;
  alignItems?: Property.AlignItems | undefined;
  flexDirection?: Property.FlexDirection | undefined;
}

export const PortfolioBubbleLegend: React.FC<PortfolioBubbleLegendProps> = ({
  data,
  seriesProperty,
  padding = 0,
  alignItems = 'center',
  flexDirection = 'row'
}) => {

  const dataByBusinessId = groupByReduce(data, 'businessId');
  const latestProjects = flattenGroupBy(dataByBusinessId, group => latestBeforeUnsorted(new Date(), group, "scoreModifiedAt")) as BusinessHistory[];
  const latestBusinessDataBySeries = groupByReduce(latestProjects, seriesProperty);
  const seriesNames: (string | number)[] = Object.keys(latestBusinessDataBySeries);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection,
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        alignItems,
        padding
      }}
    >
      {seriesNames.map((series, index) => {
        const seriesLabel = series === null || series === undefined || series === "undefined"
          ? "[unknown]"
          : String(series); // Convert to string for display

        const color = nameToColor(seriesLabel);

        return (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 2,
              marginBottom: 1,
              paddingX: 0.5,
              gap: 1,
            }}
          >
            <NumberedCircle number={"" /*index + 1*/} color={color} />
            <Typography variant="body2" noWrap>
              {seriesLabel}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

/**
 * Primary UI component for user interaction
 */
export const PortfolioBubbleChart: React.FC<PortfolioBubbleChartProps> = ({
  maturityModel,
  seriesProperty = 'horizon',
  compareMonthsAgo = 0,
  data,
  comparisonData = [],
  width,
  height,
  onClickDataPoint,
}) => {
  const translate = useTranslate();

  // group data by businessId in case it happens to be historical data
  const dataByBusinessId = groupByReduce(data, 'businessId');
  if (
    Object.keys(dataByBusinessId).length === 1 &&
    Object.keys(dataByBusinessId)[0] === 'undefined'
  ) return <Alert severity="error">Data error, missing 'businessId'.</Alert>
  if (!maturityModel) return <Alert severity="error">{translate('errors.maturity_model_missing')}</Alert>

  const latestProjects = flattenGroupBy(dataByBusinessId, group => latestBeforeUnsorted(new Date(), group, "scoreModifiedAt")) as BusinessHistory[];

  // Find maximum budget, used to for scaling bubble sizes
  const maxBudgetValue = latestProjects.reduce((max, item) => Math.max(toNumber(item.budget), max), 0);
  const latestBusinessDataBySeries = groupByReduce(latestProjects, seriesProperty);
  const seriesNames: string[] = Object.keys(latestBusinessDataBySeries);

  const mainData: Plotly.Data[] = seriesNames?.map(series => createSeriesData(
    series,
    latestBusinessDataBySeries[series],
    maxBudgetValue,
    nameToColor(series)
  ));

  // History data
  if (compareMonthsAgo > 0 || comparisonData.length > 0) {
    const comparedDate = dayjs().subtract(compareMonthsAgo).toDate();
    const comparedBusinessData = comparisonData.length > 0 ?
      comparisonData.filter(item => item !== null && item !== undefined && item.businessId
        //       && businessData.find(val => val.businessId === item?.businessId && (val.businessPotential !== item.businessPotential || val.score !== item.score))
      ) :
      flattenGroupBy(dataByBusinessId, group => latestBeforeUnsorted(comparedDate, group, "scoreModifiedAt")) as BusinessHistory[];

    // @BUG There is a bug when the series property is name, if the name has changed, the grouping and comparison does not work
    const comparedBusinessDataBySeries = groupByReduce(comparedBusinessData, seriesProperty);

    // adds the shadow balloons
    seriesNames?.map(name => createSeriesData(
      name,
      comparedBusinessDataBySeries[name],
      maxBudgetValue,
      chroma(nameToColor(name)).alpha(COMPARISON_OPACITY).hex(),
      false
    )).forEach(series => mainData.push(series));

    // create the traces, finding corresponding data from the comparedbusinessDate by businessId.
    const traces = latestProjects.map(latest => createHistoryTrace(latest, comparedBusinessData.find(val => latest?.businessId === val?.businessId)!, seriesProperty));
    traces.forEach(trace => mainData.push(trace));
  }

  const config = {
    displayModeBar: false,
    editable: false,
    responsive: false
  }
  const layout = createBubbleChartLayout(maturityModel);


  // POC: Idea Selection phase
  /*
  const phaseText = {
    yref: 'paper',
    x: -0.5,
    xanchor: 'center',
    y: 0.0,
    yanchor: 'below',
    text: 'Idea Selection',
    showarrow: false
  }
  // @ts-ignore
  layout.annotations.push(phaseText);
*/
  // END POC

  var onClickWrapper = function (d: any) {
    if (d.points.length === 1) {
      var p = d.points[0];
      // The data received is organized per the series.
      if (onClickDataPoint) onClickDataPoint(p.data.name, latestBusinessDataBySeries[p.data.name][p.pointIndex]);
      return;
    }
    console.log("several points 'clicked':" + d.points);
  }
  var extras = {};
  if (onClickDataPoint) extras = { onClick: onClickWrapper, ...extras }

  // @ts-ignore
  return <Plot data={mainData}
    style={{ width, height }}
    layout={layout}
    config={config}
    {...extras}

  />
};

// ******************************** CREATING DATA ********************************
/**
 * Creates a Plotly data set for a series of BusinessExtended data.
 * @param seriesName name for the series (shown in the legend)
 * @param dataForSeries the data set for the series
 * @param maxBudget budget value used for scaling the sizes of the bubbles
 * @param color color to beused
 * @param showLegend true if the legend is to be shown
 * @returns Plotly.Data set of markers
 */
export function createSeriesData(seriesName: string, dataForSeries: BusinessHistory[], maxBudget: number, color: string, showLegend?: boolean): Plotly.Data {
  if (dataForSeries === undefined || dataForSeries.length === 0) return {};

  function statusToOpacity(status: ScaleStatus | undefined): number {
    switch (status) {
      case 'on hold':
        return 0.3;

      case 'archived':
        return 0.3;

      default:
        break;
    }
    return 0.7
  }

  var scores: number[] = [];
  var potentials: number[] = [];
  var budgets: number[] = [];
  var names: string[] = [];
  var opacities: number[] = [];
  var validated: string[] = [];
  var validatedLine: number[] = [];
  var status: string[] = [];

  dataForSeries.forEach(item => {
    scores.push(item.score || 0);
    potentials.push(Math.max(toNumber(item.businessPotential, 0), 0));
    budgets.push(Math.max(toNumber(item.budget, 0), 0));
    names.push(item.name);
    opacities.push(statusToOpacity(item.status));
    validated.push(item.status === 'archived' ? 'yellow' : chroma(color).brighten(2).css())
    validatedLine.push(item.status === 'archived' ? 3 : 1)
    status.push(item.status === 'archived' ? 'completed ⭐️' : item.status ? item.status : "<unknown>")
  });

  // https://plotly.com/javascript/bubble-charts/#bubble-size-scaling-on-charts
  var desired_maximum_marker_size = 60;
  return {
    legendgroup: seriesName,
    mode: 'markers',
    name: seriesName,
    showlegend: showLegend,
    text: names,
    x: scores,
    y: potentials,
    customdata: status,
    marker: {
      size: budgets,
      sizemin: 4,
      sizeref: 2.0 * maxBudget / (desired_maximum_marker_size ** 2),
      sizemode: 'area',
      opacity: opacities,
      color,
      line: {
        width: validatedLine,
        color: validated
      }
    },
    hovertemplate: '<b>%{text}</b><br>' +
      '<br>Score: %{x:.1f}' +
      '<br>Business potential: %{y:,.2s} €' +
      '<br>Budget: %{marker.size:,.2s} €<br><br>' +
      'Status: %{customdata}' +
      '<extra></extra>',
  };
}

// ******************************** LAYOUT ********************************
const createMilestoneTextAnnotation = function (milestone: MaturityMilestone): Partial<Annotations> {
  return {
    showarrow: false,
    bgcolor: milestone.color ? milestone.color : '#ff7f0e',
    font: {
      size: 10,
      color: '#ffffff'
    },
    opacity: 0.8,
    text: ` ${milestone.shortName} `,
    x: milestone.level,
    xanchor: 'center',
    yref: 'paper',
    y: 1.05,
    //@ts-ignore
    yanchor: 'below',
  };
}
const createMilestoneLineTextAnnotation = function (milestone: MaturityMilestone): Partial<Annotations> {
  return {
    showarrow: false,
    text: milestone.lineText || "",
    //@ts-ignore
    textangle: 90,
    x: milestone.level,
    xanchor: 'left',
    yref: 'paper',
    y: 0.5,
    //@ts-ignore
    yanchor: 'center',
  };
}

const createMilestoneLineShape = function (milestone: MaturityMilestone): Partial<Shape> {
  return {
    type: 'line',
    line: {
      color: milestone.color ? milestone.color : 'rgb(255, 0, 0)',
      width: 2,
      dash: milestone.line ? milestone.line : 'dot'
    },
    x0: milestone.level,
    x1: milestone.level,
    yref: 'paper',
    y0: 0,
    y1: 1,
    opacity: 0.5,
  };
}

const createPhaseTextAnnotation = function (phase: MaturityPhase): Partial<Annotations> {
  return {
    showarrow: false,
    text: phase.name,
    x: ((phase.lastLevel - phase.firstLevel) / 2 + phase.firstLevel),
    xanchor: 'center',
    yref: 'paper',
    y: 0.0,
    //@ts-ignore
    yanchor: 'below',
  };
}

const createPhaseAreaShape = function (phase: MaturityPhase): Partial<Shape> {
  return {
    type: 'rect',
    fillcolor: phase.areaColor,
    line: { width: 0 },
    xref: 'x',
    x0: phase.firstLevel,
    x1: phase.lastLevel,
    yref: 'paper',
    y0: 0,
    y1: 1,
    layer: 'below'
  };
}

const createPhaseAreaTitleAnnotation = function (phase: MaturityPhase): Partial<Plotly.Annotations> {
  return {
    font: {
      size: 20,
      color: 'black',
    },
    opacity: 0.8,
    showarrow: false,
    text: phase.areaTitle ? phase.areaTitle : "",
    x: ((phase.lastLevel - phase.firstLevel) / 2 + phase.firstLevel),
    xanchor: 'center',
    yref: 'paper',
    y: 1.12,
    yanchor: 'top',
  };
}

/**
 * Function to create trace / line between the current and the previous business infos
 * @param current 
 * @param previous 
 * @param seriesProperty property of the data info to use for legend group
 * @returns 
 */
const createHistoryTrace = (current: BusinessHistory, previous: BusinessHistory, seriesProperty: keyof BusinessHistory): Plotly.Data => {
  const historyTraceColor = `rgba(0,0,0,${COMPARISON_OPACITY})`
  const size = 4;

  const trace: Plotly.Data = {
    mode: 'lines+markers',
    hoverinfo: 'skip',
    showlegend: false,
    legendgroup: '' + current[seriesProperty],
    line: {
      color: historyTraceColor,
      dash: 'dot',
      width: 1,
    }
  }

  // this is shown when there is no previous measurement OR when it is exactly the same.
  if (previous === undefined || (previous.score === current.score && previous.businessPotential === current.businessPotential)) {
    return {
      ...trace,
      x: [current.score || 0],
      y: [current.businessPotential || 0],
      marker: {
        symbol: previous === undefined ? 'star' : 'square',
        color: [historyTraceColor],
        size: size * (previous === undefined ? 3 : 1), // new ones have larger star marker
      }
    }
  }
  return {
    ...trace,
    x: [previous.score || 0, current.score || 0],
    y: [previous.businessPotential || 0, current.businessPotential || 0],
    marker: {
      symbol: ['diamond', 'diamond'],
      color: [historyTraceColor, historyTraceColor],
      size: [1, size]
    }
  }
}

export function createBubbleChartLayout(maturityModel: MaturityModel) {
  const layout: Partial<Plotly.Layout> = scaleBubbleChartLayout();
  layout.xaxis = layout.xaxis || {}
  layout.annotations = layout.annotations || [];
  layout.shapes = layout.shapes || [];

  layout.xaxis.range = [0, maturityModel.maxLevel];

  // Add milestones to layout
  maturityModel.milestones?.forEach(milestone => {
    layout.annotations!.push(createMilestoneTextAnnotation(milestone));
    layout.shapes!.push(createMilestoneLineShape(milestone));
    if (milestone.lineText)
      layout.annotations!.push(createMilestoneLineTextAnnotation(milestone));
  });

  // Add phases to layout
  maturityModel.phases?.forEach(phase => {
    layout.annotations!.push(createPhaseTextAnnotation(phase));
    if (phase.areaColor)
      layout.shapes!.push(createPhaseAreaShape(phase));
    if (phase.areaTitle)
      layout.annotations!.push(createPhaseAreaTitleAnnotation(phase));
  });

  const bubbleSizeAnnotation: Partial<Annotations> = {
    x: maturityModel.maxLevel,
    xanchor: 'right',
    y: -0.135,
    yref: 'paper',
    yanchor: 'bottom',
    text: 'Bubble size represents budget ',
    font: {
      color: 'onyx',
    },
    opacity: 0.8,
    showarrow: false
  };
  layout.annotations.push(bubbleSizeAnnotation);
  return layout;
}

function scaleBubbleChartLayout(): Partial<Plotly.Layout> {
  return {
    font: {
      family: theme.typography.fontFamily,
      size: theme.typography.fontSize,
    },
    margin: {
      t: 30,
      b: 30,
      l: 30,
      r: 30
    },
    hovermode: 'closest',
    autosize: true,
    showlegend: false,
    legend: {
      x: 0.5,
      yref: 'paper',
      y: -0.5,
      orientation: 'h',
      xanchor: 'center',
      yanchor: 'top'
    },
    yaxis: {
      type: 'log',
      //range: [5, 10],
      automargin: true,
      autorange: true,
      showline: true,
      title: 'Business potential (€)'
    },
    xaxis: {
      type: 'linear',
      rangemode: 'tozero',
      range: [0, 9], // default
      dtick: 1,
      showline: true,
      title: 'Business maturity (0-10)'

    },
    annotations: [],
    shapes: []
  }
};

