import { Alert } from "@mui/material"
import { UsesBusinessHistoryDataProps, UsesMaturityModelProps } from "./ScaleGraphTypes"
import { Task } from "gantt-task-react"

import Plot from "react-plotly.js"
import { average, FORMAT_DAY, FORMAT_QUARTER } from "../../util/DayUtils"
import { getTextColorForBackground, SET_COLORS } from "../Colors"
import chroma from "chroma-js"
import { Annotations, Shape } from "plotly.js"
import dayjs from "../../configuration/configuredDayjs"
import { Dayjs } from "dayjs"
import { measureText } from "../../util/GuiUtils"
import useComponentWidth from "../../util/useComponentWidth"

const QUARTER_IN_DAYS = 365 / 4;

export interface TimelineChartProps extends UsesMaturityModelProps, UsesBusinessHistoryDataProps {
    height?: string;
    width?: string;
    rowHeight?: number;
    shownQuarters?: number;
}
/**
 * Primary UI component for user interaction
 */
export const TimelineChart: React.FC<TimelineChartProps> = ({
    maturityModel,
    data,
    height,
    width,
    rowHeight = 42,
    shownQuarters = 8,
    ...props
}) => {
    const { ref, width: actualWidth } = useComponentWidth();

    if (!data) return <Alert severity="error">Data missing.</Alert>
    data = data.filter(business => business.plans && business.plans.length > 0);
    if (data.length === 0) return <Alert severity="warning">No projects have milestones added.</Alert>

    const MARGIN = 50;
    const GANTT_ROW_HEIGTH = rowHeight;

    const plotAreaWidth = actualWidth - MARGIN * 2;
    const shownQuarterWidth = plotAreaWidth / shownQuarters;

    var totalLines = 0;
    data.forEach(history => totalLines += history.plans ? history.plans.length : 0)

    const tasks: Task[] = [];
    data.forEach(item => {
        item.plans?.forEach(plan => {
            tasks.push({
                id: plan.id,
                type: 'task',
                start: dayjs(plan.startDate).toDate(),
                end: dayjs(plan.targetDate).toDate(),
                name: "name",
                progress: 0
            })
        });
    });

    const plotlyData: Plotly.Data[] = [];

    const quartersInValues: Dayjs[] = [];
    const timeLineStart = dayjs().subtract(2, 'years').startOf('year');
    for (var i = 0; i < 5 * 4; i++) {
        quartersInValues.push(timeLineStart.add(i, 'quarters'))
    }

    // CREATE TIMELINE
    const layout: Partial<Plotly.Layout> = {
        title: 'Portfolio Timeline',
        showlegend: true,
        height: MARGIN * 2 + totalLines * GANTT_ROW_HEIGTH,
        plot_bgcolor: '#fafafa',
        margin: { t: MARGIN, b: MARGIN, r: MARGIN, l: MARGIN },
        'xaxis': {
            autorange: false,
            range: [
                dayjs().subtract(1, 'quarter').startOf('quarter').toDate(),
                dayjs().subtract(1, 'quarter').startOf('quarter').add(shownQuarters - 1, 'quarter').endOf('quarter').toDate()
            ],
            showgrid: true,
            type: 'date',
            zeroline: true,
            tickmode: 'array',
            ticks: 'inside',
            ticklen: 0,
            tickvals: quartersInValues.map(t => t.toDate()),
            ticktext: quartersInValues.map(t => ""),

        },
        'yaxis': {
            fixedrange: true,
            'autorange': false,
            range: [-1 * totalLines, 0],
            'showgrid': true,
            'zeroline': true,
            tickmode: 'array',
            ticklen: 0,
            tickvals: [0, -1, -2, -3, -4, -5],
            ticktext: ["", "", "", "", "", ""],
        },
        dragmode: 'pan',
    }
    // Quarter annotations for top
    layout.annotations = quartersInValues.map(q => {
        return {
            x: dayjs(average(q.startOf('quarter').toISOString(), q.endOf('quarter').toString())).toISOString(),
            y: 1.0,
            xref: 'x',
            yref: 'paper',
            yshift: 15,
            text: q.format(FORMAT_QUARTER),
            showarrow: false,
            font: {
                family: 'Arial',
                size: 10,
                color: 'black'
            }
        }
    });

    layout.shapes = [];
    // VERTICAL TODAY LINE
    const todayLine: Partial<Shape> = {
        type: 'line',
        yref: 'paper',
        y0: 0,
        x0: new Date(),
        y1: 1,
        x1: new Date(),
        line: {
            color: 'red',
            width: 1,
            dash: 'dot'
        }
    }
    layout.shapes.push(todayLine);


    // ADD DATA
    var index = 0;
    data.forEach((business, businessIndes) => {
        const color = SET_COLORS[businessIndes];

        business.plans?.forEach((plan, planIdx) => {
            // add the data line (which is invisible, and assigns the hovers)
            plotlyData.push({
                marker: { color: 'white' },
                name: '',
                showlegend: false,
                x: [plan.startDate, plan.targetDate],
                y: [-index - 0.5, -index - 0.5],
                type: 'scatter',
                customdata: [plan.startSelection || "", plan.targetSelection || ""],
                hovertemplate: '%{x}<br>%{customdata}'
            })
            // make the bar
            const fillcolor = color; // dayjs().isAfter(plan.startDate) ? color : undefined;
            layout.shapes?.push(
                {
                    fillcolor,
                    line: {
                        'width': 1,
                        'color': chroma(color).darken(2).hex()
                    },
                    opacity: 1,
                    type: 'path',
                    path: `M ${plan.startDate},${-index - 0.2} ` +
                        `L ${plan.startDate},${-index - 0.8} ` +
                        `L ${plan.targetDate},${-index - 0.8} ` +
                        `L ${plan.targetDate},${-index - 0.2} ` +
                        `L ${plan.startDate},${-index - 0.2} `
                    ,
                    x0: plan.startDate,
                    x1: plan.targetDate,
                    xref: 'x',
                    y0: -index - 0.2,
                    y1: -index - 0.8,
                    yref: 'y'
                },
            )

            const texts = [`${business.name}`, `(${plan.name})`]
            const barWidth = 1.0 * shownQuarterWidth * dayjs(plan.targetDate).diff(plan.startDate, 'days') / QUARTER_IN_DAYS;

            const insideTheBarAnnotation: Partial<Annotations> = {
                x: dayjs(average(plan.startDate, plan.targetDate)).format(FORMAT_DAY),
                y: -index - 0.5,
                xref: 'x',
                yref: 'y',
                text: texts.join('<br>'),
                xanchor: 'center',
                showarrow: false,
                font: {
                    family: 'Arial',
                    size: 10,
                    color: getTextColorForBackground(fillcolor)
                }
            }

            // if any of the text lines is larger than the bar width, write it aside
            if (!texts.find(text => barWidth < measureText(text, "10px", "Arial"))) {
                layout.annotations!.push(insideTheBarAnnotation);
            } else {
                layout.annotations!.push({
                    ...insideTheBarAnnotation,
                    x: plan.targetDate,
                    xanchor: 'left',
                    font: {
                        family: 'Arial',
                        size: 10,
                        color: getTextColorForBackground('white')
                    }
                })

            }
            index++;
        });
    })

    //@ts-ignore
    return <div ref={ref} style={{ height, width }}><Plot
        data={plotlyData} layout={layout}
        style={{ height, width }}
        config={{ displayModeBar: false, autosizable: true, responsive: false }}
    /></div>
}