import { ApolloClient, InMemoryCache } from "@apollo/client"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ScaleDataProvider } from "./ScaleDataProvider"

type SetApollo = PayloadAction<ApolloClient<InMemoryCache> | undefined>
type SetDataProvider = PayloadAction<ScaleDataProvider | undefined>

export const providerSlice = createSlice({
  name: 'provider',
  initialState: {
    apollo: undefined as ApolloClient<InMemoryCache> | undefined,
    dataProvider: undefined as ScaleDataProvider | undefined
  },
  reducers: {
    setApolloClient(state, { payload: apollo }: SetApollo) {
      console.log("ProviderReducer.setApolloClient:", apollo)
      // @ts-ignore-error
      state.apollo = apollo
    },
    setScaleDataProvider(state, { payload: dataProvider }: SetDataProvider) {
      console.log("ProviderReducer.setScaleDataProvider:", dataProvider)
      if (dataProvider === undefined) {
        state.apollo?.cache?.reset()
        state.dataProvider = undefined
      } else {
        state.dataProvider = dataProvider
      }
    },
  }
})

export const {
  setApolloClient,
  setScaleDataProvider,
} = providerSlice.actions
