import { BusinessExtended, BusinessHistory, constructMaturityModelFromRecord, MaturityModel, MaturityModelRecord, MaturityScore } from '../model/ScaleTypes'
import { isNil } from '../util/ScaleUtils'
import { useEffect, useState } from 'react'
import { ScaleDataProvider, useScaleDataProvider } from './provider/ScaleDataProvider'

type ProjectDashboardData = {
  project?: BusinessExtended | null
  maturityHistory: BusinessHistory[]
  maturityModel?: MaturityModel | null
  maturityScores: MaturityScore[]
}

type ProjectDashboardState = {
  data: ProjectDashboardData;
  isLoading: boolean;
  error: string | null;
};

type Loader<T> = (dataProvider: ScaleDataProvider, projectId?: string) => Promise<T>
type LoadProject = Loader<BusinessExtended | null>
type LoadMaturityHistory = Loader<BusinessHistory[]>
type LoadMaturityModel = Loader<MaturityModel | null>
type LoadMaturityScores = Loader<MaturityScore[]>

const loadProject: LoadProject = async (dataProvider, projectId) => {
  if (isNil(projectId)) return null

  console.log('useProjectDashboardData.loadProject fetching one BusinessExtended')
  const response = await dataProvider.getOne<BusinessExtended>('api_business', { id: projectId })
  console.log('response received', response)
  return response.data
}

const loadMaturityHistory: LoadMaturityHistory = async (dataProvider, projectId) => {
  if (isNil(projectId)) return []

  const response = await dataProvider.getList<BusinessHistory>(
    'api_businessHistoryWithScore',
    {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'modifiedAt', order: 'ASC' },
      filter: { businessId: projectId }
    }
  )
  return response.data ?? []
}

const loadMaturityModel: LoadMaturityModel = async (dataProvider, projectId) => {
  if (isNil(projectId)) return null

  const response = await dataProvider.getList<MaturityModelRecord>(
    'api_maturityModelByOrganization',
    {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: {},
    },
    // { staleTime: 2 * 60 * 1000 }
  )

  const maturityModel = response?.data[0]
  return maturityModel
    ? constructMaturityModelFromRecord(maturityModel)
    : null
}

const loadMaturityScores: LoadMaturityScores = async (dataProvider, projectId) => {
  if (isNil(projectId)) return []

  const response = await dataProvider.getList<MaturityScore>(
    'scale_maturityScores',
    {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: { businessId: projectId },
    }
  )
  return response.data ?? []
}

export const useProjectDashboardData = (projectId?: string): ProjectDashboardState => {
  const dataProvider = useScaleDataProvider();
  const [state, setState] = useState<ProjectDashboardState>({
    data: {
      project: undefined,
      maturityHistory: [],
      maturityModel: undefined,
      maturityScores: [],
    },
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    if (dataProvider && projectId) { // Check projectId here as well
      setState(prevState => ({ ...prevState, isLoading: true, error: null })); // Set loading to true

      Promise.all([
        loadProject(dataProvider, projectId),
        loadMaturityHistory(dataProvider, projectId),
        loadMaturityModel(dataProvider, projectId),
        loadMaturityScores(dataProvider, projectId),
      ])
        .then(([project, maturityHistory, maturityModel, maturityScores]) => {
          if (!project) { // Check if project is null (not found)
            setState({
              data: {
                project: null, // Explicitly set to null if not found
                maturityHistory,
                maturityModel,
                maturityScores,
              },
              isLoading: false,
              error: 'Project not found.', // Set an error message
            });
          } else {
            setState({
              data: { project, maturityHistory, maturityModel, maturityScores },
              isLoading: false,
              error: null,
            });
          }
        })
        .catch((error) => {
          console.error("Error loading project data:", error);
          setState(prevState => ({ ...prevState, isLoading: false, error: error.message || 'Error loading data' }));
        });
    } else {
      setState(prevState => ({ ...prevState, isLoading: false, error: null, data: { project: null, maturityHistory: [], maturityModel: null, maturityScores: [] } }));
    }
  }, [projectId, dataProvider]);

  return state;
};