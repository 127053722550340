import { ResponseSchema, SchemaType } from '@google/generative-ai';
import { z } from 'zod';

export const zodToGeminiSchema = (schema: z.ZodTypeAny): ResponseSchema | undefined => {
  // Handle ZodOptional and ZodNullable first
  if (schema instanceof z.ZodNullable) {
    const innerSchema = zodToGeminiSchema(schema._def.innerType);
    if (!innerSchema) return undefined;
    return {
      ...innerSchema,
      nullable: true,
      description: schema.description,
    };
  }
  if (schema instanceof z.ZodOptional) {
    const innerSchema = zodToGeminiSchema(schema._def.innerType);
    if (!innerSchema) return undefined;
    return {
      ...innerSchema,
      description: schema.description,
    };
  }

  if (schema instanceof z.ZodString) {
    return { type: SchemaType.STRING, description: schema.description };
  }

  if (schema instanceof z.ZodNumber) {
    return { type: SchemaType.NUMBER, description: schema.description };
  }

  if (schema instanceof z.ZodBoolean) {
    return { type: SchemaType.BOOLEAN, description: schema.description };
  }

  if (schema instanceof z.ZodDate) {
    return { type: SchemaType.STRING, description: schema.description + ' The format is RFC3339' };
  }

  if (schema instanceof z.ZodLiteral) {
    return { type: SchemaType.STRING, enum: [schema.value], description: schema.description, format: "enum" };
  }

  if (schema instanceof z.ZodArray) {
    const items = zodToGeminiSchema(schema._def.type);
    if (!items) return undefined;
    return {
      type: SchemaType.ARRAY,
      items,
      description: schema.description,
    };
  }

  if (schema instanceof z.ZodEnum) {
    return {
      type: SchemaType.STRING,
      enum: schema.options,
      description: schema.description,
    };
  }

  if (schema instanceof z.ZodObject) {
    const properties: { [k: string]: ResponseSchema } = {};
    const required: string[] = [];

    for (const key in schema.shape) {
      const field = schema.shape[key];
      const schemaValue = zodToGeminiSchema(field);

      if (schemaValue) {
        properties[key] = schemaValue;
      }

      if (!field.isOptional() && !field.isNullable()) {
        required.push(key);
      }
    }

    return {
      type: SchemaType.OBJECT,
      properties,
      description: schema.description,
      required: required.length > 0 ? required : undefined,
    };
  }

  if (schema instanceof z.ZodFunction) {
    return undefined;
  }

  throw new Error(`I don't yet know how to convert ${JSON.stringify(schema)}}`);
};
