import {
  Grid,
  Typography,
  Box,
  Alert,
  Button,
} from '@mui/material';
import {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  SelectInput,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { canCreateReport } from './CustomReportPage';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { MaturityScoreCardContent } from '../components/MaturityScore';
import { DisplayCard, EditableWizardCard } from '../components/WizardCard';
import { MilestoneTable } from '../components/tables/MilestoneTable';
import { createBusinessHistoryGraphData } from '../components/charts/BusinessHistoryGraph';
import { createMaturityChartData } from '../components/charts/MaturityChart';
import { PlotlyChart } from '../components/charts/PlotlyCharts';
import {
  BusinessExtended,
  MaturityCategory,
  MaturityModel,
  MaturityScoreExtended,
  Plan,
} from '../model/ScaleTypes';
import BusinessGoalsForm from '../resources/scale_business/BusinessGoalsForm';
import { PlanFormContent, transformPlan } from '../resources/scale_plan/PlanForm';
import { useProjectDashboardData } from '../state/useProjectDashboardData';
import { useScaleDataProvider } from '../state/provider/ScaleDataProvider';
import { defaultCurrencyFormatter, formatDate } from '../util/FormattingUtils';
import { formatArrayForHasura, isNil, isNotNil } from '../util/ScaleUtils';
import ProjectBasicInfo, { GridRow, Text } from '../components/project/ProjectBasicInfo';
import { CategoryChip } from '../components/CategoryChip';
import ButtonTooltip from '../components/ButtonTooltip';
import { DashboardTopRow } from '../components/DashboardTopRow';
import { SummaryCoach } from '../components/scale-coach/SummaryCoach';
import { ScaleSvg } from '../components/Icons';
import DeleteItemButton from '../components/common/DeleteItemButton';
import { ProjectExtract, StartFromFile } from '../components/scale-coach/ProjectCreationAI';

interface ProjectDashboardProps {
  projectId?: string
}

const GRID_XS = 12
const COLS = 4
export const SPAN_MULTIPLIER = Math.round(GRID_XS / COLS)

export const Row = ({ children }: { children: JSX.Element | JSX.Element[] }) => (
  <>{children}</>
)

export type ProjectProps = { project: BusinessExtended }

const BusinessGoalsShow = ({ project }: ProjectProps) => {
  return (
    <Grid container width="100%">
      <GridRow>
        <Text label='Start Date' value={formatDate(project?.startDate)} />
        <Text label='Budget' value={defaultCurrencyFormatter(project?.budget ?? 0)} />
      </GridRow>

      <GridRow>
        <Text label='Business Model' value={project?.businessModel} />
      </GridRow>

      <GridRow>
        <Text label='Calculated Business Potential' value={defaultCurrencyFormatter(project?.businessPotential ?? 0)} />
      </GridRow>
    </Grid>
  )
}

const coachDisclaimer = <span style={{ color: '#FF5F6D', margin: '0.2em' }}>*</span>

type PlanningSessionProps = {
  project: BusinessExtended
  maturityModel: MaturityModel
  setSelectedPlanningCategoryOrdinals: (ordinals: number[]) => any
}

const NewPlanningSessionForm = ({
  project,
  maturityModel,
  setSelectedPlanningCategoryOrdinals,
}: PlanningSessionProps) => {
  const translate = useTranslate();
  const scaleDataProvider = useScaleDataProvider()
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<Set<string>>(new Set<string>())
  const [recommendedCategoryIds, setRecommendedCategoryIds] = useState<Set<string>>(new Set<string>())
  const [numberOfRecommendations, setNumberOfRecommendations] = useState<number>(0)
  const { getValues } = useFormContext();

  const selectCategories = (categoryIds: Set<string>) => {
    const selectedOrdinals = maturityModel.categories
      .filter(cat => categoryIds.has(cat.id))
      .map(cat => cat.order)
    setSelectedCategoryIds(categoryIds)
    setSelectedPlanningCategoryOrdinals(selectedOrdinals)
  }

  const resetToRecommendedCategories = () => {
    selectCategories(recommendedCategoryIds)
  }

  const onlyRecommendedCategoriesSelected = (): boolean => {
    return selectedCategoryIds.size === recommendedCategoryIds.size
      && Array.from(selectedCategoryIds).every(v => recommendedCategoryIds.has(v))
  }

  useEffect(() => {
    if (!scaleDataProvider) return;
    (async () => {
      const hadOnlyRecommendedCategories = onlyRecommendedCategoriesSelected()
      const recommendedPrios = await scaleDataProvider.getSuggestedMaturityCategories({ projectId: project.id, amount: numberOfRecommendations })
      const recommendedIds = new Set(recommendedPrios.map(p => p.categoryId))
      setRecommendedCategoryIds(recommendedIds)

      if (hadOnlyRecommendedCategories || selectedCategoryIds.size === 0) {
        selectCategories(recommendedIds)
      }
    })()
    // ignored missing ones as if they are added the coach does not work correctly.
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [scaleDataProvider, numberOfRecommendations])

  const toggleCategory = (category: MaturityCategory) => {
    const newSelections = new Set(selectedCategoryIds)
    if (newSelections.has(category.id)) {
      newSelections.delete(category.id)
    } else {
      newSelections.add(category.id)
    }

    selectCategories(newSelections)
  }

  function updateSelection() {
    const selectedMinutes = parseInt(getValues("allocatedTime") || '0', 10);
    const recommendationCount = Math.floor((selectedMinutes - 15) / 15)
    setNumberOfRecommendations(recommendationCount);
  }

  return (
    <Grid container width="100%">
      <Row>
        <Grid item xs={12}>
          <Typography color="#253241" fontSize={16}>{translate("assessmentsForm.instruction")}</Typography>
        </Grid>
      </Row>

      <Row>
        <Grid item xs={6}>
          <SelectInput
            label='assessmentsForm.allocatedTime'
            source='allocatedTime'
            fullWidth
            onChange={updateSelection}
            choices={[
              { id: '30', name: '30 min' },
              { id: '45', name: '45 min' },
              { id: '60', name: '60 min' },
              { id: '90', name: '90 min' },
              { id: '120', name: '120 min' },
            ]}
          />
        </Grid>
      </Row>

      <Row>
        <Grid item xs={12}>
          <Typography color="#253241" fontSize={16} style={{ display: 'flex', alignItems: 'center' }}>
            <img alt="Scale Coach" src='/scale-coach.png' />
            <span style={{ marginLeft: '8px', fontWeight: 700 }}>{translate("assessmentsForm.selectionTitle")}</span>
            {coachDisclaimer}
            <Button
              size='medium'
              color='primary'
              startIcon={ScaleSvg.Redo}
              disabled={onlyRecommendedCategoriesSelected()}
              onClick={resetToRecommendedCategories}
              style={{ marginRight: 0, marginLeft: 'auto', fontWeight: 'bold', textTransform: 'capitalize' }}
            >{translate('assessmentsForm.recreateLabel')}</Button>

          </Typography>
        </Grid>
      </Row>

      <Row>
        <Grid item xs={12}>
          <span>
            {
              maturityModel.categories.sort((a, b) => {
                return a.order - b.order
              }).map((cat, idx) => <CategoryChip isSelectable
                category={cat} isSelected={selectedCategoryIds.has(cat.id)}
                onClick={toggleCategory} onDelete={toggleCategory}
                sx={{
                  fontSize: '14px',
                  lineHeight: '14px',
                  margin: '8px',
                }}
              />)
            }
          </span>
        </Grid>
      </Row>

      <Row>
        <Grid item xs={12}>
          <Typography color="#253241" fontSize={14}>
            {coachDisclaimer}
            {translate('assessmentsForm.recommendationExplanation')}
          </Typography>
        </Grid>
      </Row>
    </Grid>
  )
}

const calculateCurrentStep = (project: BusinessExtended, maturityScores: MaturityScoreExtended[]) => {
  if (![project.name, project.status, project.organizationUnitId].every(isNotNil)) {
    return 1;
  }

  if (![project.businessPotential].every(isNotNil)) {
    return 2;
  }

  if (!project.plans || !project.plans.length) {
    return 3;
  }

  if (maturityScores.length === 0) {
    return 4;
  }
  return 5;
};


const ProjectDashboard: FC<ProjectDashboardProps> = () => {
  const { id } = useParams()
  const redirect = useRedirect()
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh();
  const scaleDataProvider = useScaleDataProvider()
  const { data, isLoading } = useProjectDashboardData(id);
  const {
    project: loadedProject,
    maturityHistory,
    maturityModel,
    maturityScores,
  } = data;

  const [project, setProject] = useState<BusinessExtended>()
  const [isReportPossible, setIsReportPossible] = useState<boolean | undefined>(undefined);
  const [maturityChartType, setMaturityChartType] = useState<('bar' | 'radar')>('bar');
  const [planningCategoryOrdinals, setSelectedPlanningCategoryOrdinals] = useState<number[]>([]);

  const [projectOriginFile, setProjectOriginFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const createNewProjectIfProjectIdIsNil = () => {
    if (isNil(id)) {
      setProject({} as BusinessExtended)
    } else if (isNotNil(loadedProject)) {
      setProject(loadedProject)
    }
  }

  const checkIsProjectReportCreationPossible = () => {
    if (isReportPossible === undefined && isNotNil(project)) {
      canCreateReport(project).then(setIsReportPossible)
    }
  }

  useEffect(createNewProjectIfProjectIdIsNil, [id, loadedProject])
  useEffect(checkIsProjectReportCreationPossible, [project, isReportPossible])

  const saveProject = async (formData: Record<string, any>, postCreateAction = async (projectId: string) => { }) => {
    if (isNil(project)) return;
    if (formData.tags) formData.tags = formatArrayForHasura(formData.tags.sort())

    console.log('Saving project.id', project.id, 'edited fields:', formData)

    try {
      if (isNil(project.id)) {
        const createResult = await scaleDataProvider.create('scale_business', {
          data: formData,
        })
        await postCreateAction(createResult.data.id);
        notify(`${createResult.data.name} created successfully`)
        redirect('show', '/api_business', createResult.data.id)
        return
      }

      // first update
      await scaleDataProvider.update('scale_business', {
        id: project.id,
        data: {
          id: project.id,
          ...formData,
        },
        previousData: {
          ...project
        }
      })
      // then re-retrieve
      const updatedProject = await scaleDataProvider.getOne<BusinessExtended>('api_business', { id: project.id })
      notify('Changes saved successfully')
      await postCreateAction(updatedProject.data.id);
      setProject(updatedProject.data)
    } catch (error: any) {
      notify(`Error: ${error.message}`, { type: 'warning' })
    }
  }

  const markProjectDeleted = async (projectId?: string) => {
    if (isNotNil(projectId)) {
      try {
        const succeeded = await scaleDataProvider.project.delete({ projectId })
        if (succeeded) {
          notify('generic.deleted', { type: "success", messageArgs: { itemType: "Project" } });
          redirect('/businessList');
        }
      } catch (error: any) {
        notify(`Error: ${error.message}`);
      }
    }
  }

  // This just handles creating a plan, update should be checked also
  const savePlan = async (formData: Record<string, any>) => {
    if (isNil(project)) return;
    console.log("Saving plan", formData);
    try {
      const createResult = await scaleDataProvider.create('scale_plan', {
        data: {
          ...transformPlan(formData as Plan),
          businessId: project.id
        },
      })
      notify(`${createResult.data.name} created successfully`)
      // for some reason I need to create this manually as refresh() nor redirect() seem to work.
      project?.plans?.push(createResult.data as Plan);
      refresh();
    } catch (error: any) {
      notify(`Error: ${error.message}`, { type: 'warning' })
    }
  }


  /**
   * Wrapper for save function that provides the saveProject with a uploading a file used to create that project, if exists.
   */
  const saveProjectAndUpload = async (formData: Record<string, any>) => {
    saveProject(formData, async (projectId: string) => {
      if (!projectOriginFile) return;
      setIsUploading(true)
      try {
        await scaleDataProvider.uploadFile({ projectId, file: projectOriginFile })
      } catch (e) {
        console.error(e);
        notify("Failed uploading file.", { type: "error" });
      }
      setProjectOriginFile(null);
      setIsUploading(false);
    })
  }

  /**
   * Alternative action for the basic info that uses a component that shows a button and FileDialog for starting a project from file
   */
  const startFromFile = (setIsDialogOpen: (isDialogOpen: boolean) => void) => <StartFromFile
    onProjectExtracted={(projectExt: ProjectExtract, fromFile: File) => {
      // when project has been extracted, set the project and open the dialog (from the parent WizardCard), set the origin file
      // to be ready to save that later after creation
      setProject({ ...project, ...projectExt } as BusinessExtended);
      setIsDialogOpen(true);
      setProjectOriginFile(fromFile);
    }} />

  if (isLoading) {
    return <LoadingAnimation loadingText={translate('generic.loading', { content: 'business' })} />
  }

  if (isUploading) {
    return <LoadingAnimation loadingText={"Creating project from the file..."} />
  }

  if (isNil(project)) {
    return <Alert severity='error'>Could not find project</Alert>
  }

  const plans: Plan[] = project?.plans ?? [];
  const maturityChartParams = isNotNil(maturityModel) && isNotNil(maturityScores)
    ? createMaturityChartData({ maturityModel, scores: maturityScores, plans, type: maturityChartType })
    : null
  const maturityHistoryGraphParams = isNotNil(maturityModel) && isNotNil(maturityHistory)
    ? createBusinessHistoryGraphData({
      maturityModel,
      data: maturityHistory,
      countDaysFromStart: false,
      plans,
      // estimatedLaunchDate is missing as it needs to be fetched from the record.
    })
    : null

  const currentStep = calculateCurrentStep(project, maturityScores);

  const openPlanningSession = () => {
    redirect(`/businessMaturity/${id}?focusCategories=${planningCategoryOrdinals.join(',')}`)
  }

  const redirectToMaturity = () => { redirect(`/businessMaturity/${id}`); }
  const redirectToReport = () => { redirect(`/businessReport/${id}`); }

  return (
    <Grid container sx={{ maxWidth: 'calc(100vw - 50px)' }}>
      <DashboardTopRow project={project} current='dashboard'
        customItems={<>
          <ButtonTooltip
            title={translate(isReportPossible
              ? 'actions.open_report'
              : 'warnings.project.report.template_missing')
            }
          >
            <Button
              variant='QuartaryCTA'
              disabled={!isReportPossible}
              startIcon={ScaleSvg.Report}
              onClick={() => redirectToReport()}
            >{translate('actions.open_report')}</Button>
          </ButtonTooltip>
          <DeleteItemButton
            variant='QuartaryCTA'
            label="Delete Project"
            disabled={isNil(project.id)}
            itemType='project'
            confirmationLevel='input'
            itemName={project.name}
            onConfirmDelete={() => markProjectDeleted(project.id)}
          />
        </>} />

      <Row>
        <Grid item xs={2 * SPAN_MULTIPLIER}>
          <EditableWizardCard
            translationPrefix='projectDashboard.projectInformation'
            step={1}
            currentStep={currentStep}
            displayContent={<ProjectBasicInfo project={project} />}
            editForm={<ProjectBasicInfo project={project} editing={true} />}
            editRecord={project}
            onEditSave={saveProjectAndUpload}
            alternativeCardAction={startFromFile} />
        </Grid>

        <Grid item xs={1 * SPAN_MULTIPLIER}>
          <EditableWizardCard
            translationPrefix='projectDashboard.businessGoals'
            step={2}
            currentStep={currentStep}
            displayContent={<BusinessGoalsShow project={project} />}
            editForm={<BusinessGoalsForm />}
            editRecord={project}
            onEditSave={saveProject}
          />
        </Grid>

        <Grid item xs={1 * SPAN_MULTIPLIER}>
          <DisplayCard
            translationPrefix='projectDashboard.maturityScore'
            active={isNotNil(maturityHistory) && maturityHistory.length > 0}
          >
            {maturityHistory && maturityModel &&
              <MaturityScoreCardContent data={maturityHistory} maturityModel={maturityModel} onClick={redirectToMaturity} />
            }
          </DisplayCard>
        </Grid>
      </Row>

      <Row>
        <Grid item xs={2 * SPAN_MULTIPLIER}>
          <EditableWizardCard
            translationPrefix='projectDashboard.milestones'
            step={3}
            currentStep={currentStep}
            displayContent={project && maturityModel && maturityScores &&
              <MilestoneTable
                maturityModel={maturityModel}
                maturityScoreHistory={maturityScores}
                plans={plans}
              />
            }
            helpTitle="Tutorial: Milestones in Scale"
            helpContent={
              [
                <Box sx={{ flexDirection: "column", gap: "8px", display: "flex" }}>
                  <Typography>Milestones are checkpoints that mark significant progress and achievements in a project. They help teams stay focused, track progress, and align on goals while breaking complex projects into manageable chunks of work. Setting milestones help ensure that projects stay on course and adapt to challenges effectively.</Typography>
                  <img src='/milestone-tutorial1.png' alt="Milestone explanation" width="100%" />
                </Box>,
                <Box sx={{ flexDirection: "column", gap: "8px", display: "flex" }}>
                  <Typography><strong>Milestone start</strong> represents current state of the project. Accompanied by start date, a milestone start defines the maturity level and conditions that must be in place to begin progress toward the milestone end.</Typography>
                  <Typography><strong>Milestone target</strong> is the maturity level to be achieved during the milestones. In addition to end date, milestone end can be determined by measurable indicators of achievements and progress.</Typography>
                  <img src='/milestone-tutorial2.png' alt="Milestone selection explanation" width="100%" />
                </Box>
              ]
            }
            editForm={<PlanFormContent />}
            onEditSave={savePlan}
          />
        </Grid>

        <Grid item xs={2 * SPAN_MULTIPLIER}>
          <EditableWizardCard
            translationPrefix='projectDashboard.planningSessions'
            step={4}
            currentStep={currentStep}
            displayContent={<SummaryCoach business={project} scoresWithNotes={maturityScores} />}
            editRecord={project}
            editForm={maturityModel &&
              <NewPlanningSessionForm
                project={project}
                maturityModel={maturityModel}
                setSelectedPlanningCategoryOrdinals={setSelectedPlanningCategoryOrdinals} />
            }
            helpTitle="Tutorial: Assessments in Scale"
            helpContent={
              [
                <Box sx={{ flexDirection: "column", gap: "8px", display: "flex" }}>
                  <Typography>Assessments evaluate your project’s strategic alignment, readiness for successful implementation and capabilities for entering the market. Assessments also help in identifying strengths, gaps, and risks in both the innovation project and the team. Project maturity is indicated with a maturity score, calculated from project’s assessment progression.</Typography>
                  <img src='/assessment-tutorial1.png' alt="Assessment explanation" width="100%" />
                </Box>,
                <Box sx={{ flexDirection: "column", gap: "8px", display: "flex" }}>
                  <Typography>Assessment objectives can be updated and resolved freely using the assessment matrix located in the assessment tab. However, with an AI-assisted Scale session planner, teams can frame their work and follow a recommended assessment path to ensure profitable business with a successful exit.</Typography>
                  <img src='/assessment-tutorial2.png' alt="Assessment explanation" width="100%" />
                </Box>
              ]
            }
            saveButtonTitle='Start session'
            onEditSave={openPlanningSession}
          />
        </Grid>

      </Row>

      <Row>
        <Grid item xs={2 * SPAN_MULTIPLIER}>
          <DisplayCard
            translationPrefix='projectDashboard.currentMaturity'
            active={isNotNil(maturityScores) && maturityScores.length > 0}
          >
            {maturityChartParams &&
              <>
                <PlotlyChart params={maturityChartParams} />
                <Button
                  variant='outlined'
                  onClick={() => setMaturityChartType(maturityChartType === 'bar' ? 'radar' : 'bar')}
                >{maturityChartType === 'bar' ? 'Show radar chart' : 'Show bar chart'}</Button>
              </>
            }
          </DisplayCard>
        </Grid>

        <Grid item xs={2 * SPAN_MULTIPLIER}>
          <DisplayCard
            translationPrefix='projectDashboard.maturityHistory'
            active={isNotNil(maturityHistory) && maturityHistory.length > 0}
          >
            {maturityHistoryGraphParams &&
              <PlotlyChart params={maturityHistoryGraphParams} />
            }
          </DisplayCard>
        </Grid>
      </Row>
    </Grid >
  )
}

export default ProjectDashboard
