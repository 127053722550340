import React from 'react';
import { Box, Typography } from '@mui/material';
import { getTextColorForBackground } from '../Colors';

interface NumberedCircleProps {
  color: string;
  number?: number | string;
  size?: 'small' | 'medium' | 'large';
}

export const NumberedCircle: React.FC<NumberedCircleProps> = ({
  color,
  number,
  size = 'small',
}) => {
  const getSize = () => {
    switch (size) {
      case 'small':
        return 14;
      case 'medium':
        return 16;
      case 'large':
        return 24;
      default:
        return 16;
    }
  };

  const fontSize = size === 'large' ? 'body1' : 'body2';

  return (
    <Box
      sx={{
        width: getSize(),
        height: getSize(),
        borderRadius: '50%',
        backgroundColor: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: getTextColorForBackground(color)
      }}
    >
      {number !== undefined && (
        <Typography variant={fontSize} component="span" sx={{ lineHeight: 1, letterSpacing: '0%', fontVariantNumeric: 'proportional-nums' }}>
          {number}
        </Typography>
      )
      }
    </Box >
  );
};

// Helper function to check for dark background colors (for accessibility)
function isDarkBackground(color: string): boolean {
  // Convert hex to RGB
  let hex = color.replace('#', '');
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate luminance (perceived brightness)
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Consider it a dark background if luminance is below a threshold (e.g., 0.5)
  return luminance < 0.5;
}