import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { BusinessExtended } from '../model/ScaleTypes';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { useGetList, useRedirect } from 'react-admin';
import { Alert, Grid } from '@mui/material';
import useMaturityModel from '../state/useMaturityModel';
import { PortfolioMilestoneChart } from '../components/charts/PortfolioMilestoneChart';
import { TimelineChart } from '../components/charts/TimelineChart';
import { BusinessToNumber, createPortfolioBarChartData, PortfolioBarChartOptions, ValueFunctionSelector } from '../components/charts/PortfolioBarChart';
import { FORMAT_DAY, generateEndOfPeriodArray } from '../util/DayUtils';
import { flattenGroupByKeepGroup, getVersionAtTimestamp } from '../util/ScaleUtils';
import dayjs from '../configuration/configuredDayjs';
import { useBusinessHistoryDataById } from '../state/useBusinessHistoryScores';
import { PlotlyChart } from '../components/charts/PlotlyCharts';
import { Business } from '@mui/icons-material';
import { useState } from 'react';

export default function SteeringReportView() {
  const redirect = useRedirect();
  const { maturityModel, loading: mmLoading } = useMaturityModel();
  const { businessHistoryDataById, isLoading: dataLoading } = useBusinessHistoryDataById();
  const [state, setState] = useState<string>("Maturity");

  const { data, isLoading: loading } = useGetList(
    'api_business',
    { pagination: { page: 1, perPage: 10000 } }
  );

  if (loading || mmLoading || dataLoading) return <LoadingAnimation />
  if (!maturityModel) return <Alert severity="error">Maturity model is not available, please contact the support via the bottom right corner.</Alert>
  if (!businessHistoryDataById) return <></>

  const dataInArray: BusinessExtended[] = data!.filter(data => data.status === 'active');

  var onClick = function (series: any, dataPoint: BusinessExtended) {
    if (dataPoint == null) return;
    var closestID = dataPoint.id;
    redirect("show", "api_business", closestID);
    return;
  }

  // FOR HISTORY BAR CHART
  const dates = generateEndOfPeriodArray(12, 'month', dayjs().endOf('month'));
  const datedProjectValuesById = flattenGroupByKeepGroup(businessHistoryDataById, values => getVersionAtTimestamp(values, dates));

  const projectsByMonth: { [key: string]: (BusinessExtended | null)[] } = {}
  const keys = Object.keys(datedProjectValuesById)
  keys.forEach(key => {
    const valueInArray = datedProjectValuesById[key]
    for (var i = 0; i < dates.length; i++) {
      const date = dates[i];
      const dateKey = dayjs(date).format(FORMAT_DAY);
      const array = projectsByMonth[dateKey] || [];
      array.push(valueInArray[i] as BusinessExtended);
      projectsByMonth[dateKey] = array;
    }
  })

  const barData = createPortfolioBarChartData({ maturityModel, businessValuesByDate: projectsByMonth, valueFunction: PortfolioBarChartOptions[state] });


  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} >
        <Card>
          <CardHeader title="Project Portfolio" />
          <CardContent>
            <ValueFunctionSelector value={state} onChange={setState} />
            <PlotlyChart params={barData} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={12} >
        <Card>
          <CardHeader title="Project Portfolio" />
          <CardContent>
            <PortfolioMilestoneChart maturityModel={maturityModel} height="500px" onClickDataPoint={onClick} data={dataInArray} levelRange={[0, 8]} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} >
        <Card >
          <CardHeader title="Project Timelines" />
          <CardContent>
            <TimelineChart maturityModel={maturityModel} data={dataInArray} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

