const isTrue = (envVar: string): boolean => {
  return 'true' === (process.env[envVar] ?? 'false').toLowerCase().trim()
}

const fakeDataEnabled = isTrue('REACT_APP_FAKE_DATA_ENABLED')
const storageProxyEnabled = isTrue('REACT_APP_SCALE_STORAGE_PROXY_ENABLED')
const organizationDashboardStepBarChartEnabled
  = isTrue('REACT_APP_ORGANIZATION_DASHBOARD_STEP_BAR_CHART_ENABLED')

export const isFakeDataEnabled = () => fakeDataEnabled
export const isStorageProxyEnabled = () => storageProxyEnabled
export const isOrganizationDashboardStepBarChartEnabled = () => organizationDashboardStepBarChartEnabled
