import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form, RaRecord, SaveButton } from "react-admin";

export interface EditDialogProps {
  open: boolean;
  onClose: () => void;
  onEditSave: (formData: Record<string, any>) => Promise<any> | void;
  title: string;
  editRecord?: RaRecord;
  editForm?: JSX.Element | null;
  onEditCancel?: () => void;
  saveButtonTitle?: string;
}

export const EditDialog: React.FC<EditDialogProps> = ({
  open,
  onClose,
  onEditSave,
  title,
  editRecord,
  editForm,
  onEditCancel = () => { },
  saveButtonTitle = 'Save',
}) => {
  const handleCancel = async () => {
    if (onEditCancel) await onEditCancel();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Form
        record={editRecord}
        onSubmit={async (formData) => {
          await onEditSave(formData);
          onClose();
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{editForm}</DialogContent>
        <DialogActions>
          <Button variant="outlined" size="small" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <SaveButton
            label=""
            variant="contained"
            size="small"
            color="primary"
            icon={<span>{saveButtonTitle}</span>}
          />
        </DialogActions>
      </Form>
    </Dialog>
  );
};