import { Button, Box, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { ScaleSvg } from '../Icons';
import FileDialog from './FileDialog';
import { FileListing } from './FileListing';
import { ProjectFile } from '../../model/ScaleTypes';
import { useProjectFiles } from '../../state/provider/ScaleDataProvider';


const flexAlignRight = {
  marginRight: 0,
  marginLeft: 'auto',
};

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const ProjectFiles = ({ projectId }: { projectId: string }) => {
  const [showFileUploadDialog, setShowFileUploadDialog] = useState<boolean>(false);
  const openDialog = () => setShowFileUploadDialog(true);
  const closeDialog = () => setShowFileUploadDialog(false);

  const { files, isLoading, handleFiles } = useProjectFileHandler(projectId);
  return (
    <div style={{ ...flexCenter, width: '100%', height: '100%' }}>
      <Button
        variant="QuartaryCTA"
        onClick={openDialog}
        startIcon={ScaleSvg.File}
        sx={flexAlignRight}
      >
        Attach files {files.length > 0 && `(${files.length})`}
      </Button>

      <FileDialog
        open={showFileUploadDialog}
        onClose={closeDialog}
        onFilesSelected={handleFiles}
        fileSection={files.length > 0 ? <FileListing files={files} loading={isLoading} /> : <></>}
      />

    </div>
  );
};

export interface FileHandler {
  handleFiles(files: File[]): Promise<void>;
  files: ProjectFile[];
  isLoading: boolean;
  reload?(): void;
}

export const useProjectFileHandler = (projectId: string): FileHandler => {
  const { files, loading, uploadFile, reload } = useProjectFiles({ projectId });

  const handleFiles = async (files: File[]) => {
    for (const file of files) {
      await uploadFile(file);
    }
    reload();
  };

  return {
    handleFiles,
    files,
    isLoading: loading,
    reload,
  };
};


export default ProjectFiles;
