import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Business, descriptionLookupKeyFrom, MaturityCategory, MaturityModel, MaturityScore, MaturityScoreExtended } from "../../model/ScaleTypes";
import useMaturityModel, { extendMaturityScore } from "../../state/useMaturityModel";
import { groupByReduceFunction } from "../../util/ScaleUtils";
import dayjs from '../../configuration/configuredDayjs';
import SortableTable, { Column } from "../common/SortableTable";
import { CategoryChip, CategoryChipList } from "../CategoryChip";
import { FORMAT_DAY } from "../../util/DayUtils";
import { PROJECT_PROMPT, ROLE_PROMPT } from "./HypothesisCoach";
import { ScaleSvg } from "../Icons";
import { UIColors } from "../Colors";
import VariantBox from "../VariantBox";
import { invokeLlm } from "../../integrations/GeminiAPI";
import { ObjectSchema, Part, SchemaType } from "@google/generative-ai";
import { RandomAILoadingElement } from "./RandomAILoadingElement";
const OpenAI = require("openai");

type AssessmentSession = {
  id: string,
  date: string,
  notes: MaturityScoreExtended[],
}

type ActionItem = {
  action: string
  maturityCategoryId: string
}

type Summary = {
  shortSummary: string,
  longSummary: string,
  highlights: string[],
  actionItems: ActionItem[],
  openQuestions: string[],
}

const summarySchema: ObjectSchema = {
  type: SchemaType.OBJECT,
  properties: {
    shortSummary: { type: SchemaType.STRING },
    longSummary: { type: SchemaType.STRING },
    highlights: { type: SchemaType.ARRAY, items: { type: SchemaType.STRING } },
    actionItems: {
      type: SchemaType.ARRAY, items: {
        type: SchemaType.OBJECT, properties: {
          action: { type: SchemaType.STRING },
          maturityCategoryId: { type: SchemaType.STRING }
        }
      }
    },
    openQuestions: {
      type: SchemaType.ARRAY, items: { type: SchemaType.STRING },
    }
  },
  required: [
    "shortSummary",
    "longSummary",
    "highlights",
    "actionItems",
    "openQuestions",
  ],
}

interface SummaryCoachProps {
  business: Business;
  scoresWithNotes: MaturityScoreExtended[];
}
export const SummaryCoach: React.FC<SummaryCoachProps> = ({ business, scoresWithNotes }) => {
  const { maturityModel, loading: mmLoading } = useMaturityModel();
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState<AssessmentSession | undefined>();
  const [summary, setSummary] = useState<Summary | undefined>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (!session) return;
    (async () => {
      summarize(session);
    })()

  }, [session])


  if (mmLoading) return <p>Loading</p>
  if (!maturityModel) return <p>Error</p>

  //      * OBJECTIVE TITLE: (short title of the desired objective towards which the note and reflection is)
  //* OBJECTIVE DESCRIPTION: (longer details what the objective is about)

  const notesByDate = groupByReduceFunction(scoresWithNotes, note => dayjs(note.createdAt).format(FORMAT_DAY))
  console.log(notesByDate);

  const dates = Object.keys(notesByDate).sort().reverse();
  const sessionNotes: AssessmentSession[] = dates.map(date => ({
    id: date,
    date,
    notes: notesByDate[date]
  }))

  async function summarize(session: AssessmentSession): Promise<void> {
    if (!maturityModel) return;
    setLoading(true);
    setIsDialogOpen(true);
    try {
      const result = await invokeLlm({
        modelParams: {
          model: 'gemini-2.0-flash',
          generationConfig: {
            responseMimeType: 'application/json',
            responseSchema: summarySchema,
          },
        },
        generativeParts: createSummaryMessages(session, business, maturityModel)
      })
      const summary = JSON.parse(result.responseText);
      console.log(summary);
      setSummary(summary);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  const columns: Column<AssessmentSession>[] = [
    { id: 'date', header: 'Date', width: '120px' },
    {
      id: 'notes',
      header: 'Updates in',
      format(value, item) {
        const categories = new Set<MaturityCategory>();
        item.notes.forEach(note => {
          const category = maturityModel.categoriesMap[note.maturityCategoryId];
          if (!category) return;
          categories.add(category)
        });

        return <CategoryChipList categories={Array.from(categories)} />
      },
      sortValue(value, item) {
        const categories = new Set<MaturityCategory>();
        item.notes.forEach(note => {
          const category = maturityModel.categoriesMap[note.maturityCategoryId];
          if (!category) return;
          categories.add(category)
        });
        return categories.size;
      }
    },
    {
      id: 'objectives',
      header: 'Resolved Objectives',
      format(value, item) {
        return item.notes.filter(note => note.score === note.notesScore).length
      },
      sortValue(value, item) {
        return item.notes.filter(note => note.score === note.notesScore).length;
      }
    },
    {
      id: 'id',
      header: '',
      width: '200px',
      format(value, item) {
        return <Button startIcon={ScaleSvg.Generate}
          type="submit" color="primary" disabled={loading}
          onClick={() => setSession(item)}>Generate Summary</Button>
      }
    }
  ]

  function noteToItem(item: MaturityScore, index: number) {
    const expandedItem = extendMaturityScore(item, maturityModel!);
    const category = maturityModel?.categoriesMap[expandedItem.maturityCategoryId];
    const description = maturityModel?.descriptionLookup[descriptionLookupKeyFrom(category!, expandedItem.level!)]
    return <VariantBox variant="item" key={index}>
      <>
        {category && <CategoryChip category={category} />}
        <Typography variant="body1" sx={{ marginLeft: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', flexGrow: 1 }}>{description!.name}</Typography>
      </>
    </VariantBox>
  }

  const updatedCategories = new Set<MaturityCategory>();
  if (session) session.notes.forEach(note => {
    const category = maturityModel.categoriesMap[note.maturityCategoryId];
    if (!category) return;
    updatedCategories.add(category)
  });

  console.log(summary)
  return <>
    <SortableTable columns={columns} data={sessionNotes} initialRowsPerPage={5} rowFormatter={() => { return { background: UIColors.Interface.DimmedWhite } }} />

    <Dialog open={isDialogOpen}>
      <DialogTitle>Assessment Summary (AI)</DialogTitle>
      <DialogContent sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
        {loading && <RandomAILoadingElement />}
        {!loading && summary && session && <>
          <div>
            <Typography variant="h2">Notes, {session.date}</Typography>
            <VariantBox variant="box">
              <Typography>{summary.longSummary}</Typography>
              <div>
                <Typography variant="subtitle1">Highlights</Typography>
                <ul style={{
                  listStyleType: 'disc',
                  margin: '0px 0px 0px 0px',
                  paddingLeft: '16px',
                }}>
                  {summary.highlights.map((item, index) => <li key={index}><Typography>{item}</Typography></li>)}
                </ul>
              </div>

              <div>
                <Typography variant="subtitle1">Questions</Typography>
                <ul style={{
                  listStyleType: 'disc',
                  margin: '0px 0px 0px 0px',
                  paddingLeft: '16px',
                }}>
                  {summary.openQuestions.map((item, index) => <li key={index}><Typography>{item}</Typography></li>)}
                </ul>
              </div>
            </VariantBox>
          </div>

          <div>
            <Typography variant="h2">Action Items</Typography>
            <div style={{ gap: "4px", flexDirection: "column", display: "flex" }}>
              {summary.actionItems.map((item, key) => <VariantBox key={key} variant="item">
                <Typography variant="body1">{item.action}</Typography>
                <CategoryChip category={maturityModel.categoriesMap[item.maturityCategoryId]} />
              </VariantBox>)}
            </div>
          </div>

          <div>
            <Typography variant="h2">Updated Objectives</Typography>
            <VariantBox variant='item'>
              <CategoryChipList categories={Array.from(updatedCategories)} expanded={true} />
            </VariantBox>
            { /*            <div style={{ gap: "4px", flexDirection: "column", display: "flex" }}>
              {session.notes.map(noteToItem)}
            </div>
            */}
          </div>

        </>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" color="primary" onClick={() => setIsDialogOpen(false)}>Ok</Button>
      </DialogActions>
    </Dialog>
  </>
}


function noteToPrompt(score: MaturityScoreExtended, maturityModel: MaturityModel) {
  return `
NOTE ${score.id}
* NOTE DATE: ${score.createdAt}
* OBJECTIVE CATEGORY: ${score.category?.name} [id: ${score.category?.id}] (Level ${score.level?.level}: ${score.level?.name})
* TEAM'S NOTES: ${score.notes} 
      * TEAM'S ASSESSMENT OF OBJECTIVE COMPLETION: ${score.score === score.notesScore}
  `;
}

function createSummaryMessages(session: AssessmentSession, business: Business, maturityModel: MaturityModel): Part[] {
  const notesToSummarize = session.notes.map(score => extendMaturityScore(score, maturityModel));

  const prompt: Part[] = [
    ROLE_PROMPT,
    PROJECT_PROMPT,
    {
      text: `In addition you will be provided a list of notes from a review and planning session of the project team.
      The Information will be provided for each objective reviewed and considered during the planning session.The format for each objective will be:
      * NOTE DATE: (Time and date of the note in question)
      * OBJECTIVE CATEGORY NAME: (type of objective)
      * TEAM'S NOTES: (team's written notes on their discussion and reflection on the objective and what they have done and think they need still to do) 
      * TEAM'S ASSESSMENT OF OBJECTIVE COMPLETION: (either true or false) 
      `},
    { text: `Your task is to create a helpful succint summary of the reflections of the team on the different objectives.Please pay special focus on summarizing the next steps and ACTION ITEMS that team has decided and also please highlight if you think some decisions still need to be made by the team.` },
    {
      text: `Provide your summary in the following format:
\`\`\`json  
{
      "shortSummary": "<short summary, maximum of 2 - 3 sentences>",
      "longSummary": "<longer summary of the session, what was discussed and what were the key insights>",
      "highlights": [ "<array of key highlights that you think succintly capture the essence of the session>" ],
      "actionItems": [ "<array of decided next steps and actions items from the team. The array consists of json objects, where 'action' contains the human readable action proposal text and 'maturityCategoryId' has id of the OBJECTIVE CATEGORY that the action is related to>" ],
      "openQuestions": [ "<array of open topics or questions to explore>" ]
}
\`\`\`    
      `},
    {
      text: `
**Additional Guidance:**
* Write succintly, keeping sentences short.
* Focus on plans, open actions and questions.
* Identify topics and shifts in ownership, responsibility, agreement and alignment.
* Use similar language as used in the TEAM'S NOTES and when it makes sense, refer to the different stakeholders with the names and acronyms used by the notes.
* For the OPEN QUESTIONS AND TOPICS, it is good to write concrete questions that were left open or unanswered. You can also propose new questions that you think are relevant as a startup coach.
`},
    // User content
    {
      text: `

        PROJECT NAME: ${business.name}

        PROJECT DESCRIPTION: ${business.description}

        NOTES OF THE SESSION : 
        =====
        ${notesToSummarize.map(note => noteToPrompt(note, maturityModel)).join("\n=====\n")}
      `},
    { text: `Please, provide me a succint and helpful summary of the notes of the session` }
  ]
  return prompt;
}
