import * as htmlToImage from 'html-to-image';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { MaturityNotesDialog, NoteStatus } from '../components/dialogs/MaturityNotesDialog';
import { MaturityTable } from '../components/MaturityTable/MaturityTable';
import { MaturityDescriptionExtended, MaturityLevel, MaturityScore, MaturityScoreExtended } from '../model/ScaleTypes';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import { withIdentityChecks } from '../components/withIdentityChecks';
import { flattenGroupBy, groupByReduce } from '../util/ScaleUtils';
import { saveAs } from '../util/GuiUtils';
import dayjs from 'dayjs';
import useBusiness from '../state/useBusiness';
import useMaturityModel from '../state/useMaturityModel';
import ConfettiComponent from '../components/ConfettiComponent';
import { useMaturityScoreData } from '../state/useMaturityScoreData';
import { buildMaturityNoteOperation } from '../model/ScaleDataProviderUtils';
import { Alert, Grid } from '@mui/material';
import { DashboardTopRow } from '../components/DashboardTopRow';
import { LoadingAnimation } from '../components/LoadingAnimation';

const exportMaturityTableAsPng = () => {
  var element = document.getElementById('maturityTable')
  if (element === null) return;
  htmlToImage.toPng(element).then(function (dataUrl) { saveAs(dataUrl, 'export.png') });
}

export type ScoresByCategories = { [categoryId: string]: MaturityScore };

const MaturityView = () => {
  const redirect = useRedirect();
  const redirectToBusinessInfo = () => { redirect('show', '/api_business', businessId) };

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { businessId } = useParams<{ businessId: string }>()
  const { maturityModel, loading: mmLoading } = useMaturityModel();

  const [updatedAt, setUpdatedAt] = React.useState<number>(Date.now());
  const [partyOn, setParty] = React.useState(false);

  const [scoresByCategory, setScoresByCategory] = React.useState<ScoresByCategories>({});
  const [hiddenLevels, setHiddenLevels] = React.useState<number[]>([]);
  const [levels, setLevels] = React.useState<MaturityLevel[]>([]);

  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [chosenCategoryLevel, setChosenCategoryLevel] = React.useState<null | MaturityDescriptionExtended>(null);
  const [chosenScore, setChosenScore] = React.useState<MaturityScoreExtended>();

  const { data: maturityScoreData, isLoading: isLoadingScoreData, refetch } = useMaturityScoreData(businessId);

  const { business, isLoading: isLoadingBusiness } = useBusiness(businessId!);
  const isLoading = isLoadingBusiness || mmLoading || isLoadingScoreData;

  const updateScoresByCategory = (state: ScoresByCategories, score: MaturityScore) => {
    if (!score) return state;
    state[score.maturityCategoryId] = score;
    return state;
  }

  React.useEffect(() => {
    const latestScores: MaturityScore[] = flattenGroupBy(
      groupByReduce(maturityScoreData, "maturityCategoryId"),
      values => values[0]
    );

    const newState = latestScores.reduce((newState, latest) => {
      return updateScoresByCategory(newState, latest)
    }, {})
    setScoresByCategory(newState);
  }, [maturityScoreData])

  // need to refetch the notes after update.
  React.useEffect(() => {
    console.log("Refetching")
    refetch()
  }, [updatedAt])

  React.useEffect(() => {
    if (!maturityModel) return;
    if (!business) return;
    let lvls = maturityModel.levels.sort((levA, levB) => levA.level - levB.level).reverse();

    // SECTION WHICH HIDES LEVELS
    if (business.plans) {
      business.plans.sort((planA, planB) => dayjs(planA.targetDate).diff(planB.targetDate));
      const ongoingPlan = business.plans.find(plan => dayjs().isBetween(plan.startDate, plan.targetDate));
      if (ongoingPlan) {
        const hiddenLevels = lvls.filter(level => level.level > (ongoingPlan.targetLevel || 10) || level.level <= (ongoingPlan.startLevel || 0)).map(lvl => lvl.level);
        // hide the levels outside of current plan
        setHiddenLevels(hiddenLevels);
      }
    }
    setLevels(lvls);
    setUpdatedAt(Date.now());

    // there is an warning on missing these, but they are deliberately omitted: latestScores, descriptionData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maturityModel, business, scoresByCategory])

  if (isLoading) return <LoadingAnimation loadingText='Loading maturity assessment' />;
  if (!businessId || business === null) return <Alert severity='error'>Missing businessId</Alert>;
  if (!maturityModel) return <Alert severity='error'>Missing Maturity Model</Alert>;

  const maturityNoteUpdater = buildMaturityNoteOperation(
    "update", dataProvider,
    () => { notify("pos.maturity.commentUpdated"); setUpdatedAt(Date.now()); },
    () => notify("pos.maturity.error", { type: "error" }),
  );

  const maturityNoteDeleter = buildMaturityNoteOperation(
    "delete", dataProvider,
    () => { notify("pos.maturity.commentDeleted"); setUpdatedAt(Date.now()); },
    () => notify("pos.maturity.error", { type: "error" }),
  );

  const maturityNoteCreator = buildMaturityNoteOperation(
    "create", dataProvider,
    (note) => {
      setScoresByCategory(state => updateScoresByCategory(state, note));
      notify("pos.maturity.saved");
      setUpdatedAt(Date.now());
    },
    () => notify("pos.maturity.error", { type: "error" }),
  );

  const onSaveNote = async (maturityDescription: MaturityDescriptionExtended, status: NoteStatus, notesText: string) => {
    const scoreLevel = status === NoteStatus.RESOLVED ? maturityDescription.level.level : maturityDescription.level.level - 1;
    const payloadData: Partial<MaturityScore> = {
      maturityCategoryId: maturityDescription.maturityCategoryId,
      score: scoreLevel,
      notesScore: maturityDescription.level.level,
      notes: notesText,
      businessId
    }
    if (status === NoteStatus.RESOLVED) setParty(true);
    maturityNoteCreator(payloadData);
    setDialogOpen(false);
  }

  const onClickDescriptionCheckbox = (clickedCategoryDescription: MaturityDescriptionExtended) => {
    const lastScoreInCategory = scoresByCategory[clickedCategoryDescription.maturityCategoryId]
    const currentScore: MaturityScoreExtended = { ...lastScoreInCategory };
    if (currentScore !== undefined && currentScore !== null) {
      currentScore.level = maturityModel.levels.find(level => level.level === currentScore.score);
      currentScore.category = maturityModel.categoriesMap[currentScore.maturityCategoryId];
    }
    setDialogOpen(true);
    setChosenCategoryLevel(clickedCategoryDescription);
    setChosenScore(currentScore);
  }

  return <div id="maturityTableParty">
    <Grid container sx={{ maxWidth: 'calc(100vw - 50px)' }}>
      <DashboardTopRow project={business} current='maturity' />
    </Grid>

    <ConfettiComponent partyOn={partyOn} setParty={setParty} />
    <MaturityNotesDialog
      business={business}
      onSaveNote={onSaveNote}
      onMaturityNoteUpdate={maturityNoteUpdater}
      onMaturityNoteDelete={maturityNoteDeleter}
      isDialogOpen={isDialogOpen}
      onCloseDialog={() => setDialogOpen(false)}
      maturityDescription={chosenCategoryLevel}
      latestMaturityScore={chosenScore}
      maturityScoreArray={maturityScoreData} />
    <MaturityTable
      levels={levels}
      hiddenLevels={hiddenLevels}
      onClickBack={redirectToBusinessInfo}
      onClickExport={exportMaturityTableAsPng}
      modelData={scoresByCategory}
      onClickDescriptionCheckbox={onClickDescriptionCheckbox}
      updatedAt={updatedAt}
    />
  </div>
}

export default withIdentityChecks(MaturityView);