import { User } from "@auth0/auth0-react";
import { Button, Card, Grid, Switch, Typography } from "@mui/material";
import { useRedirect } from "react-admin";
import { useScalePermissions } from "../withIdentityChecks";
import { LoadingAnimation } from "../LoadingAnimation";

interface NewUserWelcomeProps {
  showDemoData: boolean;
  handleDemoModeChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  identity: User | undefined;
}

export function NewUserWelcome(props: NewUserWelcomeProps) {
  const { showDemoData, handleDemoModeChange, identity } = props;
  const redirect = useRedirect();
  const { permissions, isLoading } = useScalePermissions();
  if (isLoading) return <LoadingAnimation loadingText='Loading...' />
  return <Card>
    <Grid container direction='row' justifyContent='center'>
      <Grid item sm={5}>
        <Typography variant="h6">Glad to see you here{identity?.given_name !== undefined ? " " + identity.given_name : ""}!</Typography>

        {permissions.isLimitedAccess && <Typography variant="body1">
          You do not currently have access to any projects. Please ask your admin.</Typography>
        }

        {!permissions.isLimitedAccess && <>
          <Typography variant="body1">You have no projects in your portfolio yet. Start exploring by creating your first project!</Typography>
          <Grid container direction='row-reverse' justifyContent='space-evenly'>
            <Grid item><Button variant="contained" color='primary' onClick={() => redirect("scale_business/create")}>Create project</Button></Grid>
            <Grid item>
              <span className="toggleDemoData">
                <Typography>Show demo data: <Switch
                  checked={showDemoData}
                  onChange={handleDemoModeChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                </Typography>
              </span>
            </Grid>
          </Grid>
        </>}
      </Grid>
      <Grid item sm={2}>
        <img alt="Ready to explore!" src="https://storage.googleapis.com/scale_bucket-1/undraw_adventure_map_hnin.svg" width={200} />
      </Grid>
    </Grid>
  </Card>
}