import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";

interface ComparisonPeriodDropdownProps {
  period: number,
  setPeriod: (value: number) => void
}

export function ComparisonPeriodDropdown(props: ComparisonPeriodDropdownProps) {

  const handleChange = ((event: SelectChangeEvent<any>, child: React.ReactNode) => {
    props.setPeriod(event.target.value);
  });

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ m: 1, minWidth: 120 }} variant='outlined'>
        <InputLabel id="demo-select-small-label">Comparison period</InputLabel>
        <Select
          value={props.period}
          label="Comparison"
          defaultValue={6}
          onChange={handleChange}
          input={<OutlinedInput id="demo-select-small-label" label="Comparison period" />}
        >
          <MenuItem value="" disabled>Select Period</MenuItem>
          <MenuItem value={1}>1 Month</MenuItem>
          <MenuItem value={3}>3 Months</MenuItem>
          <MenuItem value={6}>6 Months</MenuItem>
          <MenuItem value={12}>1 Year</MenuItem>
          <MenuItem value={24}>2 Years</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}