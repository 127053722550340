import React, { useEffect, useState } from 'react';
import { DataProvider, Title } from 'react-admin';
import PDFViewer from '../components/pdf/PDFViewer';
import useMaturityModel from '../state/useMaturityModel';
import { useParams } from 'react-router-dom';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { Alert, Button } from '@mui/material';
import { PDFDocument, PDFForm, PDFPage, rgb } from 'pdf-lib';
import { FieldPosition, getEmbeddedImage, getFieldPositions } from '../components/pdf/PDFUtils';
import { getValueFromObject, isNotNil } from '../util/ScaleUtils';
import { BusinessExtended, BusinessHistory, MaturityModel, MaturityScoreOnly } from '../model/ScaleTypes';
import { measureText } from '../util/GuiUtils';
import dayjs from '../configuration/configuredDayjs';
import Plotly from 'plotly.js';
import { createMaturityChartData } from '../components/charts/MaturityChart';
import { PlotlyChartParams } from '../components/charts/PlotlyCharts';
import { createBusinessHistoryGraphData } from '../components/charts/BusinessHistoryGraph';
import useBusiness from '../state/useBusiness';
import { BackButton } from '../components/BackButton';
import { getScaleDataProvider, ScaleDataProvider, useScaleDataProvider } from '../state/provider/ScaleDataProvider';

const getReportTemplateUrl = async (business: BusinessExtended): Promise<string | null | undefined> => {
  const projectId = business.businessId
  const dataProvider = getScaleDataProvider()
  if (isNotNil(projectId) && isNotNil(dataProvider)) {
    try {
      const templateLink = await dataProvider.getReportTemplateLink({ projectId })
      return templateLink?.url ?? null
    } catch (e) {
      return undefined
    }
  }
}

export const canCreateReport = async (business: BusinessExtended): Promise<boolean> => {
  const templateUrl = await getReportTemplateUrl(business)
  return isNotNil(templateUrl)
}

const reportTemplateBytes = async (businesses: BusinessExtended[]): Promise<ArrayBuffer> => {
  if (businesses.length) {
    // All use the template from the first one.
    const templateUrl = await getReportTemplateUrl(businesses[0])
    if (isNotNil(templateUrl)) {
      return fetch(templateUrl).then(res => res.arrayBuffer())
    }
  }

  return new ArrayBuffer(0)
}

const createPdfReport = async (dataProvider: ScaleDataProvider, businesses: BusinessExtended[], maturityModel: MaturityModel): Promise<PDFDocument> => {
  const reportPdf = await PDFDocument.create();
  const templateBytes = await reportTemplateBytes(businesses)

  for (const business of businesses) {
    const duplicatedTemplateBytes = templateBytes.slice(0);
    const businessPdf = await createSingleReport(duplicatedTemplateBytes, { dataProvider, business, maturityModel });
    const copiedPages = await reportPdf.copyPages(businessPdf, [0]);
    reportPdf.addPage(copiedPages[0]);
  };
  return reportPdf;
}

/**
 * Parses a command string and returns the command name and parameters.
 * 
 * @param commandStr - The command string to parse.
 * @returns An object containing the command name and parameters or null if no parameters are specified.
 */
function parseCommandString(commandStr: string): { command: string, params: string } {
  const match = commandStr.match(/^([a-zA-Z]+)\(([^)]*)\)$/);

  if (!match) return { command: commandStr, params: "", };
  const [_, commandName, paramStr] = match;
  return {
    command: commandName,
    params: paramStr.trim(),
  };
}

type PDFFieldContext = {
  form: PDFForm,
  page: PDFPage,
  fieldPosition: FieldPosition,
}

type BusinessContext = {
  dataProvider: DataProvider,
  business: BusinessExtended,
  maturityModel: MaturityModel,
}

interface FieldCommand {
  name: string,
  execute(fieldName: string, args: string, fieldContext: PDFFieldContext, businessContext: BusinessContext): Promise<void>
}

class TextFieldCommand implements FieldCommand {
  name = "text";
  async execute(fieldName: string, args: string, fieldContext: PDFFieldContext, businessContext: BusinessContext): Promise<void> {
    const textField = fieldContext.form.getTextField(fieldName);
    const value = getValueFromObject(businessContext.business, args);
    if (!value) return;
    if (Array.isArray(value)) textField.setText(value.join(", "));
    else textField.setText(value);
  }
}


async function embedPlotlyDiagram(fieldContext: PDFFieldContext, chartData: PlotlyChartParams) {

  const desiredDPI = 300;
  const scaleFactor = desiredDPI / 72; // PDF Default DPI is 72

  const { form, page, fieldPosition } = fieldContext;
  const chartDiv = document.createElement('div');
  const canvas = document.createElement('canvas');
  canvas.width = fieldPosition.width;
  canvas.height = fieldPosition.height;
  await Plotly.newPlot(chartDiv, chartData.data, chartData.layout);
  const imageData = await Plotly.toImage(chartDiv, {
    format: 'png',
    scale: scaleFactor,
    width: fieldPosition.width * scaleFactor,
    height: fieldPosition.height * scaleFactor
  });
  const pngImage = await form.doc.embedPng(imageData);
  page.drawImage(pngImage, {
    x: fieldPosition.x,
    y: fieldPosition.y,
    width: fieldPosition.width,
    height: fieldPosition.height,
  });
  chartDiv.remove();
}

class MaturityChartCommand implements FieldCommand {
  name = "";
  async execute(fieldName: string, args: string, fieldContext: PDFFieldContext, businessContext: BusinessContext): Promise<void> {
    const { dataProvider, business, maturityModel } = businessContext;
    const plans = business.plans;

    const { data } = await dataProvider.getList('api_scoreByDayAndCategory', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'day', order: 'DESC' },
      filter: { businessId: business.businessId }
    });

    const chartData = createMaturityChartData({ maturityModel, plans, showAverage: false, type: 'bar', scores: data as MaturityScoreOnly[] })
    await embedPlotlyDiagram(fieldContext, chartData);
  }
}

class BusinessHistoryChartCommand implements FieldCommand {
  name = "";

  async execute(fieldName: string, args: string, fieldContext: PDFFieldContext, businessContext: BusinessContext): Promise<void> {
    const { dataProvider, business, maturityModel } = businessContext;
    const plans = business.plans;

    const { data: businessHistoryData } = await dataProvider.getList(
      'api_businessHistoryWithScore',
      {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'modifiedAt', order: 'ASC' },
        filter: { businessId: business.businessId }
      });

    const chartData = createBusinessHistoryGraphData({
      maturityModel,
      data: businessHistoryData as BusinessHistory[],
      countDaysFromStart: false, plans,
      // estimatedLaunchDate is missing as it needs to be fetched from the record.
    })
    await embedPlotlyDiagram(fieldContext, chartData);

  }

}


class KonePotentialCommand implements FieldCommand {
  name = "";
  async execute(fieldName: string, args: string, fieldContext: PDFFieldContext, businessContext: BusinessContext): Promise<void> {
    const { page, fieldPosition } = fieldContext;
    const { business } = businessContext;
    const scale = [0, 1, 5, 10, 50, 100, Infinity]
    const sectionWidth = fieldPosition.width / scale.length
    const potential = getValueFromObject(business, "businessPotential");
    const bucket = scale.findIndex(val => potential <= val * 1000000);
    const text = "estimated"; //phases[bucket - 1].name; // Get the phase name

    //const estimatedWidth = measureText(text, "6px", "Helvetica");
    page.drawText(text, {
      x: fieldPosition.x + sectionWidth * bucket, // Center horizontally
      y: fieldPosition.y + fieldPosition.height / 2 - 6 / 2, // Center vertically
      size: 6, // Adjust font size as needed
      color: rgb(0, 0, 0), // Set text color          
    });
  }
}

class KoneMilestoneCommand implements FieldCommand {
  name = "";
  async execute(fieldName: string, args: string, fieldContext: PDFFieldContext, businessContext: BusinessContext): Promise<void> {
    const { page, fieldPosition } = fieldContext;
    const { business, maturityModel } = businessContext;

    const phases = maturityModel.phases!.sort((a, b) => (a.lastLevel - b.lastLevel));
    const sectionWidth = fieldPosition.width / (phases.length + 1);
    const bucket = phases.findIndex(phase => business!.score < phase.lastLevel) + 1;
    page.drawRectangle({
      x: fieldPosition.x + sectionWidth * bucket,
      y: fieldPosition.y,
      width: sectionWidth,
      height: fieldPosition.height,
      borderWidth: 0,
      color: rgb(1, 0, 0),
      borderColor: rgb(0, 0, 0),
      opacity: 0.5
    });
    const text = "Now here"; //phases[bucket - 1].name; // Get the phase name

    const estimatedWidth = measureText(text, "10px", "Helvetica");
    page.drawText(text, {
      x: fieldPosition.x + sectionWidth * bucket + sectionWidth / 2 - estimatedWidth / 2, // Center horizontally
      y: fieldPosition.y + fieldPosition.height / 2 - 10 / 2, // Center vertically
      size: 10, // Adjust font size as needed
      color: rgb(0, 0, 0), // Set text color          
    });
  }
}

const getProjectFiles = async (projectId: string) => {
  const scaleDataProvider = getScaleDataProvider()
  if (scaleDataProvider) {
    const files = await scaleDataProvider.listProjectFiles({ projectId })
    return files
  }
  return []
}

class ScreenshotsCommands implements FieldCommand {
  name = "";
  async execute(fieldName: string, args: string, fieldContext: PDFFieldContext, businessContext: BusinessContext): Promise<void> {
    console.log('Executing ScreenshotsCommand');
    const scaleDataProvider = getScaleDataProvider()
    const { form, page, fieldPosition } = fieldContext
    const { business } = businessContext

    const projectFiles = await getProjectFiles(business.id)
    const hasProjectImage: boolean = projectFiles.length > 0
    const url = hasProjectImage
      ? (await scaleDataProvider!.getFileDownloadLink(projectFiles[0])).url
      : 'https://app.scale-company.com/scale-company-logo.png'
    const pdfImage = await getEmbeddedImage(form.doc, url)
    page.drawImage(pdfImage, {
      x: fieldPosition.x,
      y: page.getHeight() - pdfImage.size().height
    });
  }
}

const COMMAND_LOOKUP = new Map<string, FieldCommand>([
  ["text", new TextFieldCommand()],
  ["area.milestone", new KoneMilestoneCommand()],
  ["area.businessPotential", new KonePotentialCommand()],
  ["area.screenshots", new ScreenshotsCommands()], // @TODO to change 
  ["chart.maturityBars", new MaturityChartCommand()],
  ["chart.history", new BusinessHistoryChartCommand()]
]);

const createSingleReport = async (templatePdfBytes: ArrayBuffer, businessContext: BusinessContext): Promise<PDFDocument> => {
  const templateDoc = await PDFDocument.load(templatePdfBytes);
  const form = templateDoc.getForm();

  const fieldPositions = await getFieldPositions(templatePdfBytes);

  if (templateDoc.getPageCount() > 1) console.warn(`Template has ${templateDoc.getPageCount()} pages. Using the first one.`);
  const page = templateDoc.getPages()[0]; // Only the first page is used for the template. 
  // @TODO make multipage templates?

  const fieldContextPart = {
    form,
    page,
  }

  const fields = form.getFields();

  for (const field of fields) {
    const name: string = field.getName();
    const commandAndParams = parseCommandString(name);
    const command = COMMAND_LOOKUP.get(commandAndParams.command);
    const fieldPosition = fieldPositions.find(pos => pos.name === name);
    if (!fieldPosition) continue;
    const fieldContext = { ...fieldContextPart, fieldPosition };
    if (command) await command.execute(name, commandAndParams.params, fieldContext, businessContext);
    else {
      // if no command is found, execute as text command
      await COMMAND_LOOKUP.get("text")!.execute(name, commandAndParams.command, fieldContext, businessContext)
    }
  }

  form.flatten()
  await templateDoc.save()
  return templateDoc;

  // Serialize the PDF to bytes and create a blob
  // const pdfBytes = await templateDoc.save();
  // const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  //  return URL.createObjectURL(blob);
};

const CustomReportPage: React.FC = () => {
  const { businessId } = useParams<{ businessId: string }>()
  const { business, isLoading } = useBusiness(businessId || "")
  const dataProvider = useScaleDataProvider();

  const { maturityModel, loading } = useMaturityModel();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  useEffect(() => {
    const fetchPdf = async () => {
      setIsLoadingPdf(true);
      if (!business || !maturityModel || !dataProvider) return;
      try {
        const pdfBytes = await (await createPdfReport(dataProvider, [business], maturityModel)).save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        setPdfUrl(URL.createObjectURL(blob));
      } catch (error) {
        console.error("Error generating PDF:", error);
        return <Alert severity="error">Error generating PDF.</Alert>;
      } finally {
        setIsLoadingPdf(false);
      }
    };

    fetchPdf();
  }, [business, maturityModel, dataProvider]); // Only run when business or maturityModel changes

  if (isLoading || loading) return <LoadingAnimation loadingText='Loading custom report data.' />
  if (businessId === undefined) return <Alert severity="error">businessId needs to be provided.</Alert>;
  if (!business) return <Alert severity="error">Business not found.</Alert>;
  if (!maturityModel) return <Alert severity="error">Maturity Model not found.</Alert>;
  if (isLoadingPdf) return <LoadingAnimation loadingText='Loading PDF.' />;
  if (!pdfUrl) return <Alert severity="error">PDF URL not found.</Alert>;


  return (
    <div>
      <Title title="Custom One Page Report" />
      <PDFViewer url={pdfUrl} />
      <Button variant="contained" onClick={() => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'custom_report.pdf';
        link.click();
      }}>Download PDF</Button>
      <BackButton />
    </div>
  );
};

export const handleDownloadReport = async (dataProvider: ScaleDataProvider, businesses: BusinessExtended[], maturityModel: MaturityModel) => {
  if (!businesses || !maturityModel) return;
  try {
    const pdfBytes = await (await createPdfReport(dataProvider, businesses, maturityModel)).save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const formattedDateTime = dayjs().format('YYYY-MM-DD_HHmm');

    // Trigger download immediately
    const link = document.createElement('a');
    link.href = url;
    link.download = `Scale_report_${formattedDateTime}.pdf`;
    link.click();
  } catch (error) {
    console.error("Error generating PDF:", error);
  } finally {
  }
};

export default CustomReportPage;
