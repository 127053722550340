import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import './App.css';
import { store } from './state/Store';
import { MainWithAuth } from './MainWithAuth';
import { BrowserRouter as Router } from 'react-router-dom';
import authConfig from './configuration/authConfig'
import { TrelloApiProvider } from './integrations/trello/trelloContext';
import { AxiosTrelloAPI } from './integrations/trello/trelloApi';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './layout/theme';
import { SettingsProvider } from './state/SettingsProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { isFakeDataEnabled } from './configuration/featureFlags';
import dayjs from './configuration/configuredDayjs';

const trelloApiInstance = isFakeDataEnabled()
  ? null
  : new AxiosTrelloAPI();

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* @ts-ignore */}
      <Provider store={store}>
        <Router>
          <Auth0Provider
            domain={authConfig.domain}
            clientId={authConfig.clientId}
            redirectUri={authConfig.redirectURI}
          >
            <TrelloApiProvider value={trelloApiInstance}>
              <SettingsProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <MainWithAuth />
                </LocalizationProvider>
              </SettingsProvider>
            </TrelloApiProvider>
          </Auth0Provider>
        </Router>
      </Provider>
    </ThemeProvider>
  )
}

export default App;

