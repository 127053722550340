import React from "react";

type VideoIframeProps = {
  title: string;
  src: string;
};

export const VideoIframe = React.memo(({ title, src }: VideoIframeProps) => {
  const aspectRatio = 16 / 9;

  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  let width = viewportWidth * 0.8;
  let height = width / aspectRatio;
  if (height > viewportHeight * 0.8) {
    height = viewportHeight * 0.8;
  }
  return (
    <iframe
      style={{ width: `100%`, height: `${height}px` }}
      src={src}
      title={title}
    />
  );
});