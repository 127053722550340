// theme.ts (Corrected)
import { defaultTheme } from "react-admin";
import { createTheme, ThemeOptions } from "@mui/material/styles"; // Import ThemeOptions
import merge from "lodash/merge";
import { UIColors } from "../components/Colors";
import { ScaleTypography } from "../components/Styles";
import chroma from "chroma-js";
import { ButtonProps } from "@mui/material";
import { ScaleSvg } from '../components/Icons';


export type ButtonVariant = 'PrimaryCTA' | 'QuartaryCTA' | 'text' | 'outlined' | 'contained'; // Add standard variants too


function blendColors(baseColorHex: string, overlayColorRgba: string): string {
    const base = chroma(baseColorHex);
    const overlay = chroma(overlayColorRgba);
    const alpha = overlay.alpha();
    return chroma.mix(base, overlay, alpha, 'rgb').hex();
}

export const SCALE_STANDARD_FONT_SIZE = 12;

export const SCALE_THEME: ThemeOptions = {
    spacing: 8,
    typography: {
        fontFamily: '"Roboto", serif',
        fontSize: 12,
        h1: {
            ...ScaleTypography.Title.Big
        },
        h2: {
            ...ScaleTypography.Title.Small
        },
        h3: {
            ...ScaleTypography.Bold.Big
        },
        h4: {
            ...ScaleTypography.Bold.Small
        },
        body1: {
            ...ScaleTypography.Body.Medium
        },
        body2: {
            ...ScaleTypography.Body.Small
        },
        caption: {

        },
        subtitle1: {
            fontWeight: '700'
        }
    },
    palette: {
        background: {
            default: UIColors.Interface.BackgroundGrey
        },
        primary: {
            main: UIColors.Interactions.DarkBlue,
        },
        secondary: {
            main: UIColors.Interactions.DarkBlue,
        },
        success: {
            main: UIColors.Interface.LightGreen,
        }
    },
    components: {
        MuiAccordionSummary: {
            styleOverrides: {
                content: { marginRight: '0px', }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: '28px',
                    borderRadius: '14px',
                    fontSize: '12px',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                    paddingRight: '12px',
                    paddingLeft: '12px',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    textTransform: 'capitalize',
                    position: 'relative',
                    overflow: 'hidden',
                    transition: 'box-shadow 0.3s ease',
                    '&.Mui-disabled': {
                        backgroundColor: UIColors.Interactions["InactiveGrey--Dark"],
                        color: UIColors.Text.Disabled,
                        cursor: 'not-allowed',
                        boxShadow: 'none',
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'PrimaryCTA' } as Partial<ButtonProps>, // Cast here
                    style: {
                        backgroundColor: UIColors.Interactions['NotificationRed--Light'],
                        color: UIColors.Text.Inverted,
                        height: '44px',
                        borderRadius: '22px',
                        minWidth: '144px',
                        fontSize: '16px',
                        '&:hover': {
                            backgroundColor: blendColors(UIColors.Interactions['NotificationRed--Light'], "rgba(255, 255, 255, 0.16)")
                        },
                        '&:active': {
                            backgroundColor: blendColors(UIColors.Interactions['NotificationRed--Light'], "rgba(37, 50, 65, 0.16)")
                        }
                    },
                },
                {
                    props: (props: ButtonProps) => props.variant === 'TetriaryCTA', // Primary color variant
                    style: {
                        backgroundColor: UIColors.Interactions.DarkBlue,
                        color: UIColors.Text.Inverted,
                        '&:hover': {
                            backgroundColor: blendColors(UIColors.Interactions.DarkBlue, "rgba(146, 192, 255, 0.24)")
                        },
                        '&:active': {
                            backgroundColor: UIColors.Text.Primary
                        }
                    },
                },
                {
                    props: (props: ButtonProps) => props.variant === 'TetriaryCTA' && props.color === 'secondary', // Primary color variant
                    style: {
                        backgroundColor: UIColors.Interactions["ActiveGreen--Dark"],
                        color: UIColors.Text.Inverted,
                        '&:hover': {
                            backgroundColor: blendColors(UIColors.Interactions["ActiveGreen--Dark"], "rgba(255, 255, 255, 0.16)")
                        },
                        '&:active': {
                            backgroundColor: blendColors(UIColors.Interactions["ActiveGreen--Dark"], "rgba(37, 50, 65, 0.24)")
                        }
                    },
                },
                {
                    props: { variant: 'QuartaryCTA' } as Partial<ButtonProps>, // Cast here
                    style: {
                        backgroundColor: UIColors.Interface.PureWhite,
                        color: UIColors.Text.Primary,
                        borderWidth: "1px",
                        borderStyle: 'solid',
                        borderColor: UIColors.Interactions.DarkBlue,
                        '&:hover': {
                            boxShadow: "0px 2px 4px 0px rgba(20, 20, 20, 0.16)",
                            backgroundColor: UIColors.Interface.PureWhite,
                        },
                        '&:active': {
                            backgroundColor: UIColors.Interactions["InactiveGrey--List"]
                        },
                        '&.Mui-disabled': {
                            backgroundColor: UIColors.Interface.PureWhite,
                            borderColor: UIColors.Interactions["InactiveGrey--Dark"],
                            color: UIColors.Interactions["InactiveGrey--Dark"]
                        }
                    },
                },
                {
                    props: { variant: 'QuinaryCTA' } as Partial<ButtonProps>,
                    style: {
                        color: UIColors.Text.Primary,
                        '&:hover': {
                            backgroundColor: UIColors.Interface.DimmedWhite
                        },
                        '&:active': {
                            backgroundColor: UIColors.Interface.DetailGrey
                        },
                        '&.Mui-disabled': {
                            backgroundColor: 'transparent',
                            color: UIColors.Interactions["InactiveGrey--Dark"],
                        }
                    },
                },
                {
                    props: { variant: 'QuinaryCTASelected' },
                    style: {
                        color: UIColors.Text.Primary,
                        backgroundColor: UIColors.Interface.DetailGrey
                    },
                },
                {
                    props: { variant: 'ViewSwitch' }, // Cast here
                    style: {
                        boxShadow: "0px 2px 4px 0px rgba(20, 20, 20, 0.08)",
                        backgroundColor: UIColors.Interface.PureWhite,
                        color: UIColors.Interactions.DarkBlue,
                        margin: 0,
                        '&:hover': {
                            boxShadow: "0px 2px 4px 0px rgba(20, 20, 20, 0.16)",
                        },
                        '&.Mui-disabled': {
                            color: UIColors.Interface.DimmedWhite,
                            boxShadow: 'none'
                        }
                    },
                },
                /*                {
                                    props: { selected: true },
                                    style: {
                                        backgroundColor: UIColors.Interactions.DarkBlue,
                                        color: UIColors.Text.Inverted,
                                        pointerEvents: 'none',
                                        '&:hover::before': {
                                            display: 'none',
                                        },
                                        borderColor: UIColors.Interactions.DarkBlue,
                                    },
                                },*/
            ],
        },
        MuiList: {
            styleOverrides: {
                root: {
                },
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    margin: '0px',
                    backgroundColor: UIColors.Interface.PureWhite,
                    borderRadius: '8px',
                    padding: '24px !important',
                    boxShadow: '0px 2px 24px 0px #1B1B1B1F',
                },
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: '0em 0em 0em 0em',
                    marginBottom: '16px',
                },
                title: {
                    ...ScaleTypography.Title.Big,
                    fontWeight: '600'
                },
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '0em 0em 0em 0em !important',
                    margin: '0px',
                },
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '0em 0em 0em 0em !important',
                },
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    borderWidth: "1.5px",
                    borderStyle: "solid",
                    borderRadius: '4px',
                    borderColor: UIColors.Interactions.DarkBlue,
                    color: UIColors.Special.Invisible,
                    '&.Mui-checked': {
                        borderWidth: '0px',
                        backgroundColor: UIColors.Interactions.ActiveGreen,
                        color: 'black'
                    },
                    '&.Mui-disabled': {
                    },
                },
            },
        },
        MuiChip: {
            defaultProps: {
                deleteIcon: ScaleSvg.CloseLine
            },
            styleOverrides: {
                root: {
                },
            },
            variants: [
                {
                    props: { variant: 'tag' },
                    style: {
                        background: UIColors.Interface.DetailGrey,
                        padding: '5px 6px 5px 6px',
                        borderRadius: '4px',
                        margin: '2px',
                        marginRight: "4px",
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                        lineHeight: '15px',
                        height: '20px',
                        alignContent: 'center',
                        '& .MuiChip-label': {
                            padding: 0,
                        },
                        '& .MuiChip-deleteIcon': {
                            color: 'currentColor',
                            paddingLeft: '5px',
                            margin: 0,
                        },
                        '&:hover .MuiChip-deleteIcon': {
                            color: 'currentColor',
                        },
                    },
                },
            ]
        },
        MuiGrid: {
            styleOverrides: {
                root: { width: "unset", },
                item: { padding: "8px", },
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    ...ScaleTypography.Title.Big
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    marginRight: "1em",
                    marginBottom: "1em"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: UIColors.Text.Primary
                },
            },
            variants: [
                {
                    props: { variant: 'Label' },
                    style: {
                        whiteSpace: 'nowrap',
                        color: UIColors.Text.Secondary,
                        ...ScaleTypography.Body.Small
                    }
                },
                {
                    props: { variant: 'Title/Small' },
                    style: {
                        whiteSpace: 'nowrap',
                        color: UIColors.Text.Secondary,
                        ...ScaleTypography.Title.Small
                    }
                },
                {
                    props: { variant: 'Regular/Big' },
                    style: {
                        ...ScaleTypography.Body.Big
                    }
                },
            ]
        },
        RaLayout: {
            styleOverrides: {
                root: {
                    width: "unset",
                    overflow: "visible",
                },
            }
        },
        RaSidebar: {
            styleOverrides: {
                root: {
                    width: "unset",
                    overflow: "visible",
                },
            }
        },
        RaMenu: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    width: "unset",
                    zIndex: 1,
                    backgroundColor: UIColors.Interface.PureWhite,
                    margin: '8px 0px',
                    borderRadius: '0 4px 4px 0',
                    boxShadow: '0px 2px 24px 0px #1B1B1B1F',
                },
            }
        },
        // these are the menu icons
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: UIColors.Text.Primary
                }
            }
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    color: UIColors.Text.Primary,
                },
                active: {
                }
            }
        },
        RaTextInput: {
            styleOverrides: {
                root: {
                    color: 'red',
                },
                active: {
                }
            }
        },
        VariantBox: {
            variants: [
                {
                    props: { variant: 'item' },
                    style: {
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 1,
                        padding: 1,
                        border: "1px solid",
                        borderColor: UIColors.Interface.DetailGrey,
                        background: UIColors.Interface.DimmedWhite,
                        justifyContent: 'space-between'
                    },
                },
                {
                    props: { variant: 'box' },
                    style: {
                        display: "flex",
                        flexDirection: "column",
                        border: '1px solid',
                        borderColor: UIColors.Interactions.DarkBlue,
                        padding: 2,
                        gap: "8px"
                    }
                },
                {
                    props: { variant: 'column' },
                    style: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }
                },
                {
                    props: { variant: 'row-nowrap' },
                    style: {
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignItems: 'center',
                        gap: 0.75
                    }
                },
                {
                    props: { variant: 'row-joined' },
                    style: {
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignItems: 'center',
                    }
                }
            ],
        },
    }
}

// Merge correctly, with SCALE_THEME overriding defaultTheme
export const theme = createTheme(merge({}, defaultTheme, SCALE_THEME));