import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    generic: {
        loading: 'Loading %{content} data...',
        edit: 'Edit Information',
        created: '%{itemType} created!',
        updated: '%{itemType} updated!',
        deleted: '%{itemType} deleted successfully!',
    },
    warnings: {
        delete: {
            project_name_not_matching: 'Project name does not match.'
        },
        project: {
            report: {
                template_missing: 'no report template'
            }
        }
    },
    errors: {
        empty: 'Cannot be empty.',
        missing: 'No %{itemType} found!',
        loading: 'Error loading information, please contact the support via the bottom right corner.',
        maturity_model_missing: 'Maturity model is not available, please contact the support via the bottom right corner.'
    },
    actions: {
        generic_edit: 'Edit',
        open_assessment: 'Open Maturity Assessment',
        edit_information: 'Edit Project Information',
        open_report: 'Open Report',
        open_report_template_missing: 'Open Report [no template]',
        milestone_add: 'Add milestone plan'
    },
    business: {
        label: 'Project',
        name: 'Name',
        status: 'Status',
        description: 'General Description',
        problemDescription: 'Customer (Stakeholder) Value',
        businessBenefits: 'Benefits for our Organization',
        organizationUnit: 'Organization Unit',
        stakeholders: 'Who is involved',
        score: 'Score',
        last_updated: 'Last Updated',
        budget: 'Budget',
        create: 'New project',
        updated: 'Project updated!',
        createReport: 'Create Report',
        createReport_template_missing: 'Create Report [no template]',
        tags: 'Themes',
        list: {
            title: "Project List"
        },
        assessment_missing: 'Data will be shown after first maturity assessment.'
    },
    milestone: {
        card_header: 'Project Milestones',
        empty: 'No milestones added',
        startSelection: 'Starting maturity',
        targetSelection: 'Milestone target',
        startSelectionTip: 'Level of maturity that has been reached in previous milestone. Since this is your first milestone, the starting point is level 0.',
        targetSelectionTip: 'The level of maturity or a gate that you aim to reach within this milestone.'
    },
    projectDashboard: {
        projectInformation: {
            title: 'Project Information',
            instruction: 'Start your new innovation project by filling out the basic information. It only takes a few minutes and helps your team identify and distinguish projects better.',
            actionText: 'Start here',
            editText: 'Edit',
        },
        team: {
            title: 'Team',
            instruction: 'Identify people with different skill sets and customer access and add them to your project team. Scale encourages building multidisciplinary teams for holistic thinking.',
            actionText: 'Build your team',
            editText: 'Edit',
        },
        businessGoals: {
            title: 'Business goals',
            instruction: 'Linking innovation to clear objectives improve focus on creating solutions that add measurable value. Please input your business goals to guide your innovation efforts effectively.',
            actionText: 'Define goals',
            editText: 'Edit',
        },
        maturityScore: {
            title: 'Maturity score',
        },
        milestones: {
            title: 'Milestones',
            instruction: "Milestones help dividing the innovation journey into meaningful and measurable targets. Define your first project milestone to track your innovation's development effectively.",
            actionText: 'Add milestone',
            editText: '+ New Milestone',
        },
        planningSessions: {
            title: 'Assessments',
            instruction: "Assessments are saved as sessions. Start an assessment session to frame the discussion, steer or review your team's work and plan on what to work on next.",
            actionText: 'Start session',
            editText: '+ New Session',
        },
        currentMaturity: {
            title: 'Current maturity',
        },
        maturityHistory: {
            title: 'Maturity history',
        },

    },
    assessmentsForm: {
        instruction: 'Choose allocated time for the assessment session and topics to focus on.',
        allocatedTime: 'Allocated time',
        selectionTitle: 'Select assessment categories for this session,',
        recreateLabel: 'Regenerate',
        recommendationExplanation: `Automatic recommendation by Scale. Relevant maturity categories have been pre-selected based on the project's current status and recent progress.`
    },
    menu: {
        settings: "Settings",
        dashboard: 'Dashboard',
        businesses: 'Projects',
        integrations: 'Integrations',
        organizationUnits: 'Org. Units',
        reports: 'Reports',
        maturityModel: 'Maturity Model',
    },
    pos: {
        businessShow: {
            maturityScore: "Maturity Score",
            currentMaturity: "Current Maturity",
            maturityHistory: "Maturity History",
            projectInformation: "Project Information"
        },
        maturity: {
            saved: 'Notes saved!',
            commentUpdated: 'Note comment updated!',
            commentDeleted: 'Objective assesment and note removed!',
            error: 'Error updating maturity.'
        },
        earlyInsights: {
            saved: 'Insight saved!'
        },
        usermenu: {
            inviteUser: 'Invite user',
            subscription: 'Manage Subscription',
            terms: 'Terms & Conditions',
            logout: 'Logout',
        },
        configuration: 'Configuration',
        profile: 'User profile',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'dark'
        },
        sum: {
            businessPotential: 'Total Potential',
            budget: 'Total Budget',
            count: 'Number of projects',
            weightedPotential: 'Weighted potential',
            score: 'Total Maturity',
        },
        definitions: {
            businessPotential: "Third year revenue after product market fit.",
            budget: "Running yearly budget.",
            count: "",
            score: "Maturity Score, shows the maturity of based on achieved evidence and evaluation of the levels.",
            weightedPotential: "Confidence weighted potential. The maturity score is used to adjust the total potential for the business case. The calculation is 'Business Potential' * 'Maturity Score' / 'Max Maturity Score'.",
            horizonDistribution: "Percentage of projects in different horizons (H1/H2/H3).",
            h1definition: "Maintain and defend core business (1 - 2 years)",
            h2definition: "Nurture emerging business (3 - 5 years)",
            h3definition: "Create genuinely new - possibly disruptive - business (5+ years)",
            pipelineProjects: "Number of projects in different pipeline stages.",
            maturityScore: "Average score for your previous maturity assessment. Maturity scale: 0 - %{levels}",
            currentMaturity: "Maturity assessment scores by category. Dots indicate the previous assessment results.",
            maturityHistory: "Progress overtime including projected future velocity. Green shows the maximum velocity based on the historic progress where as red means the minimum velocity.",
            portfolioBubbleChart: "This is a bubble chart visualisation of your growth portfolio. Project maturity is tracked on X axis whereas Y axis represents the business potential. Size of the bubble is the budget allocated for the project.",

        }
    },
    resources:
    {
        cases: {
            tabs: {
                information: "Case Information",
            }
        },
        definitions: {
            businessPotential: "Third year revenue after product market fit"
        }
    },
};

export default customEnglishMessages;