import React, { useState } from 'react';
import { MaturityScoreExtended, isResolved } from '../../model/ScaleTypes';
import { Card, CardContent, CardHeader, IconButton, TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, Chip } from '@mui/material';
import { ScaleColors, UIColors } from '../Colors';
import { ScaleSvg } from '../Icons';
import DeleteItemButton from '../common/DeleteItemButton';
import VariantBox from '../VariantBox';

export interface HistoryNoteCardProps {
  index: number;
  note: MaturityScoreExtended;
  onMaturityNoteUpdate?: (noteToUpdate: MaturityScoreExtended) => void;
  onMaturityNoteDelete?: (noteToDelete: MaturityScoreExtended) => void;
}

enum ActionMode { HIDDEN, SHOWN }

export const HistoryNoteCard: React.FC<HistoryNoteCardProps> = ({
  index,
  note,
  onMaturityNoteUpdate,
  onMaturityNoteDelete,
  ...props
}) => {
  const isEditable = !!onMaturityNoteUpdate;
  const [actionMode, setActionMode] = useState<ActionMode>(ActionMode.HIDDEN);
  const [editedNote, setEditedNote] = useState(note ? note.notes : "");
  const [showEditDialog, setShowEditDialog] = useState(false);

  const handleEditClick = () => setShowEditDialog(true);
  const handleDeleteClick = () => {
    if (!onMaturityNoteDelete) {
      console.error("Tried to delete, but no handler given.")
      return
    }
    onMaturityNoteDelete(note)
  };

  const handleSave = () => {
    if (!onMaturityNoteUpdate) {
      console.error("Tried to save, but no handler given.")
      return;
    }
    note.notes = editedNote;
    onMaturityNoteUpdate(note);
    setShowEditDialog(false);
    setActionMode(ActionMode.HIDDEN);
  };

  const handleCancel = () => {
    setEditedNote(note.notes);
    setShowEditDialog(false);
    setActionMode(ActionMode.HIDDEN);
  };

  const canBeDeleted = index === 0;

  const standardActions = (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <IconButton size="small" onClick={handleEditClick} sx={{ width: 26 }}>{ScaleSvg.Pencil}</IconButton>
      <DeleteItemButton buttonType='icon' icon={ScaleSvg.Trash} label={'Delete'} itemType={'objective assessment'} disabled={!canBeDeleted} onConfirmDelete={handleDeleteClick} confirmationLevel={'dialog'} tooltip={canBeDeleted ? 'Delete' : "Only most recent note can be deleted"} />
    </Box>
  );

  if (!note) return (
    <Card {...props}>
      <CardContent style={{ backgroundColor: UIColors.Interface.DetailGrey }}>
        <Typography display="block" style={{ textAlign: 'center' }}>No note to show</Typography>
      </CardContent>
    </Card>
  );


  const headerContent = <VariantBox variant='row-nowrap'>
    <Typography>Level {note.notesScore}</Typography>
    <Chip label={isResolved(note) ? 'COMPLETED' : 'PROGRESS'} variant='tag' sx={{ backgroundColor: isResolved(note) ? ScaleColors.Maturity.Resolved.Standard : UIColors.Interface.DetailGrey }} />
    <Typography>{new Date(note.createdAt).toLocaleString()} {(note.createdAt !== note.modifiedAt ? ' (updated)' : '')}</Typography>
  </VariantBox>

  const headerStyle = editedNote ? {} : { margin: 0 }

  return (
    <Card key={note.id}
      onMouseEnter={() => isEditable && setActionMode(ActionMode.SHOWN)} onMouseLeave={() => setActionMode(ActionMode.HIDDEN)}
      style={{ borderColor: isResolved(note) ? "lightgreen" : UIColors.Interface.DetailGrey }}
      {...props}
    >
      <CardHeader sx={headerStyle} action={actionMode === ActionMode.SHOWN ? standardActions : undefined} subheader={headerContent} />
      <CardContent>
        <Typography display="block" whiteSpace="pre-line">{editedNote}</Typography>
      </CardContent>
      <Dialog
        open={showEditDialog}
        onClose={() => handleCancel()}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Edit note</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              style={{ minWidth: "400px" }}
              fullWidth
              multiline
              minRows={8}
              variant="outlined"
              value={editedNote}
              onChange={(e) => setEditedNote(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancel()} color="primary">Cancel</Button>
          <Button onClick={handleSave} color="primary" autoFocus>Save</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
