import { Button, CardActions, CardHeader, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Business, MaturityDescriptionExtended, MaturityScoreExtended } from "../../model/ScaleTypes";
import { ScaleSvg } from "../Icons";
import { RandomAILoadingElement } from "./RandomAILoadingElement";
import { Message } from "../../integrations/OpenAI";
import { ArraySchema, ObjectSchema, Part, SchemaType } from "@google/generative-ai";
import { invokeLlm } from "../../integrations/GeminiAPI";

type Example = {
  hypothesis: string;
  test: string;
  successCriteria: string;
  rationale: string;
};

const ExampleSchema: ObjectSchema = {
  type: SchemaType.OBJECT,
  properties: {
    hypothesis: { type: SchemaType.STRING },
    test: { type: SchemaType.STRING },
    successCriteria: { type: SchemaType.STRING },
    rationale: { type: SchemaType.STRING },
  },
  required: [
    "hypothesis",
    "test",
    "successCriteria",
    "rationale",
  ],
}

const HypothesisCoachResponseSchema: ArraySchema = {
  type: SchemaType.ARRAY,
  items: ExampleSchema
}

export const ROLE_PROMPT: Part = { text: `You are an AI startup coach specializing in helping teams develop new business ventures within large enterprises.` };
export const PROJECT_PROMPT: Part = {
  text:
    `You will be provided with the following information about a project:
* PROJECT NAME
* PROJECT DESCRIPTION
* CURRENT PERSPECTIVE (The area of the project that the team is currently focused on)
* CURRENT OBJECTIVE (The team's desired outcome, please read this carefully, and almost literally)
* DESCRIPTION (Details about the objective)
* EXAMPLES (Actions/evidence suggesting progress towards the objective)
* EARLIER NOTES (History of the team's work)`};

// Define the type for props
interface HypothesisCoachProps {
  business: Business;
  scoresWithNotes: MaturityScoreExtended[];
  maturityDescription: MaturityDescriptionExtended;
}
export const HypothesisCoach: React.FC<HypothesisCoachProps> = ({ business, scoresWithNotes, maturityDescription }) => {
  const [prompt, setPrompt] = useState<Part[]>([]);
  const [loading, setLoading] = useState(false);

  const [examples, setExamples] = useState<Example[]>([]);

  if (prompt.length === 0) {
    setPrompt([
      ROLE_PROMPT,
      PROJECT_PROMPT,
      { text: `Your task is to generate a list of testable hypotheses and concrete tests related to the project's objective. These hypotheses should propose a causal relationship between the team's actions and measurable outcomes that indicate progress towards achieving the objective.` },
      {
        text: `As an output please provide a list of hypotheses and corresponding tests in the following format:
        
      ### Example[Number]:
\`\`\`json  
      {
         "hypothesis": "If [team action], then [measurable outcome] will happen because [reasoning]",
         "test": "[Clear plan to implement the action, collect data, and analyze results]",
         "successCriteria": "[Pre-defined thresholds or benchmarks for the outcome]"
         "rationale": "[Your rationale about why you suggest this hypothesis for for this team. Base your rationale to a specific facts or notes from projects situation that is the basis for your suggestions.']"
      }
\`\`\`         
`},
      {
        text: `
**Additional Guidance:**
* Specifically aim to solve the CURRENT OBJECTIVE, and not the entire project. Focus on what literally the objective says.
* Consider the CURRENT PERSPECTIVE, while other validations might be important, your guidance should be focused on the CURRENT OBJECTIVE.
* Also consider CURRENT LEVEL. It also gives you guidance on what CURRENT OBJECTIVE is related to. For example "Problem Validation" means that the CURRENT OBJECTIVE is related to validating that the problem exists.
* Prioritize hypotheses based on potential impact and feasibility.
* When providing suggestions, where reasonable, use the language and terms that refer the content from the EARLIER NOTES.
* Encourage continuous refinement of hypotheses and tests based on learnings.
* Be aware that some objectives might have qualitative components, not just quantitative ones. 
`},
      // USER PROMPT
      {
        text: `

        PROJECT NAME: ${business.name}

        PROJECT DESCRIPTION: ${business.description}

        CURRENT PERSPECTIVE: ${maturityDescription.category.name}

        CURRENT OBJECTIVE: ${maturityDescription.name}

        CURRENT LEVEL: ${maturityDescription.level.name}

        DESCRIPTION: ${maturityDescription.description}

        EXAMPLES: ${maturityDescription.examples}

        EARLIER NOTES:
        
        ${scoresWithNotes.map((note, index) => `NOTE FOR LEVEL ${note.level}: 
          ${note.notes}`)}
        
      `},
      { text: "Provide me a list of maximum 3 different types of hypotheses, and concrete tests to validate them related to my project so that I can say the objective has been achieved. Please be succint in your answers." }
    ])
  }



  // Function to parse JSON strings into objects
  function parseExamples(jsonStrings: string[]): Example[] {
    return jsonStrings.map(jsonString => JSON.parse(jsonString));
  }

  async function handleSubmit(): Promise<void> {
    setLoading(true);
    try {
      const modelParams = {
        model: 'gemini-2.0-flash',
        generationConfig: {
          responseMimeType: 'application/json',
          responseSchema: HypothesisCoachResponseSchema,
        },
      };

      const result = await invokeLlm({
        modelParams,
        generativeParts: prompt
      })

      const examples = JSON.parse(result.responseText);
      setExamples(examples);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  return <Card>
    <CardHeader title={
      <Typography variant="button" style={{ fontSize: '0.9em', marginBottom: '10px' }}>🤖 Scale Coach (beta)</Typography>

    } />
    <CardContent>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Hypothesis</TableCell>
              <TableCell>Implementation Plan</TableCell>
              <TableCell>Target Outcome</TableCell>
              <TableCell>Rationale</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <RandomAILoadingElement />}
            {!loading && examples.map((example, index) => (
              <TableRow key={index}>
                <TableCell>{example.hypothesis}</TableCell>
                <TableCell>{example.test}</TableCell>
                <TableCell>{example.successCriteria}</TableCell>
                <TableCell>{example.rationale}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </CardContent>
    <CardActions>
      <Button sx={{ marginTop: '8px' }} variant='QuartaryCTA' startIcon={ScaleSvg.Generate} type="submit" disabled={loading} onClick={handleSubmit}>Suggest targets</Button>
    </CardActions>
  </Card>

};

export default HypothesisCoach
