import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Paper, Box, Grid, List, ListItem, ListItemIcon, ListItemText, Tab, Divider, Alert } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Business, MaturityDescriptionExtended, MaturityScoreExtended, MaturityTool } from '../../model/ScaleTypes';
import Tabs from '@mui/material/Tabs';
import { toParagraphs } from '../../util/GuiUtils';
import { HistoryNoteCard } from '../cards/HistoryNoteCard';
import HypothesisCoach from '../scale-coach/HypothesisCoach';
import { UIColors } from '../Colors';
import { CategoryChip } from '../CategoryChip';
import { ScaleTypography } from '../Styles';
import { ScaleSvg } from '../Icons';
import VariantBox from '../VariantBox';

export enum NoteStatus {
  RESOLVED,
  UNRESOLVED
}

export interface MaturityNotesDialogProps {
  business: Business;
  isDialogOpen: boolean;
  onCloseDialog?: () => void;
  onSaveNote?: (maturityDescrption: MaturityDescriptionExtended, status: NoteStatus, newNoteText: string) => Promise<void>;
  onMaturityNoteUpdate?: (noteToUpdate: MaturityScoreExtended) => void;
  onMaturityNoteDelete?: (noteToDelete: MaturityScoreExtended) => void;
  maturityDescription: null | MaturityDescriptionExtended;
  latestMaturityScore?: MaturityScoreExtended;
  maturityScoreArray: MaturityScoreExtended[];
}

export const MaturityNotesDialog: React.FC<MaturityNotesDialogProps> = ({
  business,
  isDialogOpen,
  onCloseDialog = () => { },
  onSaveNote = () => { },
  onMaturityNoteUpdate = () => { },
  onMaturityNoteDelete = () => { },
  maturityDescription,
  latestMaturityScore,
  maturityScoreArray = [],
  ...props
}) => {
  const noteFieldRef = React.useRef<HTMLInputElement>();
  const [currentTab, setCurrentTab] = React.useState<number>(-1);

  const handleTabChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  useEffect(() => {
    setCurrentTab(-1);
  }, [latestMaturityScore])

  if (!maturityDescription) return null; // nothing selected for the dialog
  if (!business) return <Alert severity='error'>Missing business data.</Alert>

  const scoresWithNotes = maturityScoreArray
    .filter(history => history.maturityCategoryId === maturityDescription.maturityCategoryId);

  const handleSaveResolved = () => {
    onSaveNote(maturityDescription, NoteStatus.RESOLVED, noteFieldRef.current!.value);
  };

  const handleSaveUnresolved = () => {
    onSaveNote(maturityDescription, NoteStatus.UNRESOLVED, noteFieldRef.current!.value);
  };

  const tools = maturityDescription.tools?.filter(tool => tool.title) || [];
  const showTabs = tools.length > 0 || maturityDescription.description;

  return <Dialog fullWidth maxWidth={'md'} open={isDialogOpen} onClose={onCloseDialog} aria-labelledby="form-dialog-title">
    <DialogContent sx={{ backgroundColor: UIColors.Interface.PureWhite }}>
      <VariantBox variant='row-nowrap'>
        <CategoryChip category={maturityDescription.category} sx={{ margin: "0" }} />
        <Typography sx={{ ...ScaleTypography.Body.Small, color: UIColors.Text.Secondary }}>Level {maturityDescription?.level?.level}:</Typography>
        <Typography style={ScaleTypography.Body.Small}>  {maturityDescription?.level?.name}</Typography>
      </VariantBox>

      <Paper style={{ marginTop: '8px', padding: '20px', backgroundColor: UIColors.Interface.DetailGrey }}>
        <Typography variant='h1' display="block" sx={{ lineHeight: '36px' }}>
          {maturityDescription?.name}
        </Typography>
      </Paper>
      {showTabs &&
        <Box sx={{ width: '100%' }} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={handleTabChange} aria-label="tabs">
              <Tab key='main_tab' label="description" value={-1} />
              {tools?.map((tool, i) => <Tab key={i} label={`Tool: ${tool.title}`} value={i} />)}
            </Tabs>
          </Box>
          {currentTab < 0 &&
            <Box style={{ padding: '12px' }}>
              {maturityDescription.description.length > 0 &&
                <Typography whiteSpace="pre-line" display="block" style={{ marginBottom: '10px' }}>
                  {toParagraphs(maturityDescription?.description)}
                </Typography>
              }
              {maturityDescription?.examples?.length > 0 && <>
                <Typography style={{ fontWeight: '700', fontSize: '0.9em', marginBottom: '10px' }}>Examples:</Typography>
                <Typography variant='body1' display="block">{toParagraphs(maturityDescription?.examples)}</Typography>
              </>}
            </Box>
          }
          {tools && currentTab >= 0 && currentTab <= tools.length &&
            <Box style={{ padding: '12px' }} >
              <ToolTabContent key={currentTab} item={tools[currentTab]} />
            </Box>}
        </Box>}
      <Divider />
      <Box style={{ padding: '12px' }}>
        <Typography variant="button" style={{ fontSize: '0.9em', marginBottom: '10px' }}>Comments</Typography>

        <Grid container>
          <Grid item sm={12} md={8}>
            <TextField
              autoFocus
              margin="dense"
              id="notes"
              type="text"
              multiline
              fullWidth
              variant="outlined"
              minRows={8}
              inputRef={noteFieldRef}
              placeholder="What evidence justifies that you have completed this objective? What is still missing? Write it down here."
            />
          </Grid>
          <Grid item sm={12} md={4} >
            {
              <List dense={true}>
                <ListItem>
                  <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                  <ListItemText primary="What evidence justifies that you have completed this objective?" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                  <ListItemText primary="What is still missing?" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                  <ListItemText primary="What are the next tasks?" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                  <ListItemText primary="Who does what?" />
                </ListItem>
              </List>
            }
          </Grid>
        </Grid>

        <Grid item>
          <HypothesisCoach business={business} maturityDescription={maturityDescription} scoresWithNotes={scoresWithNotes} />
        </Grid>

        {scoresWithNotes?.map((note: MaturityScoreExtended, index: number) =>
          (<Grid key={note.id || index} item><HistoryNoteCard index={index} note={note} onMaturityNoteDelete={onMaturityNoteDelete} onMaturityNoteUpdate={onMaturityNoteUpdate} /></Grid>))}
      </Box>
    </DialogContent>

    <DialogActions>
      <VariantBox variant='row-nowrap'>
        <Button variant='QuartaryCTA' onClick={onCloseDialog}>Exit</Button>
        <Button variant='TetriaryCTA' type="submit" onClick={() => { handleSaveUnresolved() }}>Save Progress</Button>
        <Button variant='TetriaryCTA' color='secondary' startIcon={ScaleSvg.CheckFilledColored} type="submit" onClick={() => { handleSaveResolved() }}>
          Save & Complete
        </Button>
      </VariantBox>
    </DialogActions>
  </Dialog >
};

function ToolTabContent(props: { item: MaturityTool }) {
  if (!props.item) return <></>
  return (
    <Box style={{ padding: '12px' }}>
      <img src={props.item.img ?? '/tools-placeholder.png'} alt="Tools and Instructions" style={{ maxHeight: '500px', maxWidth: '370px', marginBottom: '10px' }} /><br />
      <Typography variant='button' style={{ fontSize: '0.9em', fontWeight: 'bold', marginBottom: '10px' }}>{props.item.title ?? ""}</Typography>
      <Typography display="block" style={{ marginBottom: '10px' }}>{toParagraphs(props.item.description) ?? ""}</Typography>
      <Grid container alignContent='center' alignItems='center' direction='column'>
        {props.item["ext-url"] && <Grid item xs={3}>
          <Typography variant='button'><a href={props.item["ext-url"] ?? ""} rel="noreferrer" target="_blank">Read more</a></Typography>
        </Grid>}
        {props.item.canvas && <Grid item xs={3}>
          <Typography variant='button'><a href={props.item.canvas ?? ""} rel="noreferrer" target="_blank">Download canvas</a></Typography>
        </Grid>}
      </Grid>
    </Box>
  )
}

