import React, { useState, useEffect } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Button } from '@mui/material'; // Import Box and Typography
import VariantBox from '../VariantBox';
import { ScaleSvg } from '../Icons';
import { UIColors } from '../Colors';

interface ScaleDatePickerProps {
  period?: 'month' | 'quarter'
  value?: Dayjs | null;
  onChange?: (newValue: Dayjs | null) => void;
}

export function ScaleDatePicker({
  value,
  period = 'month',
  onChange = () => { }
}: ScaleDatePickerProps) {
  const [viewDate, setViewDate] = useState<Dayjs>(dayjs());

  useEffect(() => {
    onChange(viewDate)
  }, [viewDate, onChange])

  const label = (period === 'quarter' ?
    `Q${viewDate.quarter()}/${viewDate.year()}` :
    `${viewDate.month() + 1}/${viewDate.year()}`) + (viewDate.isSame(dayjs(), 'month') ? ' (Today)' : '')

  return <VariantBox variant='row-joined'>
    <Button
      variant='QuartaryCTA'
      onClick={() => setViewDate(value => value.add(-1, period).endOf(period))}
      sx={{
        height: '28px',
        width: '28px',
        minWidth: '28px',
        padding: 0,
        borderRadius: "14px 0px 0px 14px",
      }}>
      {ScaleSvg.AngleLeft}
    </Button>
    <Button
      sx={{
        borderTop: 1, borderBottom: 1, borderColor: UIColors.Interactions.DarkBlue,
        background: UIColors.Interface.PureWhite,
        height: '28px',
        borderRadius: '0px'
      }}
      onClick={() => { setViewDate(dayjs().endOf(period)) }}
    >{label}</Button>
    <Button
      variant='QuartaryCTA'
      disabled={viewDate.add(1, "day").diff(dayjs(), "day") >= 0}
      onClick={() => setViewDate(value => value.add(1, period).endOf(period))}
      sx={{
        height: '28px',
        width: '28px',
        minWidth: '28px',
        padding: 0,
        borderRadius: "0px 14px 14px 0px"
      }}>
      {ScaleSvg.AngleRight}
    </Button>
  </VariantBox >

}
