import { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';
import {
  DateInput,
  required,
  SelectInput,
  TextInput,
} from 'react-admin';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import currency from 'currency.js';
import { CurrencyFormat } from '../../configuration/configuration';
import { useFormContext } from 'react-hook-form';

type Choices = { id: string, name: string }[];

const getHalfOfMaxRange = (range: string): number => {
  const [min, max] = range.split('-').map(Number);
  return max / 2;
};

const targetSelection: Choices = [
  { id: 'B2B', name: 'Businesses or Governments' },
  { id: 'B2C', name: 'Consumers' },
];

const selections: { [key: string]: { sizeSelection: Choices, costSelection: Choices } } = {
  'B2B': {
    sizeSelection: [
      { id: "0-1000", name: "Fewer than 1,000 organizations (e.g., Fortune 1000, cities/countries)" },
      { id: "1000-10000", name: "1,000 - 10,000 organizations (e.g. large enterprises)" },
      { id: "10000-100000", name: "10,000 - 100,000 organizations (e.g. mid-size and large enterprises)" },
      { id: "100000-1000000", name: "100,000 - 1,000,000 organizations (e.g. small and mid-size businesses)" },
      { id: "1000000-10000000", name: "More than 1,000,000 organizations (e.g. SMB and sole practitioners)" },
    ],
    costSelection: [
      { id: "500", name: "$500 / year (e.g., minor inconvenience / lost time)" },
      { id: "5000", name: "$5,000 / year (e.g., installed software)" },
      { id: "50000", name: "$50,000 / year (e.g. A person's salary + some software / outsourcing)" },
      { id: "500000", name: "$500,000 (e.g. an entire team, outsourcing, systems, processes)" },
      { id: "5000000", name: "$5,000,000 (e.g. entire division, multi-year contracts, integrated systems)" },
    ]
  },
  'B2C': {
    sizeSelection: [
      { id: "0-2000000", name: "Fewer than 2 million (e.g. vintage cars hobbyists and collectors in Europe)" },
      { id: "2000000-20000000", name: "Fewer than 20 million (e.g. 22 - 35 year olds in major cities; first-time parents in U.S. with toddlers, etc)" },
      { id: "20000000-200000000", name: "Fewer than 200 million (e.g K-12 students in G7 countries)" },
      { id: "200000000-2000000000", name: "More than 200 million (e.g. anyone with a smartphone in developed countries)" },
    ],
    costSelection: [
      { id: "100", name: "$100 / year (e.g. streaming or cloud service subscription, magazine subscription)" },
      { id: "300", name: "$300 / year (e.g., a regular coffee shop visits, mobile internet subscription)" },
      { id: "1000", name: "$1,000 / year (e.g., basic gym membership, regular grooming services; manicure, haircuts)" },
      { id: "3000", name: "$3,000 / year (e.g., a budget-friendly vacation, ongoing hobby costs)" },
      { id: "10000", name: "$10,000 / year (e.g., childcare, small apartment rental, personal trainer)" },
      { id: "30000", name: "$30,000 / year (e.g., family home rental)" },
      { id: "100000", name: "$100,000 / year (e.g., private school tuition, significant medical expenses)" },
    ]
  },
}

const BusinessGoalsForm = (): JSX.Element => {
  const [tamValue, setTamValue] = useState<string>("");
  const [costSelection, setCostSelection] = useState<Choices>([]);
  const [sizeSelection, setSizeSelection] = useState<Choices>([]);
  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    const businessModel = getValues("businessModel") || '';
    if (businessModel && selections[businessModel]) {
      setCostSelection(selections[businessModel].costSelection);
      setSizeSelection(selections[businessModel].sizeSelection);
    } else {
      setCostSelection([]);
      setSizeSelection([]);
    }
  }, [getValues]);

  function updateSelection() {
    const type: string = getValues("businessModel") || '';
    setCostSelection(selections[type].costSelection);
    setSizeSelection(selections[type].sizeSelection);
  }

  function calculateTam() {
    const size = getValues("size");
    const cost = getValues("cost");
    setTamValue("" + getHalfOfMaxRange(size) * Number(cost));
  }

  return (
    <Box>
      <DateInput source="startDate" defaultValue={new Date()} validate={required()} fullWidth />

      <TextInput
        format={(val) => currency(val, CurrencyFormat).format()}
        parse={(val) => currency(val, CurrencyFormat).value}
        fullWidth source="budget" validate={required()} />

      <SelectInput
        onChange={updateSelection}
        fullWidth source="businessModel"
        label="Whose problem are you solving?"
        choices={targetSelection} />

      <TextInput
        format={(val) => currency(val, CurrencyFormat).format()}
        parse={(val) => currency(val, CurrencyFormat).value}
        fullWidth source="businessPotential" validate={required()} />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>If you do not have a calculated potential, you can use the below to do a quick estimate of the Total Addressable Market.</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SelectInput
            disabled={sizeSelection.length === 0}
            onChange={calculateTam}
            fullWidth source="size"
            label="How big is the estimated market?"
            choices={sizeSelection} />

          <SelectInput
            disabled={costSelection.length === 0}
            onChange={calculateTam}
            fullWidth source="cost"
            label="How much are they currently paying to resolve the problem?"
            choices={costSelection} />

          <Typography>Calculated potential, TAM (Total Addressable Market)</Typography>
          <Typography gutterBottom>{currency(tamValue, CurrencyFormat).format()}</Typography>

          <Button
            disabled={Number.isNaN(Number(tamValue)) || Number(tamValue) === 0}
            variant='outlined'
            onClick={() => setValue("businessPotential", tamValue)}>
            Use as Business potential
          </Button>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default BusinessGoalsForm;
