import React from 'react';
import TableCell from '@mui/material/TableCell';
import InfoIcon from '@mui/icons-material/Info';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Tooltip from '@mui/material/Tooltip';
import { MaturityDescriptionExtended } from "../../model/ScaleTypes";
import { toParagraphs } from "../../util/GuiUtils";
import { ScaleColors, UIColors } from '../Colors';
import { createSvgIcon, Table } from '@mui/material';
import { ScaleSvg } from '../Icons';

export type MaturityDescriptionState = 'checked' | 'inprogress' | 'unchecked' | 'disabled';

type StateConfig = { sideBackground: string; mainBackground: string; Icon?: React.ElementType, iconColor: string }

const stateConfig: Record<MaturityDescriptionState, StateConfig> = {
  checked: {
    sideBackground: ScaleColors.Maturity.Resolved.Dark,
    mainBackground: ScaleColors.Maturity.Resolved.Standard,
    Icon: createSvgIcon(ScaleSvg.CheckFilledColored, "filled"),
    iconColor: 'black',
  },
  inprogress: {
    sideBackground: ScaleColors.Maturity.InProgress.Dark,
    mainBackground: ScaleColors.Maturity.InProgress.Standard,
    Icon: createSvgIcon(ScaleSvg.CheckNext, "next"),
    iconColor: 'black',
  },
  unchecked: {
    sideBackground: UIColors.Interface.DetailGrey,
    mainBackground: UIColors.Interface.DimmedWhite,
    Icon: createSvgIcon(ScaleSvg.CheckEmptyColored, "empty"),
    iconColor: UIColors.Special.Invisible,
  },
  disabled: {
    sideBackground: '#f4f4f4',
    mainBackground: UIColors.Special.Invisible,
    Icon: CheckBoxOutlineBlankIcon,
    iconColor: UIColors.Special.Invisible,
  },
};



export interface MaturityDesciptionCellProps {
  key: React.Key;
  maturityDescription: MaturityDescriptionExtended;
  state: MaturityDescriptionState;
  onClickDescriptionCheckbox: (clickedCategoryDescription: MaturityDescriptionExtended) => void;
  updatedAt: number;
}

export const MaturityDescriptionTableCell: React.FC<MaturityDesciptionCellProps> = ({ updatedAt, maturityDescription, state, onClickDescriptionCheckbox }) => {
  const finalState = state;
  const config = stateConfig[finalState];
  const isDisabled = (state === 'disabled');

  const handleClick = () => !isDisabled && onClickDescriptionCheckbox(maturityDescription);
  const checkBoxPart = <TableCell onClick={handleClick} style={{ backgroundColor: config.sideBackground, padding: '0px', cursor: !isDisabled ? 'pointer' : 'default' }}>
    {config.Icon && <config.Icon fontSize="small" style={{ color: config.iconColor, margin: '5px', borderRadius: '50%' }} />}
  </TableCell>
  return (<>
    {checkBoxPart}
    <DescriptionCell handleClick={handleClick} maturityDescription={maturityDescription} config={config} />
  </>)
}

interface DesciptionCellProps {
  handleClick: () => void;
  maturityDescription: MaturityDescriptionExtended;
  config: StateConfig
}

const DescriptionCell: React.FC<DesciptionCellProps> = ({ handleClick, maturityDescription, config }) => {
  if (!maturityDescription) return <TableCell></TableCell>
  const { category, level } = maturityDescription;
  const toolTipDescription = toParagraphs(
    maturityDescription.description +
    (maturityDescription.examples?.length > 0 ? "\nExamples\n" + maturityDescription.examples : ""));

  return <TableCell
    onClick={handleClick}
    className={
      category.name === 'Financial Model'
        ? `fin_${level.level}`
        : category.name === 'Team Composition'
          ? `team_${level.level}`
          : ''
    }
    style={{ backgroundColor: config.mainBackground, cursor: 'pointer' }}>
    <p style={{ fontSize: '0.85em' }}>
      {maturityDescription.name}
      {maturityDescription.description?.length > 0 && (
        <Tooltip title={toolTipDescription} arrow>
          <InfoIcon
            style={{
              transform: 'scale(0.5)',
              padding: -3,
              margin: -3,
              verticalAlign: 'bottom',
              color: 'orange',
            }}
            fontSize="small"
          />
        </Tooltip>
      )}
    </p>
  </TableCell>
}