import React from 'react';
import Plot from 'react-plotly.js';
import { Business, BusinessExtended, defaultMaturityModel } from '../../model/ScaleTypes';
import { groupByReduce, groupByReduceFunction, toNumber } from '../../util/ScaleUtils';
import { createBubbleChartLayout } from './PortfolioBubbleChart';
import { UsesBusinessHistoryDataProps, UsesMaturityModelProps } from './ScaleGraphTypes';
import { addMonths, endOfMonth, FORMAT_DAY } from '../../util/DayUtils';
import dayjs from '../../configuration/configuredDayjs';


export interface AnimatedPortfolioBubbleChartProps extends UsesMaturityModelProps, UsesBusinessHistoryDataProps {
  height?: string;
  width?: string;
  /**
   * Returns the closest data point from the data series when clicked.
   */
  onClickDataPoint?: (series: any, dataPoint: BusinessExtended) => void;
}


const DESIRED_MAXIMUM_MARKER_SIZE = 60;


/**
 * Primary UI component for user interaction
 */
export const AnimatedPortfolioBubbleChart: React.FC<AnimatedPortfolioBubbleChartProps> = ({
  maturityModel = defaultMaturityModel,
  data,
  height,
  width,
  onClickDataPoint,
}) => {

  if (!data) data = [];

  // find max budget to scale bubbles
  let maxBudget = 0;
  data.forEach(data => toNumber(data.budget) > maxBudget ? maxBudget = toNumber(data.budget) : 0);

  // organize data
  const dataByBusiness: { [key: string]: BusinessExtended[] } = groupByReduce(data, "businessId");
  const businessIds = Object.keys(dataByBusiness);

  // sort data to ascending time order
  businessIds.forEach(id => dataByBusiness[id].sort((a, b) => new Date(a.periodDate).getTime() - new Date(b.periodDate).getTime()));

  const timeIncrementer = addMonths; // addWeeks; //
  const endOfPeriod = endOfMonth;// endOfWeek; //

  // find times for frames in animation
  const dataByPeriod: { [key: string]: BusinessExtended[] } = groupByReduceFunction(data, (history: BusinessExtended) => dayjs(endOfPeriod(new Date(history.periodDate))).format(FORMAT_DAY));
  const periods = Object.keys(dataByPeriod).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  const frameTimes: string[] = [];
  let date = endOfPeriod(new Date(periods[0]));
  while (date.getTime() <= endOfPeriod(new Date(periods[periods.length - 1])).getTime()) {
    frameTimes.push(dayjs(date).format(FORMAT_DAY));
    date = endOfPeriod(timeIncrementer(date, 1));
  }


  // Create a trace for each business
  var traces: Plotly.Data[] = [];
  businessIds.forEach(id => {
    const history = dataByBusiness[id][0];


    traces.push({
      ...toPlotlyFrameData(history, maxBudget),

      x: [0],
      y: [frameTimes[0]],
      marker: {
        //@ts-ignore
        size: [0],
        sizemin: 4,
        sizeref: 2.0 * maxBudget / (DESIRED_MAXIMUM_MARKER_SIZE ** 2),
        sizemode: 'area'
      },
      // @ts-ignore
      id: history.businessId,
      name: history.name,
      text: [history.name],
      // @ts-ignore
      mode: 'markers',
      hovertemplate: '<b>%{text}</b><br>' +
        '<br>Score: %{x:.1f}' +
        '<br>Business potential: %{y:,.2s} €' +
        '<br>Budget: %{marker.size:,.2s} €<br><br>' +
        '<extra></extra>',
    });

  });

  // build frames and slider steps for each frame
  var frames: Partial<Plotly.Frame>[] = [];
  var sliderSteps: any[] = [];


  frameTimes.forEach(period => {
    const periodData = dataByPeriod[period];
    const periodByBusiness = groupByReduce(periodData, "businessId");
    if (period === frameTimes[0]) {
      frames.push({
        name: period,
        data: businessIds.map(id => false ? toPlotlyFrameData(periodByBusiness[id][periodByBusiness[id].length - 1], maxBudget) : {
          x: [0],
          y: [frameTimes[0]],
          marker: {
            //@ts-ignore
            size: [0],
            sizemin: 4,
            sizeref: 2.0 * maxBudget / (DESIRED_MAXIMUM_MARKER_SIZE ** 2),
            sizemode: 'area'
          },

        })
      });
    } else {
      frames.push({
        name: period,
        data: businessIds.map(id => periodByBusiness[id] ? toPlotlyFrameData(periodByBusiness[id][periodByBusiness[id].length - 1], maxBudget) : {})
      });
    }
    sliderSteps.push({
      method: 'animate',
      label: period,
      args: [[period], {
        mode: 'immediate',
        transition: { duration: 100 },
        frame: { duration: 100, redraw: true }
      }]
    })
  });

  const layout = createBubbleChartLayout(maturityModel);

  // We'll use updatemenus (whose functionality includes menus as
  // well as buttons) to create a play button and a pause button.
  // The play button works by passing `null`, which indicates that
  // Plotly should animate all frames. The pause button works by
  // passing `[null]`, which indicates we'd like to interrupt any
  // currently running animations with a new list of frames. Here
  // The new list of frames is empty, so it halts the animation.
  //@ts-ignore
  layout['updatemenus'] = [{
    x: 0,
    y: 0,
    yanchor: 'bottom',
    xanchor: 'left',
    showactive: false,
    direction: 'left',
    type: 'buttons',
    //pad: { b: 87, r: 10 },
    buttons: [{
      method: 'animate',
      args: [null, {
        mode: 'immediate',
        fromcurrent: true,
        transition: { duration: 300 },
        frame: { duration: 300, redraw: true }
      }],
      label: 'Play'
    },
    {
      method: 'animate',
      args: [[null], {
        mode: 'immediate',
        transition: { duration: 300 },
        frame: { duration: 300, redraw: true }
      }],
      label: 'Pause'
    }]
  }];

  // Finally, add the slider and use `pad` to position it
  // nicely next to the buttons.
  //@ts-ignore
  layout['sliders'] = [{
    pad: { l: 0, t: 30 },
    currentvalue: {
      visible: true,
      prefix: 'Month:',
      xanchor: 'left',
      font: { size: 20, color: '#666' }
    },
    steps: sliderSteps
  }];

  var onClickWrapper = function (d: any) {
    if (d.points.length === 1) {
      var p = d.points[0];
      // The data received is organized per the series.
      console.log("clicked", p);
      if (onClickDataPoint) onClickDataPoint(p.data.name, dataByBusiness[p.data.id][0])
      return;
    }
    console.log("several points 'clicked':" + d.points);
  }
  var extras = {};
  if (onClickDataPoint) extras = { onClick: onClickWrapper, ...extras }

  //@ts-ignore
  return <Plot data={traces} frames={frames}
    style={{ width, height }}
    layout={layout}
    config={{ displayModeBar: false, responsive: false }}
    {...extras}
  />

};

function toPlotlyFrameData(history: BusinessExtended, maxBudget: number): Partial<Plotly.Data> {
  return {
    x: [history.score],
    y: [history.businessPotential!],
    text: [history.name],
    marker: {
      size: [toNumber(history.budget)],
      sizemin: 4,
      sizeref: 2.0 * maxBudget / (DESIRED_MAXIMUM_MARKER_SIZE ** 2),
      sizemode: 'area'
    }
  }

}

// function hello () {
//   // Extract the series names per the series property
//   const seriesNames : any[] = [];
//   data?.forEach(function (obj: any) {
//     if (seriesNames.indexOf(obj[seriesProperty]) !== -1) return;
//     seriesNames.push(obj[seriesProperty]);
//   }, []);

//   const dataPerSeries : any[] = [];
//   seriesNames.forEach(val => dataPerSeries[val] = data.filter(d => d[seriesProperty] === val));


//   // used for scaling the bubble sizes
//   const maxBudget = data?.reduce((max, obj) => Math.max(budgetToNumber(obj.budget), max), 0);

//   const pdata: Plotly.Data[] = seriesNames?.map(name => createSeriesData(name, dataPerSeries[name], maxBudget));

//   const config = {
//     displayModeBar: false,
//     editable: false,
//     // responsive: true
//   }
//   const layout = scaleBubbleChartLayout;

//   var onClickWrapper = function (d: any) {
//     if (d.points.length === 1) {
//       var p = d.points[0];
//       // The data received is organized per the series.
//       if (onClickDataPoint) onClickDataPoint(p.data.name, dataPerSeries[p.data.name][p.pointIndex]);
//       return;
//     }
//     console.log("several points 'clicked':" + d.points);
//   }
//   var extras = {};
//   if (onClickDataPoint) extras = { onClick: onClickWrapper, ...extras }




//  const years = [ new Date() ];



//   const frames: Plotly.Frame[] = [];
//   frames.push({
//     name: "a",
// //@ts-ignore
//     data: pdata.map(v => { x: v.x, y: v.y })
//   })



//   return <Plot
//     data={pdata} frames={frames}
//     style={{ width: '100%', height: '100%' }}
//     // @ts-ignore
//     layout={layout} config={config}
//     {...extras}

//   />
// };
