import React from 'react';
import { MaturityCategory, MaturityMilestone, MaturityModel, MaturityScoreOnly, Plan } from '../../model/ScaleTypes';
import { SCALE_STANDARD_FONT_SIZE } from '../../layout/theme';
import Plot from 'react-plotly.js'
import Plotly from 'plotly.js';

import { Alert } from '@mui/material';
import { groupByReduce } from '../../util/ScaleUtils';
import { useTranslate } from 'react-admin';
import { MATURITY_CATEGORY_COLORS, UIColors } from '../Colors';
import { Annotations, Layout, Shape } from 'plotly.js';
import { createDisplayNameFromPlanSelectionValue } from '../tables/MilestoneTable';
import dayjs from '../../configuration/configuredDayjs';
import { PLAN_STYLE_ONGOING, STANDARD_MARGIN } from './BusinessHistoryGraph';
import { PlotlyChartParams } from './PlotlyCharts';

export interface MaturityChartProps {
  width?: string,
  height?: string,
  /**
   * Maturity Model information
   */
  maturityModel: MaturityModel,
  /**
   * Type of the chart, default bar
   */
  type?: 'bar' | 'radar',
  /**
   * Show average
   */
  showAverage?: boolean,
  /**
   * Scores
   */
  scores: MaturityScoreOnly[],
  /**
   * Current Plans
   */
  plans?: Plan[],
}

export function addPlanToLayout(layout: Partial<Layout>, plan: Plan, maturityModel: MaturityModel, shapeStyle: Partial<Shape> = {}) {
  if (!plan.targetLevel || !plan.targetSelection) return;

  const selection = createDisplayNameFromPlanSelectionValue(plan.targetSelection, maturityModel)

  const hasXAxis = layout.xaxis && layout.xaxis.range;

  const planBox: Partial<Shape> = hasXAxis ?
    {
      ...shapeStyle,
      type: 'rect',
      y0: plan.startLevel || 0,
      y1: plan.targetLevel,
      x0: plan.startDate,
      x1: plan.targetDate,
    } : {
      ...shapeStyle,
      type: 'line',
      y0: plan.targetLevel,
      y1: plan.targetLevel,
      x0: 0,
      x1: 1,
      xref: 'paper',
    }

  const horizontalText: Partial<Annotations> = {
    y: plan.targetLevel,
    yanchor: 'bottom',
    x: hasXAxis ? plan.startDate : 0,
    xref: hasXAxis ? 'x' : 'paper',
    xanchor: 'left',
    text: `<b>Milestone</b>: ${plan.name} [${selection?.name}]`,
    font: {
      size: 7,
      color: 'black'
    },
    opacity: 1,
    showarrow: false
  };

  // Vertical text at the targetDate
  const targetVerticalText: Partial<Annotations> = {
    y: plan.startLevel || 0,
    yanchor: 'bottom',
    x: plan.targetDate,
    xanchor: 'left',
    text: `<b>Target Date</b>:<br /> ${plan.name} `,
    font: {
      size: SCALE_STANDARD_FONT_SIZE * 0.5
    },
    showarrow: false,
    textangle: "90"
  };

  layout.shapes!.push(planBox);
  layout.annotations!.push(horizontalText);
  // show the target date text only for the ongoing plan
  if (hasXAxis && dayjs().isBetween(plan.startDate, plan.targetDate)) layout.annotations!.push(targetVerticalText);
}


export function addMaturityMilestoneToLayout(layout: Partial<Layout>, milestone: MaturityMilestone, maturityModel: MaturityModel, opacity: number) {
  const milestoneText: Partial<Annotations> = {
    xref: 'paper',
    y: milestone.level,
    yanchor: 'middle',
    x: 1,
    xanchor: 'left',
    text: milestone.shortName,
    font: {
      size: 10,
      color: '#ffffff'
    },
    bgcolor: milestone.color ? milestone.color : '#ff7f0e',
    opacity: opacity,
    showarrow: false
  };
  const milestoneLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    y0: milestone.level,
    x0: 0,
    y1: milestone.level,
    x1: 1,
    line: {
      color: milestone.color ? milestone.color : 'rgb(255, 0, 0)',
      width: 1,
      dash: milestone.line ? milestone.line : 'dot'
    },
    opacity: opacity
  };
  layout.annotations!.push(milestoneText);
  layout.shapes!.push(milestoneLine)
}

/**
 * Returns data and layout needed for MaturityChart
 * @param param0 
 * @returns 
 * @throws Error business.assessment_missing if there is no assessment data available.
 */
export const createMaturityChartData = ({
  maturityModel,
  scores,
  plans,
  type = 'bar',
  showAverage = true,
}: MaturityChartProps): PlotlyChartParams => {
  const scoresByMaturityId = groupByReduce(scores, "maturityCategoryId");

  const categoriesWithScores: MaturityCategory[] = maturityModel.categories.filter(cat => scoresByMaturityId[cat.id]);
  if (categoriesWithScores.length === 0) {
    return {
      data: [],
      layout: {},
      error: 'business.assessment_missing'
    }
  }
  const categoryNames = categoriesWithScores.map(category => category.name);
  const currentValues: (number | undefined)[] = categoriesWithScores.map(cat => scoresByMaturityId[cat.id] ? scoresByMaturityId[cat.id][0]?.score : undefined);
  const previousValues: (number | undefined)[] = categoriesWithScores.map(cat => scoresByMaturityId[cat.id] ? scoresByMaturityId[cat.id][1]?.score : undefined);
  const colors: (string | undefined)[] = categoriesWithScores.map(cat => MATURITY_CATEGORY_COLORS[cat.order - 1]);

  const maturityBarData: Plotly.Data = {
    x: categoryNames,
    //@ts-ignore
    y: currentValues,
    type: 'bar',
    fill: 'toself',
    marker: {
      color: colors,
      line: {
        color: "black",
        width: 0,
      }
    },
    /*    text: categoryNames,
        textfont: {
          size: 6,
        },*/
    name: '',
    hovertemplate:
      '%{x}: %{y}'
  };

  // radar graph needs the first category twice
  const maturityRadarData: Plotly.Data = {
    // @ts-ignore
    theta: [...maturityBarData.x, maturityBarData.x[0]],
    // @ts-ignore
    r: [...maturityBarData.y, maturityBarData.y[0]],
    type: 'scatterpolar',
    fill: 'toself',
    marker: {
      color: UIColors.Interactions.DarkBlue
    },
    customdata: [...categoryNames, categoryNames[0]],
    text: categoryNames,
    textfont: {
      size: 9,
    },
    hovertemplate: '%{customdata} %{r}',
    showlegend: true,
    name: '',
    hoverlabel: {
      namelength: -1,
      align: 'auto'
    }
  };

  const sum = currentValues.reduce((s, v) => s! + Number(v), 0) || 0;
  const avgScore = sum / currentValues.length || 0;


  const bargap = 0.06
  let layout: Partial<Layout> = {
    showlegend: false,
    margin: STANDARD_MARGIN,
    xaxis: {
      showline: false,
      zeroline: false,
      tickangle: -45,
      tickfont: {
        size: SCALE_STANDARD_FONT_SIZE * 0.8
      },
    },
    yaxis: {
      range: [0, maturityModel.maxLevel],
      automargin: false,
      autotick: false,
      showgrid: false,
      showline: false,
      zeroline: false
    },
    annotations: [],
    shapes: []
  }

  // TODO: Clean layout and graph building 
  plans?.forEach(plan => {
    if (dayjs().isBetween(plan.startDate, plan.targetDate)) addPlanToLayout(layout, plan, maturityModel, PLAN_STYLE_ONGOING);
  });
  const milestoneOpacity = plans && plans.length > 0 ? 0.2 : 0.7;
  maturityModel.milestones?.forEach(milestone => addMaturityMilestoneToLayout(layout, milestone, maturityModel, milestoneOpacity))

  if (showAverage) {
    const averageText: Partial<Annotations> = {
      xref: 'paper',
      y: avgScore,
      yanchor: 'middle',
      x: 0.5,
      xanchor: 'auto',
      text: 'average',
      yshift: 10,
      font: {
        color: 'rgb(255, 0, 0)',
      },
      showarrow: false,
    };
    const averageLine: Partial<Shape> = {
      type: 'line',
      xref: 'paper',
      y0: avgScore,
      x0: 0,
      y1: avgScore,
      x1: 1,
      line: {
        color: 'rgb(255, 0, 0)',
        width: 1,
        dash: 'dash'
      },
    };
    layout.annotations!.push(averageText);
    layout.shapes!.push(averageLine);
  }

  // Add necessary datas to the plot.
  const datasToDisplay: Plotly.Data[] = [];
  if (type === 'bar') {
    layout = {
      ...barLayoutFragment,
      ...layout,
      bargap,
    };

    // Add background shapes for each bar
    categoriesWithScores.forEach((category, index) => {
      const rectShape: Partial<Shape> = {
        type: 'rect',
        xref: 'x',
        yref: 'paper',
        x0: -(0.5 - bargap / 2) + index,
        x1: (0.5 - bargap / 2) + index,
        y0: 0,
        y1: 1,
        fillcolor: UIColors.Interface.DimmedWhite, // Light gray with some transparency
        line: {
          width: 0
        },
        layer: 'below'
      };
      layout.shapes!.push(rectShape);
    });
    datasToDisplay.push(maturityBarData);
  } else {
    layout = {
      ...radarLayoutFragment(maturityModel.maxLevel),
      ...layout
    };
    datasToDisplay.push(maturityRadarData);
  }
  if (previousValues) {
    const pastScores: Plotly.Data = {
      x: categoryNames,
      //@ts-ignore
      y: previousValues,
      type: 'scatter',
      mode: 'markers',
      marker: {
        color: UIColors.Text.Brand,
      },
      name: 'Previous assessment',
      hovertemplate:
        '%{x}: %{y}'
    };
    datasToDisplay.push(pastScores);
  }
  return {
    data: type === 'bar' ? datasToDisplay : [maturityRadarData],
    layout
  };
}


/**
 * Primary UI component for user interaction
 * @deprecated use the PlotlyChart and pass the created parameters there instead
 */
export const MaturityChart: React.FC<MaturityChartProps> = ({
  width,
  height,
  maturityModel,
  scores,
  plans,
  type = 'bar',
  showAverage = true,
  ...props
}) => {
  const translate = useTranslate();
  if (!maturityModel) return <Alert severity='error'>{translate('errors.maturity_model_missing')}</Alert>

  try {
    const plotlyParams = createMaturityChartData({ maturityModel, scores, plans, type, showAverage })
    //@ts-ignore
    return <Plot data={plotlyParams.data}
      layout={plotlyParams.layout}
      style={{ width, height }}
      config={{ displayModeBar: false, responsive: false }} />
  } catch (error) {
    //@ts-ignore
    return <Alert severity='warning'>{translate(error.message)}</Alert>
  }
};


const barLayoutFragment = {
  xaxis: {
    tickangle: -45
  },
  yaxis: {
    range: [0, 20],
  },
}

const radarLayoutFragment = function (maxLevel: number) {
  return {
    polar: {
      radialaxis: {
        visible: true,
        range: [0, maxLevel]
      }
    }
  }
}