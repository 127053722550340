import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useState, useCallback } from 'react';
import CustomFileSelector from '../CustomFileSelector';
import { ScaleSvg } from '../Icons';
import { UIColors } from '../Colors';

const FILE_UPLOAD_LIMIT_MB = 20;
const FILE_UPLOAD_LIMIT_BYTES = FILE_UPLOAD_LIMIT_MB * 1024 * 1024;

const marginTop = '1em';

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const dialogTitleStyle = {
  ...flexCenter,
  flexDirection: 'row',
  fontWeight: 700,
  fontSize: '24px',
};

const uploadAreaStyle = {
  ...flexCenter,
  minWidth: '590px',
  minHeight: '250px',
  borderRadius: '12px',
  border: '2px dashed #e9e9e9',
  backgroundColor: UIColors.Interface.DimmedWhite,
  flexDirection: 'column',
  justifyContent: 'center',
};

const fileTypesTextStyle = {
  fontSize: '12px',
  color: '#828282',
  lineHeight: '16px',
  marginTop: '8px',
};

const RightAlignedCloseIcon = ({ onClick }: { onClick?: () => void }) => (
  <Typography sx={{ marginLeft: 'auto', '& :hover': { cursor: 'pointer' } }} onClick={onClick}>
    {ScaleSvg.Close}
  </Typography>
);

interface FileDialogProps {
  open: boolean;
  title?: string;
  onClose: () => void;
  onFilesSelected: (files: File[]) => Promise<void>;
  fileSection?: JSX.Element;
  accept?: string;
  acceptText?: string;
}

const FileDialog: React.FC<FileDialogProps> = ({
  title = "Select Files",
  open,
  onClose,
  onFilesSelected,
  fileSection,
  accept = 'image/*,.doc,.docx,.pdf,.pptx',
  acceptText = 'images, .doc, .docx, .pptx and .pdf'
}: FileDialogProps) => {
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [showTooLargeWarning, setShowTooLargeWarning] = useState<boolean>(false);

  const handleFileSelect = useCallback(
    async (file?: File) => {
      if (file) {
        setShowTooLargeWarning(false);
        if (file.size <= FILE_UPLOAD_LIMIT_BYTES) {
          setUploadInProgress(true);
          await onFilesSelected([file]);
          setUploadInProgress(false);
        } else {
          setShowTooLargeWarning(true);
        }
      }
    },
    [onFilesSelected],
  );

  const handleClose = () => {
    onClose();
    setShowTooLargeWarning(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" sx={{ padding: '1lh' }}>
      <DialogTitle sx={dialogTitleStyle}>{title}
        <RightAlignedCloseIcon onClick={handleClose} />
      </DialogTitle>

      <DialogContent>
        <Box sx={uploadAreaStyle}>
          {ScaleSvg.CloudUpload}

          <b style={{ margin: '1em' }}>
            {uploadInProgress ? 'Processing...' : 'Select files to upload'}
          </b>

          {uploadInProgress ? (
            <Box sx={{ ...flexCenter, margin: '1em', width: '80%' }}>
              <LinearProgress sx={{ width: '100%', opacity: 0.7 }} />
            </Box>
          ) : (
            <CustomFileSelector
              disabled={uploadInProgress}
              accept={accept}
              multiple={false}
              onFileSelect={handleFileSelect}
              text="Browse files"
              color="primary"
              variant="contained"
              sx={{ marginTop }}
            />
          )}
        </Box>

        <Typography sx={fileTypesTextStyle}>
          {`You can upload ${acceptText} files, maximum file size ${FILE_UPLOAD_LIMIT_MB}MB`}
        </Typography>

        {showTooLargeWarning && (
          <Typography sx={fileTypesTextStyle} style={{ color: 'red', fontWeight: 'bold' }}>
            {`File over maximum file size limit ${FILE_UPLOAD_LIMIT_MB}MB`}
          </Typography>
        )}
        {fileSection}
      </DialogContent>

      <DialogActions>
        <Button variant="QuartaryCTA" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileDialog;
