import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    ReferenceInput,
    SelectInput,
    TextInput,
    required,
    CreateProps,
    useRedirect,
    useTranslate,
    SimpleForm,
    SaveButton,
    Toolbar,
    email,
    DateInput,
    useNotify,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import { BusinessExtended } from '../../model/ScaleTypes';
import { Grid } from '@mui/material';

export interface QuickCreateProps extends CreateProps {
    quickCreate?: boolean,
    closeDialog?: Function
}

export const QuickCreate: FC<QuickCreateProps> = ({
    quickCreate = false,
    closeDialog = () => { },
}) => {
    const redirect = useRedirect();
    const notify = useNotify();

    const onSuccess = (data: BusinessExtended) => {
        notify('generic.created', { type: 'success', messageArgs: { itemType: `'${data.name}'` } })
        quickCreate ? closeDialog(data) : redirect('show', '/api_business', data.id, data);
    }
    return (
        <Create resource='scale_business' mutationOptions={{ onSuccess }}>
            <QuickCreateForm />
        </Create>
    );
};

export const QuickCreateForm: FC<QuickCreateProps> = ({ }) => {
    const translate = useTranslate();
    const FormToolBar = () => (
        <Toolbar>
            <SaveButton type="button" />
        </Toolbar>
    ); return <SimpleForm toolbar={<FormToolBar />}>
        <Typography variant='h6' gutterBottom>{translate('business.create')}</Typography>
        <Typography variant="body1">
            Please share the basic information for the project. There are extra information fields that you can add to your project anytime later in the Edit screen.
        </Typography>
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <TextInput autoFocus source="name" fullWidth validate={required()} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
                <ReferenceInput source="organizationUnitId" reference="scale_organizationUnit">
                    <SelectInput validate={required()} fullWidth optionText="name" />
                </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <TextInput source="leadPersonEmail" type="email" fullWidth validate={[email()]} />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <TextInput label="business.description" source="description" multiline fullWidth rows={4} />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
                <DateInput source="startDate" defaultValue={new Date()} validate={required()} fullWidth />
            </Grid>
        </Grid>

    </SimpleForm>
}

export default QuickCreate;