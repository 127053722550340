import { useGetOne } from 'react-admin';
import { ApiScoreByDay, Business, BusinessExtended, BusinessHistory, MaturityModel } from '../model/ScaleTypes';
import { defineCalculatedProperty } from '../util/ScaleUtils';
import dayjs from '../configuration/configuredDayjs';

export default function useBusiness(businessId: string): { business: BusinessExtended | null, isLoading: boolean } {
  const { data, isLoading } = useGetOne<BusinessExtended>('api_business', { id: businessId })
  return {
    business: data ?? null,
    isLoading,
  }
}


/**
 * Adds missing properties to Business, so that it has the same data as BusinessExtended
 * @param item BusinessHistory item, 
 * @param scores 
 * @returns 
 */
export function buildBusinessHistory(item: Business, score: ApiScoreByDay | null, maturityModel: MaturityModel): BusinessHistory {
  const extendedItem = item as BusinessHistory;
  extendedItem.organizationUnitName = extendedItem.organizationUnit.name;

  const averageScore = score;
  if (averageScore) {
    extendedItem.score = averageScore.score;
    extendedItem.averageScoreByDay = averageScore;
    defineCalculatedProperty(extendedItem, "weightedPotential", () => extendedItem.score / maturityModel.maxLevel * Number(extendedItem.businessPotential));
    defineCalculatedProperty(extendedItem, "scoreModifiedAt", () => averageScore.periodDate);
  }
  return extendedItem;
}


// this combines the data from the two arrays, creating a copy of the other latest
export function buildBusinessHistories(arrayA: Business[], arrayB: ApiScoreByDay[], maturityModel: MaturityModel): BusinessHistory[] {
  const combinedObjects: BusinessHistory[] = [];

  if (!arrayA || !arrayB) {
    console.warn("combineSnapshots: One or both input arrays are undefined."); // Or throw an error, or return an empty array, etc.
    if (!arrayA) return []; // Return an empty array if either input is undefined

  }

  const histories = arrayA.slice();
  const scores = arrayB.slice();

  let history: Business | undefined = undefined;
  let score: ApiScoreByDay | undefined = undefined;

  // Continue until all items are consumed from both
  history = histories.shift();
  score = scores.shift();
  while (history) {
    const histModified = history.modifiedAt ? dayjs(history.modifiedAt) : null;
    const scoreModified = score?.periodDate ? dayjs(score.periodDate) : null;

    if (!histModified) return combinedObjects;

    const current = scoreModified ? dayjs.max(histModified, scoreModified) : histModified;
    const constructed: Business = {
      ...history,
      modifiedAt: current.format("YYYY-MM-DD")
    }

    combinedObjects.push(buildBusinessHistory(constructed, score ? score : null, maturityModel));
    if (!scoreModified) return combinedObjects; // scores has ended

    if (histModified.isAfter(scoreModified)) {
      history = histories.shift();
    } else {
      score = scores.shift();
    }
  }
  return combinedObjects;
}