import { Button, Grid, Switch, Dialog, DialogActions } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import React, { useContext, useState, useEffect, useCallback } from 'react'; // Import useEffect, useMemo, useCallback
import { BusinessHistory, Tag } from '../model/ScaleTypes';
import { ProjectsInPhases } from '../components/ProjectsInPhases';
import Joyride, { Step } from 'react-joyride';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { flattenGroupBy, flattenGroupByKeepGroup, latest, latestBefore } from '../util/ScaleUtils';
import { FilterState, PortfolioBubbleCard } from '../components/cards/PortfolioBubbleCard';
import { Title, useRedirect, useTranslate } from 'react-admin';
import InfoIconTooltip from '../components/InfoIconTooltip';
import { SettingsContext } from '../state/SettingsContext';
import { useScalePermissions, withIdentityChecks } from '../components/withIdentityChecks';
import useMaturityModel from '../state/useMaturityModel';
import PropertySumStat from '../components/cards/PropertySumStat';
import { NewUserWelcome } from '../components/cards/NewUserWelcome';
import { ComparisonPeriodDropdown } from '../components/ComparisonPeriodDropdown';
import { createDashboardTutorialSteps } from '../configuration/tutorials';
import { FullScreenButton } from '../components/FullScreenButton';
import { BusinessListCard } from './BusinessListView';
import dayjs from '../configuration/configuredDayjs';
import { VideoIframe } from '../components/VideoIframe';
import { ScaleDatePicker } from '../components/common/ScaleDatePicker';
import VariantBox from '../components/VariantBox';
import { ScaleSvg } from '../components/Icons';
import { Predicate, TruePredicate } from '../components/common/MultiSelect';
import { formatDateRange } from '../util/DayUtils';
import { useBusinessHistoryDataById } from '../state/useBusinessHistoryScores';
import { ThemeFilterComponent } from '../components/ThemeFilter';



const TUTORIAL_VIDEO_URL = "https://www.youtube.com/embed/crv-INGEAQE";

const Dashboard = () => {
  document.title = "Scale SaaS - Dashboard"

  const redirect = useRedirect();
  const translate = useTranslate();

  const settings = useContext(SettingsContext);

  const { permissions, organization, isLoading: identityLoading } = useScalePermissions();
  const { maturityModel, loading: mmLoading } = useMaturityModel();

  const [period, setPeriod] = useState<number>(6);
  const [shownDate, setShownDate] = useState(dayjs().endOf('month'))
  const pastDate = shownDate.subtract(period, 'month').endOf('month');

  const [filterState, setFilterState] = useState<FilterState<BusinessHistory>>({ filter: TruePredicate });
  // ONBOARDING
  const [run, setOnboardingRun] = React.useState<boolean>(false);
  const [steps, setSteps] = React.useState<Step[]>([]);
  const [showTutorialVideo, setShowTutorialVideo] = useState(false);
  React.useEffect(() => {
    setSteps(createDashboardTutorialSteps(permissions.user, setOnboardingRun, redirect));
  }, [permissions.user, redirect]);
  const { businessHistoryDataById, isLoading: dataLoading } = useBusinessHistoryDataById();
  const countOfProjects = businessHistoryDataById ? Object.keys(businessHistoryDataById).length : 0

  // --- useCallbacks for memoized functions ---
  const onClickDataPoint = useCallback((series: any, dataPoint: BusinessHistory) => {
    if (dataPoint === null) return;
    var closestID = dataPoint.businessId;
    redirect("show", "api_business", closestID);
    return;
  }, [redirect]);  // Dependency: redirect

  // --- useEffect for data processing ---
  const [nowValues, setNowValues] = useState<BusinessHistory[] | null>(null);
  const [pastValues, setPastValues] = useState<BusinessHistory[]>([]);
  const [latestHistories, setLatestHistories] = useState<{ [key: string]: BusinessHistory | null }>({})

  useEffect(() => {
    if (!businessHistoryDataById) return;
    const foundLatest = flattenGroupByKeepGroup(businessHistoryDataById, group => latest(group));
    setLatestHistories(foundLatest);

    const newPastValues = flattenGroupBy(businessHistoryDataById, group => latestBefore(pastDate.toDate(), group, "modifiedAt", true));
    const newNowValues: (BusinessHistory | null)[] = flattenGroupBy(businessHistoryDataById, group => latestBefore(shownDate.toDate(), group, "modifiedAt", true));

    setPastValues(newPastValues.filter(business => business?.status === 'active').filter(filterState.filter) as BusinessHistory[]);
    setNowValues(newNowValues.filter(business => business?.status === 'active').filter(filterState.filter) as BusinessHistory[]);

  }, [shownDate, period, businessHistoryDataById, maturityModel, filterState]); // Dependencies

  if (mmLoading || identityLoading || dataLoading || nowValues === null) {
    return <LoadingAnimation loadingText='Loading portfolio data' />
  }
  if (!maturityModel) return <p>Failure Loading maturity model.</p>

  // Tags are used to filter based on 'CURRENT' businesses tags, not the past. Ie. show data for all those who match the filter TODAY 
  const onChangeFilter = (filter: Predicate<Tag>) => {
    setFilterState({
      filter: (historyItem) => {
        if (historyItem === null) return false;
        const currently = latestHistories[historyItem.businessId];
        if (!currently) return false;
        if (!currently.tags || currently.tags.length === 0) return filter(null); // if no tags, check if it passes the filter as null
        return currently.tags.map<Tag>(s => ({ id: s, name: s, organizationUnitId: s, })).some(filter);
      }
    }
    );
  }


  return (
    <div className="scaleDashboard">
      <Dialog
        open={showTutorialVideo}
        onClose={() => setShowTutorialVideo(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'lg'} // 'md' is approximately 80% of the viewport size
      >
        <VideoIframe src={TUTORIAL_VIDEO_URL} title="Tutorial Video" />

        <DialogActions>
          <Button onClick={() => setShowTutorialVideo(false)} color="primary" autoFocus>Close</Button>
        </DialogActions>

      </Dialog>
      <Title title="Dashboard" />
      <Joyride
        //callback={this.handleJoyrideCallback}
        continuous={true}
        //getHelpers={this.getHelpers}
        run={run}
        scrollToFirstStep={true}
        showSkipButton={true}
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            width: 'auto'
          },
        }}
      />
      <div className="dashboardContainer">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <VariantBox sx={{ justifyContent: 'space-between' }} variant='row-nowrap'>
              <VariantBox sx={{ alignSelf: 'flex-start' }} variant='row-nowrap'>
                <Typography variant='Label'>Data date:</Typography>
                <ScaleDatePicker onChange={(val) => val && setShownDate(val)} />
                <ComparisonPeriodDropdown period={period} setPeriod={setPeriod} />
              </VariantBox>
              <VariantBox sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                justifyContent: 'center',
              }} variant='row-nowrap'>
                <Typography variant='h1'>{organization?.name}, {formatDateRange(pastDate, shownDate)}</Typography>
              </VariantBox>
              <VariantBox sx={{ alignSelf: 'end' }} variant='row-nowrap'>
                {(!permissions.isLimitedAccess && nowValues.length < 5) &&
                  <Typography textAlign="right">Show demo data: <Switch
                    checked={settings.interfaceSettings.demoMode}
                    onChange={(event, checked) => settings.setInterfaceSetting('demoMode', checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /></Typography>
                }
                <VariantBox variant='row-nowrap'>
                  <ThemeFilterComponent onChangeFilter={onChangeFilter} />
                  {ScaleSvg.Divider}
                  <Button startIcon={ScaleSvg.Play} variant='QuartaryCTA' onClick={() => setShowTutorialVideo(true)}>Watch Tutorial</Button>
                </VariantBox>
              </VariantBox>
            </VariantBox>
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <PropertySumStat now={nowValues} past={pastValues} pastDate={pastDate} propertyName="businessPotential" />
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <PropertySumStat now={nowValues} past={pastValues} pastDate={pastDate} propertyName="weightedPotential" />
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <PropertySumStat now={nowValues} past={pastValues} pastDate={pastDate} propertyName="count" changeMode='absolute' default={1} formatter={(value) => value.toFixed(0)} />
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <PropertySumStat now={nowValues} past={pastValues} pastDate={pastDate} propertyName="score" changeMode='absolute' default={1} formatter={(value) => value.toFixed(1)} />
          </Grid>
          {(countOfProjects === 0) &&
            <Grid item sm={12}>
              <NewUserWelcome identity={permissions.user} handleDemoModeChange={(event) => settings.setInterfaceSetting('demoMode', event.target.checked)} showDemoData={settings.interfaceSettings.demoMode} />
            </Grid>
          }

          {!permissions.isLimitedAccess && <>
            <Grid item xs={12} sm={12} md={12}>
              <PortfolioBubbleCard
                businessData={nowValues}
                comparisonBusinessData={pastValues}
                maturityModel={maturityModel} onClick={onClickDataPoint} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} >
              <Card className="pipeline">
                <CardHeader title="Projects in innovation pipeline" action={<InfoIconTooltip title={translate('pos.definitions.pipelineProjects')} />} />
                <ProjectsInPhases maturityModel={maturityModel} maxItemsToList={0} data={nowValues} />
              </Card>
            </Grid>
          </>
          }
          <Grid item xs={12} sm={12} md={12}>
            <BusinessListCard title="Ongoing projects" data={nowValues} initialNumberOfRows={25} />
          </Grid>
          <Grid item xs={4} sm={4} md={4}><FullScreenButton /></Grid>
          <Grid item xs={4} sm={4} md={4} />
          <Grid item xs={4} sm={4} md={4} />
        </Grid>
      </div>
    </div >
  );
}

export default withIdentityChecks(Dashboard);