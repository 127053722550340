import { Chip, SxProps, Theme } from "@mui/material";
import { MaturityCategory } from "../model/ScaleTypes";
import { getTextColorForBackground, MATURITY_CATEGORY_COLORS, UIColors } from "./Colors";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { useState } from "react";

type CategoryChipProperties = {
  size?: 'mini' | 'standard';
  category: MaturityCategory;
  isSelectable?: boolean;
  isSelected?: boolean;
  isMinimized?: boolean;
  sx?: SxProps<Theme> | undefined;
  onClick?: (category: MaturityCategory) => void;
  onDelete?: (category: MaturityCategory) => void;
}

export const CategoryChip: React.FC<CategoryChipProperties> = ({
  size = 'standard',
  category,
  sx = {},
  isSelectable = false,
  isSelected = !isSelectable,
  onClick = () => { },
  onDelete = () => { }
}) => {
  const categoryBgColor = MATURITY_CATEGORY_COLORS[category.order - 1]
  const categoryTextColor = getTextColorForBackground(categoryBgColor)
  const colors = {
    default: {
      backgroundColor: isSelected ? categoryBgColor : 'default',
      color: isSelected ? categoryTextColor : 'default',
    },
    hover: {
      backgroundColor: categoryBgColor,
      color: categoryTextColor,
    }
  }

  if (size === 'mini') {
    return <FiberManualRecordIcon fontSize="medium" sx={{ margin: "-2px", color: colors.default, ...sx }} />
  }

  return (
    <Chip
      variant='tag'
      size='small'
      sx={{
        ...colors.default,
        ':hover': {
          ...colors.hover,
        },
        ...sx,
      }}
      key={category.id}
      label={category.name}
      onClick={isSelectable ? () => onClick(category) : undefined}
      onDelete={isSelected && isSelectable ? () => onDelete(category) : undefined}
    />
  )
}

export const CategoryChipList: React.FC<{ categories: MaturityCategory[], expanded?: boolean }> = ({ categories, expanded = false }) => {
  const [isHovered, setIsHovered] = useState(false);

  if (expanded) {
    return <div>
      {categories
        .sort((a, b) => a.order - b.order)
        .map((cat, index) => (
          <CategoryChip key={index} category={cat} />
        ))}
    </div>
  }

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
    >
      {categories
        .sort((a, b) => a.order - b.order)
        .map((cat, index) => (
          <CategoryChip key={index} category={cat} size='mini' sx={{ color: isHovered ? UIColors.Special.Invisible : MATURITY_CATEGORY_COLORS[cat.order - 1] }}
          />
        ))}
      {isHovered && (
        <div style={{
          position: 'absolute',
          top: '-0.3em', // Positioning to match the center of the cell
          width: '400px',
          zIndex: 1000, // High enough to be above other content*/
          transition: 'all 0.3s',
          pointerEvents: 'auto', // Prevent interference with other elements when not hovered
        }}>
          {categories
            .sort((a, b) => a.order - b.order)
            .map((cat, index) => (
              <CategoryChip key={index} category={cat} />
            ))}
        </div>
      )}
    </div>
  );
};

