import Plot from 'react-plotly.js'
import { Alert } from '@mui/material';
import { useTranslate } from 'react-admin';
import { makeUniqueId } from '@apollo/client/utilities';
import Plotly from 'plotly.js';
import { useState } from 'react';


export interface PlotlyChartParams {
  data: Plotly.Data[],
  layout: Partial<Plotly.Layout>,
  relayoutListener?: (graphId: string) => void,
  error?: string,
}

export interface PlotlyChartProps {
  width?: string,
  height?: string,
  params: PlotlyChartParams,
}

/**
 * Primary UI component for user interaction
 */
export const PlotlyChart: React.FC<PlotlyChartProps> = ({
  width,
  height,
  params,
  ...props
}) => {
  const [id] = useState<string>(makeUniqueId("graph_"));
  const translate = useTranslate();
  if (params.error) return <Alert severity='warning'>{translate(params.error)}</Alert>
  try {
    return <Plot divId={id} onInitialized={(figure, div) => { }}
      onRelayout={(event) => params.relayoutListener && params.relayoutListener(id)}
      data={params.data} layout={params.layout} style={{ width, height }} config={{ displayModeBar: false, responsive: false }} />
  } catch (error) {
    return <Alert severity='warning'>{translate("error.message")}</Alert>
  }
};
