
import React from 'react';
import Plot from 'react-plotly.js'
import { theme } from '../../layout/theme';
import { UIColors } from '../Colors';

export interface LevelUpBenchmarkChartProps {
  /**
  * Businesses to show
  */
  businesses?: {
    id: string,
    name: string
  }[],
}

/*
function getStandardDeviation (array : number[]) {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b) / n
  return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}
*/

/**
 * Primary UI component for user interaction
 */
export const LevelUpBenchmarkChart: React.FC<LevelUpBenchmarkChartProps> = ({
  businesses,
  ...props
}) => {

  var data: Plotly.Data[] = [
    {
      x: ["Exploration<br>Formation", "Problem<br>Validation", "Solution<br>Validation", "Business<br>Validation", "MVP<br>Implementation", "Productization &<br>Preparing to Sell", "Go To<br>Market", "Product Market<br>Fit", "Scaling<br>up", "Profitability &<br>Growing"],//[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      y: [51, 106, 151, 188 / 3, 188 / 3 * 2, 178, 287, 72, 0, 0],
      name: 'Benchmark data<br>(avg / high / low)',
      error_y: {
        type: 'data',
        symmetric: false,
        array: [117 - 51, 282 - 106, 426 - 151, 449 - 188, 90, 395 - 178, 625 - 287, 72 - 72],
        arrayminus: [51 - 19, 106 - 47, 151 - 29, 188 / 3 - 29, 40, 178 - 87, 287 - 79, 72 - 72],
        color: UIColors.Text.Brand
      },
      type: 'bar',
      marker: {
        color: UIColors.Interactions.DarkBlue
      },
      hovertemplate: '<i>Avg</i>: %{y:.0f} days' +
        '<br><i>Slowest</i>: +%{error_y.array:.0f} days' +
        '<br><i>Fastest</i>: -%{error_y.arrayminus:.0f} days',
      showlegend: true,
    }
  ];

  data.push(
    {
      x: ["Exploration<br>Formation", "Problem<br>Validation", "Solution<br>Validation", "Business<br>Validation", "MVP<br>Implementation", "Productization &<br>Preparing to Sell", "Go To<br>Market", "Product Market<br>Fit", "Scaling<br>up", "Profitability &<br>Growing"],//[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      y: [70, 110, 90, 150, 200, 300, 180],
      type: 'scatter',
      name: 'Your organisation<br>(demo)',
      marker: {
        color: UIColors.Interactions.BrightRed
      },
      line: {
        dash: 'dot'
      },
      showlegend: true
    }
  )

  data.push(
    {
      x: ["Exploration<br>Formation", "Problem<br>Validation", "Solution<br>Validation", "Business<br>Validation", "MVP<br>Implementation", "Productization &<br>Preparing to Sell", "Go To<br>Market", "Product Market<br>Fit", "Scaling<br>up", "Profitability &<br>Growing"],//[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      y: [30, 30, 30, 30, 180, 250, 90, 250, 380, 550],
      type: 'scatter',
      name: 'Target',
      marker: {
        color: UIColors.Interface.LightGreen
      },
      showlegend: true,
      visible: "legendonly"
    }
  )

  /*data.push(
    {
      x: ["Exploration<br>Formation", "Problem<br>Validation", "Solution<br>Validation", "Business<br>Validation", "MVP<br>Implementation","Productization &<br>Preparing to Sell","Go To<br>Market", "Product Market<br>Fit", "Scaling<br>up", "Profitability &<br>Growing"],//[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      y: [117, 282, 426, 449, 0, 395, 625, 72],
      type: 'scatter',
      marker: {
        color: SCALE_COLORS.Interface.LightRed
      }
    }
  )*/

  var layout = {
    font: {
      family: theme.typography.fontFamily,
      size: theme.typography.fontSize,
    },
    title: "Level completion times",
    showlegend: true,
    barmode: 'bar',
    // title: 'Colored Bar Chart',
    // bargap: 1,
    // automargin: true,
    yaxis: {
      title: {
        text: "Days",
      },
    },
    xaxis: {
      title: "Level"
    },
  };

  //@ts-ignore
  return <Plot data={data}
    //@ts-ignore
    layout={layout}
    config={{ displayModeBar: false, responsive: false }} style={{ width: '100%' }} />
}