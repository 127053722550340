import Card from '@mui/material/Card';
import { BusinessExtended, Tag } from '../model/ScaleTypes';
import { BusinessList } from '../components/BusinessList';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { useGetList, useNotify, useRedirect, useTranslate } from 'react-admin';
import { Alert, Box, Button, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { useScalePermissions, withIdentityChecks } from '../components/withIdentityChecks';
import React, { useEffect, useState } from 'react';
import useMaturityModel from '../state/useMaturityModel';
import { canCreateReport, handleDownloadReport } from './CustomReportPage';
import { useScaleDataProvider } from '../state/provider/ScaleDataProvider';
import { formatArrayForHasura, isNil, isNotNil } from '../util/ScaleUtils';
import { ScaleSvg } from '../components/Icons';
import VariantBox from '../components/VariantBox';
import { Predicate, TruePredicate } from '../components/common/MultiSelect';
import { FilterState } from '../components/cards/PortfolioBubbleCard';
import { EditDialog } from '../components/EditDialog';
import ProjectBasicInfo from '../components/project/ProjectBasicInfo';
import { ThemeFilterComponent } from '../components/ThemeFilter';

const BusinessListView = (props: any) => {
    document.title = "Scale SaaS - Business List"
    const { permissions, isLoading } = useScalePermissions();
    const scaleDataProvider = useScaleDataProvider()
    const [filterState, setFilterState] = useState<FilterState<BusinessExtended>>({ filter: TruePredicate });
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [projectInDialog, setProjectInDialog] = useState<BusinessExtended>();
    const notify = useNotify()
    const { data: allData, isLoading: loading, refetch } = useGetList<BusinessExtended>(
        'api_business', {
        pagination: { page: 1, perPage: 100 },
        filter: {
            "deleted@_neq": true,
            "businessId@_in": permissions.isLimitedAccess ? permissions.allowedBusinessIds : undefined
        }
    });
    if (loading || isLoading) {
        return <LoadingAnimation loadingText='Loading data' />
    }
    if (!allData) return <></>
    const dataInUse = allData.filter(filterState.filter);

    // Tags are used to filter based on 'CURRENT' businesses tags, not the past. Ie. show data for all those who match the filter TODAY 
    const onChangeFilter = (filter: Predicate<Tag>) => {
        setFilterState({
            filter: (currently) => {
                if (currently === null) return false;
                if (!currently) return false;
                if (!currently.tags || currently.tags.length === 0) return filter(null); // if no tags, check if it passes the filter as null
                return currently.tags.map<Tag>(s => ({ id: s, name: s, organizationUnitId: s, })).some(filter);
            }
        }
        );
    }

    // @TODO Duplication with Project Dashboard
    const saveProject = async (formData: Record<string, any>) => {
        if (isNil(projectInDialog)) return;
        if (formData.tags) formData.tags = formatArrayForHasura(formData.tags.sort())

        console.log('Saving project.id', projectInDialog.id, 'edited fields:', formData)

        try {
            if (isNil(projectInDialog.id)) {
                const createResult = await scaleDataProvider.create('scale_business', {
                    data: formData,
                })
                notify(`${createResult.data.name} created successfully`)
                refetch()
                return;
            }
            // first update
            await scaleDataProvider.update('scale_business', {
                id: projectInDialog.id,
                data: {
                    id: projectInDialog.id,
                    ...formData,
                },
                previousData: {
                    ...projectInDialog
                }
            })
            notify('Changes saved successfully')
            resetProject();
            refetch();
        } catch (error: any) {
            notify(`Error: ${error.message}`, { type: 'warning' })
        }
    }

    const resetProject = () => {
        setProjectInDialog({
            status: 'proposed'
        } as BusinessExtended);
    }

    const openBusiness = (item: BusinessExtended) => {
        setProjectInDialog(item);
        setShowDialog(true);
    }

    if (!projectInDialog) resetProject();
    if (!projectInDialog) return <></>

    return (
        <Grid container>
            <EditDialog
                open={showDialog}
                title="Concept details"
                editForm={<ProjectBasicInfo project={projectInDialog} editing={true} />}
                onClose={() => setShowDialog(false)}
                editRecord={projectInDialog}
                onEditSave={saveProject} />

            <Grid item xs={12} sm={12} md={12}>
                <VariantBox variant='row-nowrap' >
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography variant='h1'>Projects overview</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <ThemeFilterComponent onChangeFilter={onChangeFilter} />
                </VariantBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <BusinessListCard
                    title='Concepts'
                    columnNames={["name", "organizationUnitName", "tags", "description", "createdAt", "modifiedAt"]}
                    data={dataInUse?.filter(business => business.status === 'proposed')}
                    alternativeCreate={<Button
                        startIcon={ScaleSvg.Plus}
                        variant='QuartaryCTA'
                        onClick={() => setShowDialog(true)}>New concept</Button>}
                    onClickListItem={openBusiness}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <BusinessListCard title='Ongoing projects' data={dataInUse?.filter(business => business.status === 'active')} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <BusinessListCard
                    title='On hold'
                    initialCollapse={true}
                    data={dataInUse?.filter(business => business.status === 'on hold')}
                    alternativeCreate={<></>} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <BusinessListCard title='Cancelled' initialCollapse={true} data={dataInUse?.filter(business => business.status === 'cancelled')}
                    alternativeCreate={<></>} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <BusinessListCard title='Archived' initialCollapse={true} data={dataInUse?.filter(business => business.status === 'archived')}
                    alternativeCreate={<></>} />
            </Grid>
        </Grid>
    );
}

export interface BusinessListCardProps {
    data: BusinessExtended[];
    columnNames?: (keyof BusinessExtended)[],
    initialNumberOfRows?: number;
    title?: string;
    allowFold?: boolean;
    initialCollapse?: boolean;
    alternativeCreate?: JSX.Element;
    onClickListItem?: (item: BusinessExtended) => void,
}



export const BusinessListCard: React.FC<BusinessListCardProps> = ({
    data,
    initialNumberOfRows = 25,
    title,
    columnNames = ['name', 'organizationUnitName', 'tags', 'businessPotential', 'budget', 'score', 'startDate', 'scoreModifiedAt'],
    allowFold = true,
    initialCollapse = false,
    alternativeCreate,
    onClickListItem,

}) => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const [collapsed, setCollapsed] = useState<boolean>(initialCollapse)
    const dataProvider = useScaleDataProvider();
    const { maturityModel, loading: mmLoading } = useMaturityModel();
    const { permissions, isLoading } = useScalePermissions();
    const [selectedBusinesses, setSelectedBusinesses] = useState<string[]>([]); // State to track selected businesses
    const [isReportPossible, setIsReportPossible] = useState<boolean | undefined>(undefined);

    const shownTitle = title ? title : translate('business.list.title')

    // This checks if the report creation is possible for the business
    useEffect(() => {
        if (isReportPossible === undefined && isNotNil(data) && isNotNil(data[0])) {
            canCreateReport(data[0]).then(setIsReportPossible)
        }
    }, [data, isReportPossible]);

    const defaultOnClickListItem = (item: BusinessExtended) => redirect("show", "api_business", item.businessId);

    if (mmLoading || isLoading || !data) return <LoadingAnimation loadingText='Loading data' />
    if (!maturityModel) return <Alert severity="error">Maturity model is not available, please contact the support via the bottom right corner.</Alert>

    if (collapsed) return <Card>
        <CardContent>
            <VariantBox sx={{ cursor: 'pointer' }} onClick={() => setCollapsed(false)} variant='row-nowrap'>
                {ScaleSvg.Expand}
                <Typography variant='h1'>{shownTitle}</Typography>
                <Typography variant='Title/Small'>({data.length})</Typography>
            </VariantBox>
        </CardContent>
    </Card>

    return <Card>
        <CardHeader title={
            <VariantBox variant='row-nowrap' sx={{ cursor: 'pointer' }} onClick={() => setCollapsed(true)}>{ScaleSvg.Collapse}
                <Typography variant='h1'>{shownTitle}</Typography>
                <Typography variant='Title/Small'>({data.length})</Typography>
            </VariantBox>} action={
                <Box sx={{ display: 'flex', flexDirection: 'row', whiteSpace: "nowrap", marginRight: "10px", gap: 1 }}>
                    <Button
                        variant='QuartaryCTA'
                        disabled={selectedBusinesses.length === 0 || !isReportPossible}
                        startIcon={ScaleSvg.Report}
                        onClick={() => {
                            const businesses: BusinessExtended[] = [];
                            selectedBusinesses.forEach(businessId => {
                                const business = data.find(business => business.id === businessId);
                                if (business) businesses.push(business);
                            });
                            if (businesses.length === 0) return;
                            handleDownloadReport(dataProvider, businesses, maturityModel);
                        }}>
                        {translate('business.createReport' + (isReportPossible ? '' : '_template_missing'))}
                    </Button>
                    {!permissions.isLimitedAccess && (alternativeCreate ? alternativeCreate :
                        <Button
                            startIcon={ScaleSvg.Plus}
                            variant='QuartaryCTA'
                            onClick={() => redirect("/scale_business/create")} >{translate('business.create')}</Button>)}
                </Box>
            } />
        <CardContent>
            <BusinessList maturityModel={maturityModel}
                initialNumberOfRows={initialNumberOfRows}
                businessList={data}
                columnNames={columnNames}
                onCheckboxChange={(id, checked) => {
                    if (checked) {
                        setSelectedBusinesses([...selectedBusinesses, id]);
                    } else {
                        setSelectedBusinesses(selectedBusinesses.filter(businessId => businessId !== id));
                    }
                }}
                onClickListItem={onClickListItem || defaultOnClickListItem}
            />
        </CardContent>
    </Card>
}

export default withIdentityChecks(BusinessListView);
