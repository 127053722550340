import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { isNotNil } from '../../util/ScaleUtils';
import SortableTable, { Column } from '../common/SortableTable';
import { ProjectFile } from '../../model/ScaleTypes';
import { ScaleSvg } from '../Icons';
import { UIColors } from '../Colors';
import { useScaleDataProvider } from '../../state/provider/ScaleDataProvider';

const marginTop = '1em';
const marginBottom = '1em';

const fontBold = {
  fontFamily: 'Roboto Slab',
  fontWeight: 700,
};

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const verticalMargins = {
  marginTop,
  marginBottom,
};

const fileWrapperStyle = {
  ...flexCenter,
  border: '1px solid #c9c9c9',
  borderRadius: '4px',
  marginTop: '8px',
  padding: '8px',
  ':hover': {
    backgroundColor: UIColors.Interface.DetailGrey,
  }
}

const savedFileNameStyle = {
  fontFamily: 'Roboto Slab',
  fontWeight: 700,
  color: UIColors.Text.Primary,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '20px',
  marginLeft: '8px',
}

const fileIconColor = '#eb5764'

const fileTypesTextStyle = {
  fontSize: '12px',
  color: '#828282',
  lineHeight: '16px',
  marginTop: '8px',
}

const UploadedFileRow = (file: ProjectFile) => {
  const scaleDataProvider = useScaleDataProvider()
  //const isImage = file.link?.type?.includes('image')

  return (
    <a
      target='_blank'
      href={file.link.url}
      style={{ textDecoration: 'none' }}
      onClick={async (e) => {
        e.preventDefault()
        const newWindow = window.open('', '_blank')
        if (isNotNil(newWindow)) {
          const fileLink = await scaleDataProvider!.getFileDownloadLink(file)
          newWindow.location = fileLink.url
        } else {
          // TODO: pop-up blocked
        }
      }}
    >
      <Box sx={fileWrapperStyle}>
        <DescriptionIcon sx={{ color: fileIconColor, }} />
        <Typography sx={savedFileNameStyle}>{file.name}</Typography>
      </Box>
    </a>
  )
}

const uploadedFilesColumns: Column<ProjectFile>[] = [
  {
    id: 'name',
    header: '',
    disableSorting: true,
    format: (_: any, f: ProjectFile) => <UploadedFileRow {...f} />,
    style: {
      border: 0,
      padding: 0,
    }
  }
]

export const FileListing = ({ files, loading }: { files: ProjectFile[], loading: boolean }) => (
  <>
    <Box sx={{ ...flexCenter, ...verticalMargins, }}>
      <b>Uploaded files</b>
      {loading && <CircularProgress size={18} sx={{ marginLeft: '1em', }} />}
    </Box>

    {files.length > 0
      ? <SortableTable
        defaultSortColumn={undefined}
        columns={uploadedFilesColumns}
        data={files}
        initialRowsPerPage={5}
        labelRowsPerPage=''
        disablePageSizeChange={true}
        disableHeader={true}
        sx={{
          '.MuiTableCell-footer': {
            border: 0,
          },
          '.MuiTablePagination-toolbar': {
            minHeight: 'unset',

            '.MuiTablePagination-displayedRows': {
              marginTop: 0,
              marginBottom: 0,
            },
          },
        }}
      />
      : loading
        ? null
        : <Box sx={{ ...verticalMargins, ...fileTypesTextStyle }}>No files have been added</Box>
    }
  </>
)
