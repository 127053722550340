import { DateInput, SelectInput, SimpleForm, TextInput, required, useTranslate } from "react-admin";
import useMaturityModel from "../../state/useMaturityModel";
import { LoadingAnimation } from "../../components/LoadingAnimation";
import { Alert, Grid, Typography } from "@mui/material";
import { Plan } from "../../model/ScaleTypes";
import { extractNumber } from "../../util/ScaleUtils";

export const REMOVE_BUSINESS_CASE_ATTRIBUTE = 'removeBusinessCase';

export function transformPlan(data: Plan): Plan {
  data.startLevel = extractNumber(data.startSelection)
  data.targetLevel = extractNumber(data.targetSelection)
  return data;
}

export const PlanForm = ({ ...props }) => {
  return <SimpleForm {...props}>
    <PlanFormContent />
  </SimpleForm>
};

export const PlanFormContent = (): JSX.Element => {
  const translate = useTranslate();
  const { maturityModel, loading } = useMaturityModel();

  if (loading) return <LoadingAnimation loadingText={translate('generic.loading', { content: 'Maturity Model' })} />
  if (!maturityModel) return <Alert severity="error">{translate('errors.maturity_model_missing')}</Alert>

  const choices: any[] = [];
  maturityModel.milestones!.forEach(milestone => {
    choices.push(
      {
        id: milestone.level + "-milestone",
        key: milestone.level + "-milestone",
        level: milestone.level,
        name: "★ Milestone: " + milestone.name,
      });
  });

  maturityModel.levels.forEach(level => {
    choices.push({
      key: level.level + "-level",
      id: level.level + "-level",
      level: level.level,
      name: `${level.level.toString().padStart(2, '0')} - ${level.name}`,
    })
  })

  choices.sort((a, b) => a.level - b.level);
  return <Grid container width="100%">
    <Grid item xs={12}>
      <TextInput fullWidth source="name" validate={required()} />
    </Grid>
    <Grid item xs={12}>
      <TextInput fullWidth rows={4} source="description" />
    </Grid>

    <Grid item xs={6}>
      <DateInput source="startDate" fullWidth validate={required()} />
    </Grid>

    <Grid item xs={6}>
      <DateInput source="targetDate" fullWidth validate={required()} />
    </Grid>

    <Grid item xs={6}>
      <SelectInput fullWidth source="startSelection" choices={choices} label="milestone.startSelection">
        <SelectInput optionText="name" />
      </SelectInput>
      <Typography variant="caption" >{translate('milestone.startSelectionTip')}</Typography>
    </Grid>

    <Grid item xs={6}>
      <SelectInput fullWidth source="targetSelection" choices={choices} label="milestone.targetSelection" validate={required()}>
        <SelectInput optionText="name" />
      </SelectInput>
      <Typography variant="caption" >{translate('milestone.targetSelectionTip')}</Typography>
    </Grid>
  </Grid>
}
