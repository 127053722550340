import React, { useState, useEffect } from 'react';
import { InputLabel, MenuItem, Select, SelectChangeEvent, OutlinedInput, Checkbox } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { ScaleSvg } from '../Icons'; // Assuming this is your custom SVG component.  Make sure the path is correct.
import VariantBox from '../VariantBox';

export type Predicate<T> = (item: T | null, index?: number, array?: (T | null)[]) => boolean;

export const TruePredicate: Predicate<any> = (item, index, array): boolean => { return true };

interface MultiSelectProps<T> {
  label: string;
  items: T[];
  width?: number;
  onChangeFilter?: (filter: Predicate<T>) => void;
  renderItem: (item: T) => { display: React.ReactNode | string; id: string };
  allSelectedLabel?: string; // Optional, defaults to "All"
}

export const MultiSelect = <T,>({
  label,
  items,
  renderItem,
  width = 256,
  onChangeFilter = () => { },
  allSelectedLabel = "All"
}: MultiSelectProps<T>) => {
  const selectAllValue = '_all'
  const deselectAllValue = '_none';

  const [allSelected, setAllSelected] = useState<boolean>(true); // initialize as all selected
  const [internalSelected, setInternalSelected] = useState<string[]>([]); // Initialize as empty array

  // keep all selected if that is the state
  useEffect(() => {
    if (allSelected) {
      setInternalSelected(items.map(item => renderItem(item).id));
    }
  }, [items])

  // everytime when selection changes, propagate that to the listeners
  useEffect(() => {
    onChangeFilter((item: T | null) => {
      if (allSelected) return true;
      if (item === null) return false;
      return (internalSelected.includes(renderItem(item).id));
    })
  }, [internalSelected])

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;

    let eventSelection: string[];
    if (typeof value === 'string') {
      eventSelection = value.split(',');
    } else {
      eventSelection = value;
    }

    if (eventSelection.includes(deselectAllValue)) {
      setInternalSelected([]);
      setAllSelected(false);
    } else if (eventSelection.includes(selectAllValue)) {
      setAllSelected(true);
      setInternalSelected(items.map(item => renderItem(item).id));
    } else {
      setInternalSelected(eventSelection)
      setAllSelected(false);
    }
  };

  const renderDropDownValue = (selectedIds: string[]) => {
    if (allSelected) { return `${allSelectedLabel} (${items.length})`; }
    const currentlySelected = items.filter(item => selectedIds.includes(renderItem(item).id))
    if (currentlySelected.length === 0) { return ""; }
    if (currentlySelected.length <= 2) {
      const displayedItems = currentlySelected;
      return <VariantBox variant='row-nowrap'>
        {displayedItems.map((displayItem, index) => (
          <React.Fragment key={index}>
            {renderItem(displayItem).display}
            {index < displayedItems.length - 1 && ', '}
          </React.Fragment>
        ))}
      </VariantBox>

    }
    return `Selected (${currentlySelected.length})`;
  };

  return (
    <FormControl sx={{ m: 1, width }} variant='outlined'>
      <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
      <Select
        multiple
        value={internalSelected.includes(selectAllValue) ? [selectAllValue] : internalSelected}
        label={label}
        onChange={handleChange}
        input={<OutlinedInput id={`${label}-select-label`} label={label} />}
        renderValue={renderDropDownValue}
      >
        <MenuItem value="" disabled>Select {label}</MenuItem>
        <MenuItem key={selectAllValue} value={selectAllValue}>
          Select {allSelectedLabel} ({items.length})
        </MenuItem>
        <MenuItem key={deselectAllValue} value={deselectAllValue}>
          Deselect All
        </MenuItem>
        {items.map((item) => {
          const { display, id: value } = renderItem(item);
          return (
            <MenuItem key={value} value={value}>
              <Checkbox
                sx={{ marginRight: 1, padding: 0, height: "20px", width: "20px" }}
                icon={ScaleSvg.Tick}
                checkedIcon={ScaleSvg.Tick}
                checked={internalSelected.includes(value)}
              />
              {display}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};