import { Chip } from "@mui/material";
import { MaturityLevel, MaturityMilestone } from "../../model/ScaleTypes";
import useMaturityModel from "../../state/useMaturityModel";
import { maturityScoreColorScale } from '../Colors';

export interface MilestoneChipProps {
  item: MaturityLevel | MaturityMilestone | null
}

export const MilestoneChip: React.FC<MilestoneChipProps> = ({
  item
}) => {
  const { maturityModel } = useMaturityModel();
  if (!maturityModel) return <></>
  if (!item) return <></>

  let label = 'unknown'
  if ((item as MaturityMilestone).shortName?.length > 0) {
    label = `★ ${item.name}`
  } else {
    label = `${item.level.toString().padStart(2, '0')}- ${item.name}`
  }
  return <Chip variant='tag' sx={{ bgcolor: maturityScoreColorScale(item.level / maturityModel.maxLevel) }} label={label} />
}