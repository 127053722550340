import {
  EnhancedGenerateContentResponse,
  GenerativeModel,
  GoogleGenerativeAI,
  HarmBlockThreshold,
  HarmCategory,
  ModelParams,
  Part,
  SafetySetting,
  UsageMetadata,
} from '@google/generative-ai';

export const genAI = new GoogleGenerativeAI("AIzaSyC-YIxsUm-2TGTEFEDeV59gJydPCpR89XY");

const gemini15flash8b = 'gemini-1.5-flash-8b';
const gemini15flash = 'gemini-2.0-flash';
const gemini15pro = 'gemini-1.5-pro';
export type GeminiModel = typeof gemini15flash8b | typeof gemini15flash | typeof gemini15pro;

function parseModelNameOrThrow(model: Pick<ModelParams, 'model'>): GeminiModel {
  switch (model.model) {
    case 'gemini-1.5-pro':
    case 'gemini-2.0-flash':
    case 'gemini-1.5-flash-8b':
      return model.model;
  }
  throw new Error('Unrecognised model ' + model.model);
}

type TokenReport = {
  inputTokens: number;
  inputPriceDollars: number;
  outputTokens: number;
  outputPriceDollars: number;
  totalPriceDollars: number;
};

/**
 * See https://ai.google.dev/pricing for details and up-to-date pricing information
 */
const tokenDollarPrice: {
  // eslint-disable-next-line no-unused-vars
  [K in GeminiModel]: { inputPrice: number; outputPrice: number };
} = {
  'gemini-2.0-flash': {
    // up to 128k tokens
    inputPrice: 0.1 / 1000000,
    outputPrice: 0.4 / 1000000,
  },
  'gemini-1.5-flash-8b': {
    // up to 128k tokens
    inputPrice: 0.0375 / 1000000,
    outputPrice: 0.15 / 1000000,
  },
  'gemini-1.5-pro': {
    inputPrice: 1.25 / 1000000,
    outputPrice: 5.0 / 1000000,
  },
};

function naiveTokenPrice(model: GeminiModel, usageMetadata: UsageMetadata): TokenReport {
  const inputPrice = tokenDollarPrice[model].inputPrice * usageMetadata.promptTokenCount;
  const outputPrice = tokenDollarPrice[model].outputPrice * usageMetadata.candidatesTokenCount;
  return {
    inputTokens: usageMetadata.promptTokenCount,
    inputPriceDollars: inputPrice,
    outputTokens: usageMetadata.candidatesTokenCount,
    outputPriceDollars: outputPrice,
    totalPriceDollars: inputPrice + outputPrice,
  };
}

function reportTokenConsumption(
  usageMetadata: UsageMetadata | undefined,
  model: GeminiModel,
): TokenReport {
  if (!usageMetadata) {
    throw new Error('Could not find usage metadata');
  }
  return naiveTokenPrice(model, usageMetadata);
}

export type LlmResponse = {
  tokenReport: TokenReport;
};

async function generateContent(
  model: GenerativeModel,
  generativeParts: Part[],
): Promise<{
  usageMetadata?: UsageMetadata;
  responseText: string;
  functionCalls: ReturnType<EnhancedGenerateContentResponse['functionCalls']>;
}> {
  const {
    response: { usageMetadata, text, functionCalls },
  } = await model.generateContent(generativeParts);

  // If you expect JSON and need to parse it:
  let responseText = text();
  try {
    // Attempt to parse if the content type indicates JSON
    if (model.generationConfig.responseMimeType === 'application/json') {
      JSON.parse(text()); // This will throw if it's not valid JSON
    }
  } catch (e) {
    console.error("Error parsing JSON:", e, text());
    // Handle the error appropriately, perhaps by returning an error.
  }
  return { usageMetadata, responseText, functionCalls: functionCalls() };
}

const safetySettings: SafetySetting[] = [
  {
    category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
    threshold: HarmBlockThreshold.BLOCK_NONE,
  },
  {
    category: HarmCategory.HARM_CATEGORY_HARASSMENT,
    threshold: HarmBlockThreshold.BLOCK_NONE,
  },
  {
    category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
    threshold: HarmBlockThreshold.BLOCK_NONE,
  },
  {
    category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
    threshold: HarmBlockThreshold.BLOCK_NONE,
  },
];

export type InvokeLlmParams = {
  modelParams: ModelParams;
  generativeParts: Part[];
};

export async function invokeLlm({ modelParams, generativeParts }: InvokeLlmParams): Promise<
  LlmResponse & {
    responseText: string;
    functionCalls: ReturnType<EnhancedGenerateContentResponse['functionCalls']>;
  }
> {
  const model = genAI.getGenerativeModel({
    safetySettings,
    ...modelParams,
  }, {});
  const { usageMetadata, responseText, functionCalls } = await generateContent(
    model,
    generativeParts,
  );

  console.log(
    '===============================================================================================',
  );
  console.log('Generative model params:', modelParams);
  console.log('Generative parts:', generativeParts);

  if (modelParams.generationConfig?.responseMimeType !== 'application/json') {
    console.log('ResponseText:', responseText);
  }

  if (functionCalls && functionCalls.length > 0) {
    console.log('FunctionCalls:', functionCalls);
  }

  return {
    tokenReport: reportTokenConsumption(usageMetadata, parseModelNameOrThrow(modelParams)),
    responseText,
    functionCalls,
  };
}
