import { getNestedProperty, toNumber } from "../../util/ScaleUtils";
import { Card, CardContent, CardHeader, Chip, Typography } from "@mui/material";
import InfoIconTooltip from "../InfoIconTooltip";
import { useTranslate } from "react-admin";
import { ChangeIndicator } from "../common/ChangeIndicator";
import { defaultCurrencyFormatter } from "../../util/FormattingUtils";
import VariantBox from "../VariantBox";
import { ScaleTypography } from "../Styles";
import { BlueScale, GreenScale, UIColors } from "../Colors";
import dayjs from "../../configuration/configuredDayjs";

interface PropertySumStatProps<T> {
  now: T[],
  past?: T[];
  pastDate?: dayjs.Dayjs;
  propertyName: string;
  default?: number;
  changeMode?: 'relative' | 'absolute';
  formatter?: (value: number) => string;
}

const PropertySumStat = <T,>(props: PropertySumStatProps<T>) => {
  const { propertyName, formatter = defaultCurrencyFormatter, default: defaultValue = 0, now, past, pastDate, changeMode } = props;
  //@ts-ignore
  const property: keyof T = propertyName;
  const translate = useTranslate();

  const nowSum = now.reduce((sum: number, item: T) => sum + (item ? toNumber(getNestedProperty(item, property) || defaultValue, 0) : 0), 0);
  const pastSum = past ? past.reduce((sum: number, item: T) => sum + (item ? toNumber(getNestedProperty(item, property) || defaultValue, 0) : 0), 0) : 0;
  const change = (nowSum - pastSum);

  const percentageChange = pastSum !== 0 ? change / pastSum : 0;

  const definitionText = translate(`pos.definitions.${String(propertyName)}`);

  let color = UIColors.Interface.DetailGrey;
  if (percentageChange < 0) {
    color = BlueScale(-percentageChange).hex()
  } else if (percentageChange > 0) {
    color = GreenScale(percentageChange).hex()
  }

  return (
    <Card>
      <CardHeader title={translate(`pos.sum.${propertyName}`)}
        action={(definitionText !== "") && <InfoIconTooltip title={definitionText} />}
      />
      <CardContent id="KPIs" style={{ textAlign: 'center', alignItems: 'center' }}>
        <VariantBox variant="row-nowrap" sx={{ gap: 2 }}>
          <Chip variant="tag" sx={{ backgroundColor: color, ...ScaleTypography.Title.Big, lineHeight: '25px', height: '36px' }} label={formatter(nowSum)} />
          <VariantBox variant='column'>
            <Typography variant='Label'>Change since {pastDate?.format("D/M/YYYY")} </Typography>

            <VariantBox variant='row-nowrap'>
              {props.past && <ChangeIndicator change={changeMode === 'absolute' ? change : percentageChange} changeMode={changeMode} />}
            </VariantBox>
          </VariantBox>
        </VariantBox>
      </CardContent>
    </Card>
  );
};

export default PropertySumStat;
