import { makeStyles } from 'tss-react/mui';
import { Widget } from '@typeform/embed-react';

const useStyles = makeStyles()({
    iframe: {
        border: 'none',
        width: 'auto',
        height: '100%'
    },
});

const InviteColleagueView = () => {
    useStyles();
    return (
        <Widget id="nnstAtte" style={{ width: '100%', height: '100%' }} className="invite-colleague" />
    );
};



export default InviteColleagueView;