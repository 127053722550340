import { Box, Button, Grid, Typography } from "@mui/material"
import { useRedirect } from "react-admin"
import { Business } from "../model/ScaleTypes"
import { Row, SPAN_MULTIPLIER } from "../pages/ProjectDashboard"
import { UIColors } from "./Colors"
import VariantBox from "./VariantBox"

const SELECTED = {
  color: UIColors.Interface.PureWhite,
  backgroundColor: UIColors.Interactions.DarkBlue,
  '&:hover': {
    color: UIColors.Interface.PureWhite,
    backgroundColor: UIColors.Interactions.DarkBlue,
  }
}

const NOT_SELECTED = {
  color: UIColors.Interactions.DarkBlue,
  backgroundColor: UIColors.Interface.PureWhite,
  '&:hover': {
    color: UIColors.Interactions.DarkBlue,
    backgroundColor: UIColors.Interface.PureWhite,
  }
}

export const DashboardTopRow: React.FC<{ project: Business, customItems?: JSX.Element | null, current: 'dashboard' | 'maturity' }> = ({
  project,
  current,
  customItems }) => {
  const redirect = useRedirect();

  const redirectToMaturity = () => { redirect(`/businessMaturity/${project.id}`); }
  const redirectToDashboard = () => { redirect(`/api_business/${project.id}/show`); }

  return <Row>
    <Grid item xs={1 * SPAN_MULTIPLIER} alignContent="center">
      <VariantBox variant='row-joined'>
        <Button
          onClick={redirectToDashboard}
          variant='ViewSwitch'
          sx={{
            borderRadius: "14px 0px 0px 14px",
            ...(current === 'dashboard' ? SELECTED : NOT_SELECTED),
          }}
        >Dashboard</Button>
        { /*
        <ScaleButton label="Timeline"
          onClick={redirectToDashboard}
          variant="contained"
          disabled={false}
          sx={{
            ...NOT_SELECTED,
            borderRadius: 0,
          }}
        />
        */}
        <Button
          value={current}
          onClick={redirectToMaturity}
          disabled={!project || !project.id}
          variant='ViewSwitch'
          sx={{
            borderRadius: "0px 14px 14px 0px",
            ...(current === 'maturity' ? SELECTED : NOT_SELECTED),
          }}
        >Assessment</Button>
      </VariantBox>
    </Grid>
    <Grid item xs={2 * SPAN_MULTIPLIER} display="flex" justifyContent="center">
      <Typography variant='h1'>{project.name ?? 'New Project'}</Typography>
    </Grid>
    <Grid item xs={1 * SPAN_MULTIPLIER} alignContent="center">
      <Box display="flex" justifyContent='flex-end' sx={{ gap: '8px' }}>
        {customItems}
      </Box>
    </Grid>
  </Row>
}