import { Typography } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";

const loadingTexts: string[] = [
  "Brewing fresh ideas...☕️",
  "Unleashing creativity dragons...🐉",
  "Sprinkling some innovation dust...✨",
  "Warming up the brainwaves...🌊",
  "Channeling inner Einstein...🧠",
  "Syncing with the innovation muse...🎨",
  "Brainstorming with bots...🤖",
  "Unlocking hidden potential...🔐",
  "Tuning the creative engines...🚀",
  "Merging with brilliance...💡",
  "Preparing some eureka moments...💥",
  "Loading awesomeness...100%...🚀",
  "Generating brilliant insights...⚡"
];

function getRandomLoadingText(): string {
  const randomIndex = Math.floor(Math.random() * loadingTexts.length);
  return loadingTexts[randomIndex];
}

export function RandomAILoadingElement(): JSX.Element {
  return (
    <div style={{
      width: '100%', display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <ThreeDots
        height="80"
        width="80"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="rings-loading" />
      <Typography style={{ padding: "8px" }}>
        {getRandomLoadingText()}
      </Typography>
    </div>
  );
}