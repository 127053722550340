import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setLoggedIn } from './state/login/LoginReducer';
import { Main } from './Main';
import { Typography } from "@mui/material";
import { LoadingAnimation } from "./components/LoadingAnimation";
import dayjs from "./configuration/configuredDayjs";

const REFRESH_TOKEN_BUFFER_MS = 60 * 60 * 1000; // 60 minutes

function getJwtExpiry(token: string): number | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));

    const payload = JSON.parse(jsonPayload);
    return payload.exp ? payload.exp * 1000 : null;
  } catch (e) {
    console.error('Error decoding JWT', e);
    return null;
  }
}

export function isTokenExpired(token: string): boolean {
  const expiryTime = getJwtExpiry(token);
  if (expiryTime === null) return true;
  console.log("Time to expiry", dayjs(expiryTime).fromNow());
  return (Date.now() + REFRESH_TOKEN_BUFFER_MS > expiryTime);
}

export function MainWithAuth() {
  const dispatch = useDispatch();
  const { isLoading, error, getIdTokenClaims, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);

  const refreshAccessToken = useCallback(async () => {
    if (isTokenRefreshing) return; // Prevent multiple refresh calls.
    setIsTokenRefreshing(true);
    try {
      console.log('Attempting to refresh token');
      await getAccessTokenSilently();
      const token = await getIdTokenClaims();
      localStorage.setItem('scale_token', token?.__raw ?? "")
      dispatch(setLoggedIn(token?.__raw));
    } catch (error) {
      console.error('Error refreshing token', error);
      loginWithRedirect();
    }
    setIsTokenRefreshing(false);
  }, [getAccessTokenSilently, getIdTokenClaims, dispatch, loginWithRedirect]);

  useEffect(() => {
    const checkValidity = async () => {
      const token = localStorage.getItem('scale_token');
      console.log("Checking token validity.");
      if (!token || isTokenExpired(token)) {
        await refreshAccessToken();
      }
    };

    const debounceCheckValidity = () => {
      let timeout: NodeJS.Timeout;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(checkValidity, 300); // Debouncing with 300ms delay
      };
    };

    const debouncedCheckValidity = debounceCheckValidity();

    window.addEventListener('focus', debouncedCheckValidity);
    window.addEventListener('mousedown', debouncedCheckValidity);

    return () => {
      window.removeEventListener('focus', debouncedCheckValidity);
      window.removeEventListener('mousedown', debouncedCheckValidity);
    };
  }, [refreshAccessToken]);

  useEffect(() => {
    const init = async () => {
      if (isAuthenticated) {
        await refreshAccessToken();
      } else if (!isLoading) {
        console.log('Not authenticated. Redirecting to login.');
        localStorage.removeItem('scale_token');
        dispatch(setLoggedIn(undefined));
        loginWithRedirect();
      }
    };
    init();
  }, [isLoading, isAuthenticated, refreshAccessToken]);

  if (isTokenRefreshing) return <LoadingAnimation loadingText="Refreshing authentication" />;
  if (isLoading) return <LoadingAnimation loadingText="Loading authentication" />;
  if (!isAuthenticated) return <LoadingAnimation loadingText="Login (not authenticated yet)" />;

  if (error) {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Typography>Please refresh the browser, application state needs to be reloaded.</Typography></div>
    );
  }

  return <Main />;
}
