import { ApolloClient, DocumentNode } from '@apollo/client'
import gql from 'graphql-tag'
import { DataProvider, RaRecord } from 'react-admin'
import { useSelector } from 'react-redux'
import { ScaleState, store } from '../Store'
import {
  Business,
  FileUpload,
  Link,
  PriorityListItem,
  ProjectFile,
  StorageFile,
} from '../../model/ScaleTypes'
import { isNotNil } from '../../util/ScaleUtils'
import { useEffect, useState } from 'react'

const LIST_PROJECT_FILES = gql(`
  query ListProjectFiles($projectId: uuid!) {
    api_business(where: {id: {_eq: $projectId}}) {
      files {
        name,
        size,
        link {
          expires,
          fileName,
          url,
          type,
        }
      }
    }
  }
`)

const GET_PROJECT_FILE_DOWNLOAD_LINK = gql(`
  query GetProjectFileDownloadLink($projectId: uuid!, $fileName: String!) {
    api_business(where: {id: {_eq: $projectId}}) {
      fileDownloadLink(fileName: $fileName) {
        expires,
        fileName,
        url,
        type,
      }
    }
  }
`)

const GET_PROJECT_FILE_UPLOAD_LINKS = gql(`
  query GetProjectFileUploadLinks($projectId: uuid!, $files: [FileUpload!]!) {
    api_business(where: {id: {_eq: $projectId}}) {
      fileUploadLinks(files: $files) {
        expires,
        fileName,
        url,
      }
    }
  }
`)

const GET_PROJECT_REPORT_TEMPLATE_LINK = gql(`
  query GetProjectReportTemplateLink($projectId: uuid!) {
    api_business(where: {id: {_eq: $projectId}}) {
      reportTemplateLink {
        expires,
        fileName,
        url,
        type,
      }
    }
  }
`)

interface ProjectOpParams {
  projectId: string
}

interface ProjectOperations {
  delete: (params: ProjectOpParams) => Promise<boolean>
}

interface ScaleHasuraExtensions {
  listProjectFiles: (params: ProjectOpParams) => Promise<ProjectFile[]>
  getFileDownloadLink: (params: ProjectFile) => Promise<Link>
  downloadFile: (params: ProjectFile) => Promise<Blob>
  getReportTemplateLink: (params: ProjectOpParams) => Promise<Link | null>
  getFileUploadLinks: (params: ProjectOpParams & { files: FileUpload[] }) => Promise<Link[]>
  uploadFile: (params: ProjectOpParams & { file: File }) => Promise<boolean>
  getSuggestedMaturityCategories: (params: ProjectOpParams & { amount: number }) => Promise<PriorityListItem[]>
  project: ProjectOperations
}

type StrictDataProvider = Pick<DataProvider,
  'getList' | 'getOne' | 'getMany' | 'getManyReference' |
  'update' | 'updateMany' |
  'create' |
  'delete' | 'deleteMany'>

export interface ScaleDataProvider extends StrictDataProvider, ScaleHasuraExtensions { }

export const buildScaleExtensions = (
  baseDataProvider: DataProvider,
  client: ApolloClient<unknown>
): ScaleHasuraExtensions => {
  const executeQuery = (query: DocumentNode, variables: any) => client.query({
    query,
    variables,
    fetchPolicy: 'network-only',
    pollInterval: 0,
  })

  const listProjectFiles: ScaleHasuraExtensions['listProjectFiles'] = async ({ projectId }) => {
    console.log('listProjectFiles')
    const { data } = await executeQuery(LIST_PROJECT_FILES, { projectId })
    if (isNotNil(data)) {
      const files = data.api_business[0]?.files ?? [] as StorageFile[]
      return files.map((file: StorageFile): ProjectFile => {
        return {
          ...file,
          projectId,
        }
      })
    } else {
      throw new Error('Loading list of project files')
    }
  }

  const getFileDownloadLink: ScaleHasuraExtensions['getFileDownloadLink'] = async (file) => {
    if (Date.parse(file.link?.expires ?? 0) >= Date.now() + 2000) {
      return file.link
    }

    const { data } = await executeQuery(GET_PROJECT_FILE_DOWNLOAD_LINK, {
      fileName: file.name,
      projectId: file.projectId,
    })

    if (isNotNil(data)) {
      return data.api_business[0]?.fileDownloadLink as Link
    } else {
      throw new Error('Loading project file download link failed')
    }
  }

  const downloadFile: ScaleHasuraExtensions['downloadFile'] = async (file) => {
    // TODO download using existing link if available and stil valid
    const downloadLink = await getFileDownloadLink(file)
    const response = await fetch(downloadLink.url)
    return response.blob()
  }

  const getReportTemplateLink: ScaleHasuraExtensions['getReportTemplateLink'] = async ({ projectId }) => {
    const { data } = await executeQuery(GET_PROJECT_REPORT_TEMPLATE_LINK, { projectId })
    if (isNotNil(data)) {
      return data.api_business[0]?.reportTemplateLink as Link ?? null
    } else {
      throw new Error('Getting project report template link failed')
    }
  }

  const getFileUploadLinks: ScaleHasuraExtensions['getFileUploadLinks'] = async ({ projectId, files }) => {
    const { data } = await executeQuery(GET_PROJECT_FILE_UPLOAD_LINKS, { projectId, files })
    if (isNotNil(data)) {
      return data.api_business[0]?.fileUploadLinks ?? [] as Link[]
    } else {
      throw new Error('Getting file upload links failed')
    }
  }

  const uploadFile: ScaleHasuraExtensions['uploadFile'] = async ({ projectId, file }): Promise<boolean> => {
    const uploadInfo = await getFileUploadLinks({
      projectId,
      files: [{
        contentType: file.type,
        name: file.name,
        size: file.size,
      }]
    })

    const uploadResponse = await fetch(uploadInfo[0].url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
        'Content-Length': `${file.size}`,
      },
      body: await file.arrayBuffer(),
    })

    if (uploadResponse.ok) {
      return true
    } else {
      throw new Error(`Upload failed: ${uploadResponse.statusText}`)
    }
  }

  const getSuggestedMaturityCategories: ScaleHasuraExtensions['getSuggestedMaturityCategories'] = async ({
    projectId,
    amount,
  }) => {
    const { data } = await baseDataProvider.getList<PriorityListItem>('api_priorityList', {
      filter: { businessId: projectId },
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
    })
    if (isNotNil(data)) {
      const sortedData = data.sort((a, b) => {
        const idDiff = a.id - b.id
        return idDiff !== 0
          ? idDiff
          : a.priorityScore - b.priorityScore
      })
      return sortedData.slice(0, amount)
    } else {
      throw new Error('Getting suggested maturity categories failed')
    }
  }

  return {
    listProjectFiles,
    getFileDownloadLink,
    downloadFile,
    getReportTemplateLink,
    getFileUploadLinks,
    getSuggestedMaturityCategories,
    uploadFile,
    project: {
      delete: async ({ projectId }) => {
        const params = {
          id: projectId,
          data: {
            id: projectId,
            deleted: true
          },
          previousData: {
            id: projectId,
          },
        }
        const { data } = await baseDataProvider.update<Business>('scale_business', params)
        return isNotNil(data)
      }
    }
  }
}

const dataProviderSelector = (state: ScaleState) => state.provider.dataProvider

export const useScaleDataProvider = (): ScaleDataProvider => {
  return useSelector(dataProviderSelector)
}

export const getScaleDataProvider = (): ScaleDataProvider | undefined => {
  return store.getState()?.provider?.dataProvider
}

export const useProjectFiles = ({ projectId }: ProjectOpParams) => {
  const scaleDataProvider = useScaleDataProvider()
  const [error, setError] = useState<unknown>()
  const [files, setFiles] = useState<ProjectFile[]>([])
  const [shouldLoadFiles, setShouldLoadFiles] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    if (projectId && scaleDataProvider && shouldLoadFiles) {
      setLoading(true)
      setShouldLoadFiles(false)

      scaleDataProvider.listProjectFiles({ projectId })
        .then(files => {
          setFiles(files)
          setError(undefined)
        })
        .catch(err => {
          setFiles([])
          setError(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [projectId, scaleDataProvider, shouldLoadFiles])

  return {
    loading,
    error,
    files,
    uploadFile: async (file: File) => { await scaleDataProvider.uploadFile({ projectId, file }) },
    reload: () => setShouldLoadFiles(true),
  }
}


const fakeSingle = async <T>(): Promise<{ data: T }> => {
  return Promise.resolve({
    data: {} as T
  })
}

const fakeList = async <T>() => {
  return Promise.resolve({
    data: [] as T[]
  })
}

const fakePage = async <T>() => {
  return Promise.resolve({
    data: [] as T[],
    total: 0,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
    }
  })
}

const notImplemented = async () => {
  throw new Error("Function not implemented.")
}

export const fakeScaleDataProvider: ScaleDataProvider = {
  create: fakeSingle,
  delete: fakeSingle,
  deleteMany: <T extends RaRecord>() => fakeList<T['id']>(),
  getList: fakePage,
  getOne: fakeSingle,
  getMany: fakeList,
  getManyReference: fakePage,
  update: fakeSingle,
  updateMany: <T extends RaRecord>() => fakeList<T['id']>(),

  listProjectFiles: notImplemented,
  getFileDownloadLink: notImplemented,
  downloadFile: notImplemented,
  getReportTemplateLink: notImplemented,
  getFileUploadLinks: notImplemented,
  uploadFile: notImplemented,
  getSuggestedMaturityCategories: notImplemented,
  project: {
    delete: notImplemented,
  },
}
