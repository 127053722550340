import React, { CSSProperties, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { descriptionLookupKeyFrom, MaturityCategory, MaturityDescriptionExtended, MaturityLevel, MaturityScore } from '../../model/ScaleTypes';
import Joyride, { Step } from 'react-joyride';
import { MATURITY_CATEGORY_COLORS, MATURITY_CATEGORY_COLORS_RESOLVED, UIColors } from '../Colors';
import { MaturityDescriptionState, MaturityDescriptionTableCell } from './MaturityDescriptionTableCell';
import { CategoryTitleCell } from './CategoryTitleCell';
import { Card, Typography } from '@mui/material';
import { maturityTableTutorialSteps } from './MaturityTableTutorial';
import { ScoresByCategories } from '../../pages/MaturityView';
import { useSearchParams } from 'react-router-dom';
import { ScaleSvg } from '../Icons';
import useMaturityModel from '../../state/useMaturityModel';
import { LoadingAnimation } from '../LoadingAnimation';
import VariantBox from '../VariantBox';

const verticalCellStyle: CSSProperties = {
  writingMode: 'sideways-lr',
  whiteSpace: 'nowrap',
  padding: '4px',
  fontWeight: 'bold',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
}

export interface MaturityTableProps {
  levels: MaturityLevel[];
  modelData: ScoresByCategories;
  updatedAt: number;
  hiddenLevels?: number[];
  onClickBack?: () => void;
  onClickExport?: () => void;
  onClickDescriptionCheckbox?: (clickedCategoryDescription: MaturityDescriptionExtended) => void;
}

function levelStatus(categoryScore: MaturityScore, level: number): MaturityDescriptionState {
  if (!categoryScore) return 'unchecked';
  if (categoryScore.notesScore === level) {
    if (categoryScore.notesScore === categoryScore.score) return 'checked'; // means last note was resolved
    return 'inprogress'; // note was for different level than the score (ie. comment on the next level)
  }
  if (categoryScore.score >= level) return 'checked';
  return "unchecked";
}

type ShowMode = "All" | "Milestone";

export const MaturityTable: React.FC<MaturityTableProps> = ({
  levels = [],
  modelData = {},
  updatedAt,
  hiddenLevels = [],
  onClickBack = () => { },
  onClickExport = () => { },
  onClickDescriptionCheckbox = (clickedCategoryDescription: MaturityDescriptionExtended) => { },
}) => {
  const { maturityModel, loading } = useMaturityModel();
  const [transpose, setTranspose] = React.useState<boolean>(false)
  const [run, setOnboardingRun] = React.useState<boolean>(false);
  const [steps, setSteps] = React.useState<Step[]>([]);


  const [collapsedLevels, setCollapsedLevels] = React.useState<number[]>(hiddenLevels);
  const [showMode, setShowMode] = React.useState<ShowMode>(hiddenLevels.length > 0 ? 'Milestone' : 'All');
  const [collapsedCategories, setCollapsedCategories] = React.useState(new Set<number>([]));

  // This manipulates which categories are shown (by ordinal). If empty, all are shown.
  const [focusCategoryOrdinals, setFocusCategoryOrdinals] = React.useState(new Set<number>([]));
  const [resetCounter, setResetCounter] = React.useState<number>(0);
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (showMode === 'All') {
      setCollapsedLevels([])
      return;
    }
    setCollapsedLevels(hiddenLevels);
  }, [hiddenLevels, showMode]);

  useEffect(() => {
    if (searchParams && searchParams.has('focusCategories')) {
      const categoryOrdinals = searchParams.get('focusCategories')?.split(',').map(n => parseInt(n, 10)) ?? []
      console.log('Parsed category ordinals:', categoryOrdinals, 'from', searchParams.get('focusCategories'))
      setFocusCategoryOrdinals(new Set(categoryOrdinals))
    }
  }, [searchParams])

  // hide categories that have all shown levels checked
  useEffect(() => {
    if (!maturityModel) return;
    const shownLevels = levels.filter(level => collapsedLevels.indexOf(level.level) === -1);
    const highestShownLevel = Math.max(...shownLevels.map(lvl => lvl.level));
    const collapsedCategories = new Set<number>();
    maturityModel.categories.forEach(category => {
      // if the category's level is higher or equal to highest then it is hidden.
      if (highestShownLevel <= modelData[category.id]?.score) collapsedCategories.add(category.order);
    });
    setCollapsedCategories(collapsedCategories);
  }, [collapsedLevels, levels, modelData, resetCounter, maturityModel]);

  useEffect(() => {
    setSteps(maturityTableTutorialSteps);
  }, []);

  const shownLevels = useMemo(() => levels.filter(level => !collapsedLevels.includes(level.level)), [collapsedLevels, levels]);
  const shownCategories: MaturityCategory[] = useMemo(() => {
    if (!maturityModel) return []
    return maturityModel.categories.filter(category => !collapsedCategories.has(category.order))
  }, [collapsedCategories, maturityModel, modelData]);

  if (loading || !maturityModel) return <LoadingAnimation />
  const categories = maturityModel.categories


  const handleToggleHiddenLevels = () => {
    if (showMode === 'All') setShowMode('Milestone');
    else setShowMode('All')
  };

  const toggleCategory = (category: MaturityCategory) => setCollapsedCategories(prevSet => {
    const set = new Set(prevSet);
    set.delete(category.order)
    return set;
  })

  document.title = "Scale SaaS - Maturity Assessment";



  const ToggleLevelsRow = ({ rowKey }: { rowKey: string }) => hiddenLevels.length > 0 ?
    <TableRow key={rowKey}>
      <TableCell colSpan={categories.length * 2 + 2} align="center">
        <ToggleLevelsButtonHorizontal />
      </TableCell>
    </TableRow> : <></>

  const ToggleLevelsButtonHorizontal = () => <Button size="small" variant="text" style={{ width: '100%' }} color="primary" onClick={handleToggleHiddenLevels}>
    {showMode === 'Milestone' ? "Show All Levels" : "Hide Levels outside of Current Milestone"}</Button>


  const ToggleLevelsButtonVertical = () => <Button size='small' style={{ minWidth: 0, height: "100%", padding: '0px', margin: '0px' }} variant='text'
    color="primary" onClick={handleToggleHiddenLevels}>
    {showMode === 'Milestone' ? "Show All Levels" : "Hide Levels outside of Current Milestone"}</Button>

  const ActionButtonsCell = () => (
    <TableCell align="center">
      <Button variant='QuinaryCTA' startIcon={ScaleSvg.Transpose} onClick={() => { setTranspose(!transpose) }}>Transpose</Button>
    </TableCell >
  )

  const categoriesInTable = focusCategoryOrdinals.size > 0 ?
    categories.filter(category => focusCategoryOrdinals.has(category.order)) :
    categories;

  const NormalTable = () =>
    <Table size="small" sx={{ margin: '10px' }}>
      <TableHead>
        <TableRow className="categories">
          <ActionButtonsCell />
          {categoriesInTable.map((category, index) => <CategoryTitleCell key={index} colSpan={2} category={category} skipped={!shownCategories.some(cat => cat.id === category.id)} />)}
          <ActionButtonsCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <ToggleLevelsRow rowKey="toggleAbove" />
        {shownLevels.map((level, rowIndex) =>
          <TableRow key={rowIndex}>
            <TableCell component="th" scope="row" className={"levelName_" + level.level} >{level.level}. {level.name}</TableCell>

            {categoriesInTable.map((category, i) => {
              const isShown = shownCategories.some(cat => cat.id === category.id);
              if (isShown) return <MaturityDescriptionTableCell key={i}
                maturityDescription={maturityModel!.descriptionLookup[descriptionLookupKeyFrom(category, level)]}
                state={levelStatus(modelData[category.id], level.level)}
                onClickDescriptionCheckbox={onClickDescriptionCheckbox}
                updatedAt={updatedAt}
              />

              if (rowIndex > 0) return null; // only first is drawn, is it has colspan
              // The vertical table cell to show the category that has been collapsed.
              return <TableCell key={i} colSpan={2} rowSpan={shownLevels.length}
                style={{
                  ...verticalCellStyle,
                  backgroundColor: MATURITY_CATEGORY_COLORS_RESOLVED[category.order - 1],
                  color: 'white'
                }}
                align="center">
                <Button size='small' style={{ color: 'white', minWidth: 0, height: "100%", padding: '0px', margin: '0px' }} variant='text'
                  onClick={() => setCollapsedCategories(prevSet => {
                    const set = new Set(prevSet);
                    set.delete(category.order)
                    return set;
                  })}
                >{category.name} ★ MILESTONE REACHED</Button>
              </TableCell>
            }
            )}

            <TableCell>{level.level}. {level.name}</TableCell>
          </TableRow>
        )}
        <ToggleLevelsRow rowKey="toggleBelow" />
        {/* This is the bottom row */}
        <TableRow>
          <ActionButtonsCell />
          {categoriesInTable.map((category, index) => <CategoryTitleCell colSpan={2} key={index} category={category} skipped={!shownCategories.some(cat => cat.id === category.id)} />)}
          <ActionButtonsCell />
        </TableRow>
      </TableBody>
    </Table >

  const reversedShownLevels = shownLevels.slice().reverse();

  const TransposedTable = () => <Table size="small">
    <TableHead></TableHead>
    <TableBody>
      <TableRow className="levels">
        <ActionButtonsCell />
        {hiddenLevels.length > 0 && <TableCell rowSpan={2 + categories.length}
          style={verticalCellStyle}
          align="center">
          <ToggleLevelsButtonVertical />
        </TableCell>}
        {reversedShownLevels.map((level, levelIndex) =>
          <TableCell key={"levels" + levelIndex} colSpan={2} width="200px" align="center" className={"levelName_" + level.level}>
            <Typography minWidth={200}>{level.level}. {level.name}</Typography>
          </TableCell>
        )}
        {hiddenLevels.length > 0 && <TableCell rowSpan={2 + categories.length}
          style={verticalCellStyle}
          align="center">
          <ToggleLevelsButtonVertical />
        </TableCell>}
        <ActionButtonsCell />
      </TableRow>
      {categoriesInTable.map((category, categoryIndex) => {
        const isShown = shownCategories.some(cat => cat.id === category.id);

        if (!isShown) {
          return <TableRow key={categoryIndex}>

            <TableCell align="center" style={{
              backgroundColor: MATURITY_CATEGORY_COLORS[category.order - 1],
              color: 'white'
            }}>{category.name}</TableCell>
            <TableCell colSpan={reversedShownLevels.length * 2}
              style={{
                backgroundColor: MATURITY_CATEGORY_COLORS_RESOLVED[category.order - 1],
                color: 'white'
              }}
              align="center">
              <Button size='small' style={{ color: 'white', minHeight: 0, width: "100%", padding: '0px', margin: '0px' }} variant='text'
                onClick={() => toggleCategory(category)}
              >{category.name} ★ MILESTONE REACHED</Button>
            </TableCell>
            <TableCell align="center" style={{
              backgroundColor: MATURITY_CATEGORY_COLORS[category.order - 1],
              color: 'white'
            }}>{category.name}</TableCell>

          </TableRow>
        }
        return <TableRow key={categoryIndex}>
          <CategoryTitleCell key={"first" + categoryIndex} category={category} skipped={false} />
          {reversedShownLevels.map((level, colIndex) =>
            <MaturityDescriptionTableCell key={colIndex}
              maturityDescription={maturityModel.descriptionLookup[descriptionLookupKeyFrom(category, level)]}
              state={levelStatus(modelData[category.id], level.level)}
              onClickDescriptionCheckbox={onClickDescriptionCheckbox}
              updatedAt={updatedAt}
            />
          )}
          <CategoryTitleCell key={"second" + categoryIndex} category={category} skipped={false} />
        </TableRow>
      }
      )}

      {/* This is the bottom row */}
      <TableRow key="bottomRow">
        <ActionButtonsCell />
        {reversedShownLevels.map((level, levelIndex) =>
          <TableCell key={"bottom" + levelIndex} colSpan={2} width="500px" align='center' className={"levelName_" + level.level}>{level.level}. {level.name}</TableCell>
        )}
        <ActionButtonsCell />
      </TableRow>

    </TableBody>
  </Table>

  return <TableContainer className="maturityTableCont" id="maturityTable" component={Card} sx={{ margin: "10px" }}>
    <Joyride
      continuous={true}
      run={run}
      scrollToFirstStep={true}
      showSkipButton={false}
      steps={steps}
      styles={{
        overlay: {
          width: document.body.scrollWidth
        },
        options: {
          zIndex: 10000,
        },
      }}
    />
    <VariantBox variant="row-nowrap">
      <Button variant='QuinaryCTA' onClick={() => { setShowMode('All'); setOnboardingRun(!run); }}>Tutorial</Button>
      <Button variant='QuinaryCTA' startIcon={ScaleSvg.Report} onClick={onClickExport}>Export Table</Button>

      <Typography variant="body1" sx={{ fontSize: '12px', color: UIColors.Text.Secondary }}>Show:</Typography>
      <Button onClick={() => setShowMode('All')} variant={showMode === 'All' ? "QuinaryCTASelected" : "QuinaryCTA"} >All levels</Button>
      <Button disabled={hiddenLevels.length === 0} onClick={() => setShowMode('Milestone')} variant={showMode === 'Milestone' ? "QuinaryCTASelected" : "QuinaryCTA"} >Ongoing milestone</Button>
      {/*      <Button variant='QuinaryCTA' onClick={handleReset}>Collapse</Button> */}
    </VariantBox>

    {transpose ? <TransposedTable /> : <NormalTable />}
  </TableContainer>
};

