import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { ScaleStatus } from '../../model/ScaleTypes'
import { UIColors } from '../Colors'

type StatusData = {
  sort: number
  text: string
  color?: string
}

export const statusDisplayMap: { [key: string]: StatusData } = {
  proposed: {
    text: '💡 In Concepting',
    sort: 1,
  },
  active: {
    text: 'Active',
    color: UIColors.Interactions.ActiveGreen,
    sort: 2
  },
  'on hold': {
    text: 'On Hold',
    color: UIColors.Interactions.ProgressBlue,
    sort: 3
  },
  cancelled: {
    text: 'Cancelled',
    color: UIColors.Complementary.Tangerine,
    sort: 4
  },
  archived: {
    text: 'Archived',
    color: UIColors.Interface.DetailGrey,
    sort: 5
  }
};

const ProjectStatusChip = ({ status }: { status?: ScaleStatus }) => {
  const data = statusDisplayMap[status as string];
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {data.color && <FiberManualRecordIcon fontSize="inherit" style={{ color: data.color, marginRight: 4 }} />}
      <span style={{ textTransform: 'capitalize' }}>{data.text}</span>
    </span>
  )



}

export default ProjectStatusChip
