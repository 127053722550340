import TableCell, { TableCellProps } from "@mui/material/TableCell";
import { MaturityCategory } from "../../model/ScaleTypes";
import { MATURITY_CATEGORY_COLORS, UIColors } from "../Colors";
import Typography from "@mui/material/Typography";

interface CategoryTitleCellProps extends TableCellProps {
  key: React.Key;
  category: MaturityCategory;
  skipped: boolean;
}

export const CategoryTitleCell: React.FC<CategoryTitleCellProps> = ({ category, skipped, ...props }) => {
  if (skipped) return <TableCell
    {...props}
    key={category.id}
    style={{ padding: "0px", backgroundColor: MATURITY_CATEGORY_COLORS[category.order - 1] }} align="center" />
  return (
    <TableCell
      {...props}
      key={category.id}
      className={category.name === "Financial Model" ? "fin_cat" : ""}
      style={{ height: "100px", justifyContent: 'center', backgroundColor: MATURITY_CATEGORY_COLORS[category.order - 1] }}
      align="center"
    >
      <Typography style={{
        minWidth: "200px", textAlign: 'center', color: UIColors.Text.Primary, fontWeight: '400'
      }}>{category.name}</Typography>
    </TableCell>
  );
};
