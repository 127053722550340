import React, { ComponentType, useState } from 'react';
import { Menu, MenuProps, useSidebarState } from 'react-admin';
import Divider from '@mui/material/Divider';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SubMenu from './SubMenu';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { useScalePermissions } from '../components/withIdentityChecks';
import ScaleIcons from '../components/Icons';
import { UIColors } from '../components/Colors';

interface ScaleMenuState {
    [submenuName: string]: boolean;
}


function deduceLocation(): string {
    const path = window.location.pathname.startsWith('/') ? window.location.pathname.substring(1) : window.location.pathname;
    if (path === "") return "dashboard";
    if (path.startsWith("api_business") || path.startsWith("business")) return "business";
    return path.split("/")[0];
}

const ScaleMenu: ComponentType<MenuProps> = (props) => {
    const [open, setOpen] = useSidebarState();
    const dense = false;

    const [state, setState] = useState<ScaleMenuState>({
        menuCases: false,
        menuModel: false,
    });

    const handleToggle = (menu: string) => {
        setState((state) => ({ ...state, [menu]: !state[menu] }));
        setOpen(true);
    };

    const { permissions, isLoading: loading } = useScalePermissions();

    if (loading) return <LoadingAnimation loadingText="Loading authentication" />;

    const isSingleBusiness = permissions.isLimitedAccess;
    const showMenu =
        !permissions.isUnverified && !permissions.hasUnknownOrganization && !isSingleBusiness;


    const location = deduceLocation();

    const selected = {
        backgroundColor: UIColors.Complementary.Tangerine,
        borderRadius: "16px",
    }

    const getStyle = (name: string) => {
        if (location === name) return selected;
        return {}
    }

    return (
        <Menu>
            {isSingleBusiness ? (
                <Menu.Item to="/" primaryText="Businesses" leftIcon={<ScaleIcons.Drawer sx={getStyle('dashboard')} />} />
            ) : (
                <Menu.Item to="/" primaryText="Dashboard" leftIcon={<ScaleIcons.Grid sx={getStyle('dashboard')} />} />
            )}
            {showMenu && [
                <Menu.Item
                    key="businessList"
                    to="businessList"
                    primaryText="menu.businesses"
                    leftIcon={<ScaleIcons.Drawer sx={getStyle('business')} />}
                />,
                < Menu.Item
                    key="steeringReport"
                    to="/steeringReport"
                    primaryText="Steering & Team"
                    leftIcon={< ScaleIcons.Team sx={getStyle('steeringReport')} />}
                />,
                <Menu.Item
                    key="history"
                    to="history"
                    primaryText="Portolio History"
                    leftIcon={<ScaleIcons.Chart sx={getStyle('history')} />}
                />,
                <Menu.Item
                    key="benchmarking"
                    to="benchmarking"
                    primaryText="Benchmarking"
                    leftIcon={<ScaleIcons.Compare sx={getStyle('benchmarking')} />}
                />,
                /*                <Menu.Item
                                    key="earlyInsights"
                                    to="earlyInsights"
                                    primaryText="Foresight (beta)"
                                    leftIcon={<ScaleIcons.Light sx={getStyle('earlyInsights')} />}
                                />,*/
                <SubMenu
                    key="settingsMenu"
                    sidebarIsOpen={open}
                    isOpen={state.menuSettings}
                    handleToggle={() => handleToggle('menuSettings')}
                    name="menu.settings"
                    dense={dense}
                    icon={<ScaleIcons.Cog />}
                >
                    <Menu.Item
                        key="scale_organizationUnit"
                        to="scale_organizationUnit"
                        primaryText="menu.organizationUnits"
                        leftIcon={<ScaleIcons.Building sx={getStyle('scale_organizationUnit')} />}
                    />
                    <Menu.Item
                        key="integrations"
                        to="integrations"
                        primaryText="menu.integrations"
                        leftIcon={<ScaleIcons.Adjust sx={getStyle('integrations')} />}
                    />
                </SubMenu>,
            ]}

            {permissions.isAdmin && [
                <Divider key="divider" />,
                <Menu.Item
                    key="adminMenu"
                    to="/"
                    disabled
                    leftIcon={<ArrowRightIcon />}
                    primaryText="ADMIN MENU"
                />,
                <SubMenu
                    key="modelMenu"
                    handleToggle={() => handleToggle('menuModel')}
                    isOpen={state.menuModel}
                    sidebarIsOpen={open}
                    name="menu.maturityModel"
                    icon={<ViewComfyIcon />}
                    dense={dense}
                >
                    <Menu.Item
                        key="scale_maturityCategory"
                        to="scale_maturityCategory"
                        primaryText="Categories"
                        leftIcon={<ScaleIcons.Column sx={getStyle('scale_maturityCategory')} />}
                    />
                    <Menu.Item
                        key="scale_maturityLevel"
                        to="scale_maturityLevel"
                        primaryText="Levels"
                        leftIcon={<ScaleIcons.List sx={getStyle('scale_maturityLevel')} />}
                    />
                    <Menu.Item
                        key="scale_maturityDescription"
                        to="scale_maturityDescription"
                        primaryText="Descriptions"
                        leftIcon={<DescriptionIcon sx={getStyle('scale_maturityDescription')} />}
                    />
                </SubMenu>,
                <Menu.Item
                    key="scale_organization"
                    to="scale_organization"
                    primaryText="Customer Orgs"
                    leftIcon={<ScaleIcons.Building sx={getStyle('scale_organization')} />}
                />,
                <Menu.Item
                    key="adminSettings"
                    to="adminSettings"
                    primaryText="Admin Settings"
                    leftIcon={<ScaleIcons.Cog sx={getStyle('adminSettings')} />}
                />,
            ]}
        </Menu>
    );
};

export default ScaleMenu;
