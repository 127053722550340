import { Chip, Checkbox } from '@mui/material';
import React from 'react';
import { BusinessExtended, defaultMaturityModel } from '../model/ScaleTypes';
import { UsesMaturityModelProps } from './charts/ScaleGraphTypes';
import { CurrencyFormat } from '../configuration/configuration';
import { getTextColorForBackground, maturityScoreColorScale, nameToColor } from './Colors';
import currency from 'currency.js';
import SortableTable, { Column } from './common/SortableTable';
import dayjs from '../configuration/configuredDayjs';
import { ScaleSvg } from './Icons';
import { ScaleTypography } from './Styles';

export interface BusinessListProps extends UsesMaturityModelProps {
  businessList: BusinessExtended[],
  onClickListItem: (item: BusinessExtended) => void,
  onCheckboxChange?: (id: string, checked: boolean) => void,
  //  onCheckboxSelectAll?: (state: boolean) => void,
  emptyListComponent?: React.ReactNode,
  initialNumberOfRows?: number,
  showStatus?: boolean,
  columnNames: (keyof BusinessExtended)[]
}

const ScoreElement: React.FC<{ value: number, maxValue: number, width: number }> = ({
  value,
  maxValue,
  ...props
}) => {
  return <Chip variant='tag' size='small' sx={{
    width: '50px',
    backgroundColor: value ? maturityScoreColorScale((value || 0) / maxValue) : 'lightgray'
  }} label={value ? "" + (Math.round(value * 10) / 10) : 'N/A'} />

}

const columnLookup: { [key: string]: Column<BusinessExtended> } = {
  'name': { id: 'name', header: 'Name', style: { ...ScaleTypography.Bold.Big, maxWidth: '256px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } },
  'organizationUnitName': { id: 'organizationUnitName', header: 'Org. unit', style: { maxWidth: '256px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } },
  'tags': {
    id: 'tags', header: 'business.tags', style: { maxWidth: "300px" },
    format: (value: any) => (value?.map((tag: string, i: number) => <Chip variant='tag' sx={{ backgroundColor: nameToColor(tag), color: getTextColorForBackground(nameToColor(tag)) }} size='small' key={i} label={tag} />))
  },
  'businessPotential': {
    id: 'businessPotential', header: 'Potential', style: { textAlign: "right", maxWidth: "100px", overflow: 'hidden', textOverflow: "ellipsis" },
    format: (value: any) => { return value ? currency(value, CurrencyFormat).format() : "-"; }
  },
  'budget': {
    id: 'budget', header: 'Budget', style: { textAlign: "right" },
    format: (value: any) => { return value ? currency(value, CurrencyFormat).format() : "-"; }
  },
  'description': { id: 'description', header: 'Description', style: { maxWidth: "400px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } },
  'modifiedAt': {
    id: 'modifiedAt', header: 'Last modified', style: { whiteSpace: "nowrap" },
    format: (value: string) => (value ? dayjs(value).fromNow() : "never")
  },
  'createdAt': {
    id: 'createdAt', header: 'Created', style: { whiteSpace: "nowrap" },
    format: (value: string) => (value ? dayjs(value).fromNow() : "never")
  },
  'startDate': { id: 'startDate', header: 'Start date', style: { whiteSpace: "nowrap" } },
  'scoreModifiedAt': {
    id: 'scoreModifiedAt', header: 'Last Assessed', style: { whiteSpace: "nowrap" },
    format: (value: string) => (value ? dayjs(value).fromNow() : "never")
  }
}


export const BusinessList: React.FC<BusinessListProps> = ({
  maturityModel = defaultMaturityModel,
  businessList,
  onClickListItem,
  onCheckboxChange,
  emptyListComponent,
  initialNumberOfRows,
  showStatus = true,
  columnNames,
  ...props
}) => {
  if ((!businessList || businessList.length === 0) && emptyListComponent) return <>{emptyListComponent}</>;

  const dynamicColumnLookup: { [key: string]: Column<BusinessExtended> } = {
    'score': {
      id: 'score', header: 'Score',
      format: (value) => (<ScoreElement width={50} value={value} maxValue={maturityModel.maxLevel} />)
    }
  }
  const columns = columnNames.map(name => {
    var col = columnLookup[name]
    if (col) return col;
    col = dynamicColumnLookup[name];
    if (!col) throw Error(`Column '${name}' not defined in lookups`);
    return col;
  })


  // If there is the onCheckboxChange prop, add the checkbox column to the beginning of the columns array
  if (onCheckboxChange) columns.unshift(
    {
      id: 'id', disableSorting: true, disableEvents: true, header: '', style: { maxWidth: '0px' },
      //      id: 'id', disableSorting: true, header: <Checkbox style={{ padding: 0 }} onChange={(event) => onCheckboxSelectAll && onCheckboxSelectAll(event.target.checked)} />, style: { maxWidth: '0px' },
      format: (value, item) => (<Checkbox
        sx={{ padding: 0, height: "16px", width: "16px" }}
        icon={ScaleSvg.Tick}
        checkedIcon={ScaleSvg.Tick}
        onChange={(event) => {
          onCheckboxChange(value, event.target.checked);
        }
        } />)
    },
  )
  return (
    <SortableTable onRowClick={onClickListItem} data={businessList} columns={columns} defaultSortColumn={columns[columns.length - 1]} initialRowsPerPage={initialNumberOfRows} />
  )

};
