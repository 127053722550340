import { Button, ButtonProps, styled } from '@mui/material'

type SupportedButtonProps = Pick<ButtonProps, 'color' | 'disabled' | 'size' | 'sx' | 'variant'>

type CustomFileInputProps = SupportedButtonProps & {
  accept: string
  onFileSelect: (f?: File) => unknown
  text?: string,
  multiple?: boolean,
}

const makeStyledInput = styled('input')

const HiddenInput = makeStyledInput({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const CustomFileSelector = ({
  accept,
  disabled = false,
  onFileSelect,
  sx,
  text = 'Select file',
  color,
  size = 'medium',
  variant = 'contained',
  multiple = false,
}: CustomFileInputProps) => (
  <Button

    component='label'
    disabled={disabled}
    role={undefined}
    tabIndex={-1}
    color={color}
    size={size}
    variant={variant}
    sx={sx}
  >
    <span>{text}</span>
    <HiddenInput
      type='file'
      accept={accept}
      multiple={multiple}
      onChange={(e) => onFileSelect(e.target.files?.item(0) ?? undefined)}
    />
  </Button>
)

export default CustomFileSelector
