import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material"
import { useState } from "react"
import { RaRecord, useTranslate } from "react-admin"
import { UIColors } from "./Colors"
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import { EditDialog } from "./EditDialog"
import VariantBox from "./VariantBox";

interface ContentWrapperProps {
  active: boolean
  children?: JSX.Element | JSX.Element[] | null
}

const ContentWrapperCard = ({ active, children }: ContentWrapperProps) => {
  return (
    <Card style={{ minHeight: '12lh', height: '100%', opacity: active ? 1 : 0.5 }}>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {children}
      </div>
    </Card>
  )
}

export interface DisplayCardProps {
  translationPrefix: string
  active: boolean
  children?: JSX.Element | JSX.Element[] | null
}

/**
 * Display card is simply showing the content on the card
 * @returns 
 */
export const DisplayCard: React.FC<DisplayCardProps> = ({
  translationPrefix,
  active,
  children,
}) => {
  const translate = useTranslate();
  const content = active ? children : null
  return (
    <ContentWrapperCard active={active}>
      <CardHeader title={translate(`${translationPrefix}.title`)} />
      <CardContent>{content}</CardContent>
    </ContentWrapperCard>
  )
}

type SaveClickHandler = (formData: Record<string, any>) => Promise<any> | void

export interface EditableWizardCardProps {
  translationPrefix: string
  step: number
  currentStep: number
  displayContent?: JSX.Element | null
  helpTitle?: string
  helpContent?: JSX.Element[]
  editRecord?: RaRecord
  editForm?: JSX.Element | null
  onEditCancel?: () => void
  onEditSave?: SaveClickHandler
  saveButtonTitle?: string

  /**
   * Provides a possibility of rendering additional Buttons or other components and passes them the ability to switch the edit dialog
   * @param setIsDialogOpen 
   * @returns JSX.Element to be displayed
   */
  alternativeCardAction?: (setIsDialogOpen: (isOpen: boolean) => void) => JSX.Element
}

export const EditableWizardCard: React.FC<EditableWizardCardProps> = ({
  translationPrefix,
  step,
  currentStep,
  displayContent,
  helpTitle,
  helpContent,
  editRecord,
  editForm,
  onEditCancel = () => { },
  onEditSave = async () => Promise.resolve(window.alert("save not implemented")),
  saveButtonTitle,
  alternativeCardAction,
}) => {
  const translate = useTranslate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [helpPage, setHelpPage] = useState<number>(0);

  const isNextToBeFilled = step === currentStep;
  const isReadyForEdit = step < currentStep;

  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  };

  const handleCancel = async () => {
    onEditCancel()
    handleCloseDialog()
  }

  const title = translate(`${translationPrefix}.title`)

  const headerAction = isReadyForEdit
    ? <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", gap: 1 }}>
      {helpContent && helpContent.length > 0 && <>
        <IconButton onClick={() => { setHelpPage(0); setIsHelpOpen(true) }} sx={{ marginRight: 1 }} ><QuestionMarkOutlinedIcon sx={{ height: "10px", width: "10px" }} /></IconButton>
        <Dialog open={isHelpOpen}>
          <DialogTitle>
            {helpTitle} ({helpPage + 1}/{helpContent.length})
          </DialogTitle>
          <DialogContent>
            {helpContent[helpPage]}
          </DialogContent>
          <DialogActions>
            <Button variant='TetriaryCTA' size="small" color="primary" onClick={() => helpPage < helpContent.length - 1 ? setHelpPage((prev) => prev + 1) : setIsHelpOpen(false)}>
              {helpPage < helpContent.length - 1 ? "next" : "ok"}
            </Button>
          </DialogActions>
        </Dialog>
      </>
      }
      <Button
        variant='TetriaryCTA'
        size='small'
        onClick={handleOpenDialog}
      >{translate(`${translationPrefix}.editText`)}</Button>
    </div >
    : <Avatar
      sx={{
        height: 24,
        width: 24,
        backgroundColor: isNextToBeFilled ? UIColors.Interactions.BrightRed : UIColors.Interactions.DarkBlue,
        fontSize: '12px',
        fontWeight: 'bold',
      }}>{step}</Avatar>

  const editDialog = (<EditDialog
    open={isDialogOpen}
    onClose={handleCloseDialog}
    onEditSave={onEditSave}
    title={title}
    editRecord={editRecord}
    editForm={editForm}
    onEditCancel={onEditCancel}
    saveButtonTitle={saveButtonTitle}
  />);

  const firstTimeContent = <div>
    <Typography gutterBottom variant="Regular/Big">
      {translate(`${translationPrefix}.instruction`)}
    </Typography>
  </div>

  const content = isReadyForEdit
    ? displayContent
    : isNextToBeFilled
      ? firstTimeContent
      : null


  const alternativeActions = alternativeCardAction ? alternativeCardAction(setIsDialogOpen) : <></>

  return (
    <ContentWrapperCard active={currentStep >= step}>
      <CardHeader title={title} action={headerAction} />
      <CardContent>{content}</CardContent>
      {isNextToBeFilled
        ? <CardActions sx={{ flexGrow: 1, marginTop: 4 }}>
          <VariantBox variant='row-nowrap' sx={{ flexGrow: 1, justifyContent: 'space-between' }}>
            <Button
              variant="PrimaryCTA"
              color="primary"
              onClick={handleOpenDialog}
            >{translate(`${translationPrefix}.actionText`)}</Button>
            {alternativeActions}
          </VariantBox>
        </CardActions>
        : <></>
      }
      {editDialog}
    </ContentWrapperCard>
  )
}

