import chroma from "chroma-js";
import { stringToHash } from "../util/ScaleUtils";

export const UIColors = {
  Special: {
    Invisible: 'rgba(255,255,255,0)'
  },
  Text: {
    Primary: "rgba(20, 20, 20, 1)",
    Secondary: "rgba(128, 128, 128, 1)",
    Inverted: "rgba(255, 255, 255, 1)",
    Brand: "rgba(255, 152, 0, 1)",
    Warning: "rgba(175, 81, 89, 1)",
    Disabled: "rgba(233, 233, 233, 1)",
  },
  Interface: {
    BackgroundGrey: "rgba(241, 241, 241, 1)",
    DetailGrey: "rgba(233, 233, 233, 1)",
    DimmedWhite: "rgba(249, 249, 249, 1)",
    PureWhite: "rgba(255, 255, 255, 1)",
    LightRed: "rgba(251, 230, 231, 1)",
    LightGreen: "rgba(231, 252, 231, 1)",
    LightBlue: "rgba(237, 244, 249, 1)"
  },
  Interactions: {
    ActiveGreen: 'rgba(128, 227, 106, 1)',
    "ActiveGreen--Dark": 'rgba(76, 177, 58, 1)',
    BrightGreen: 'rgba(128, 227, 106, 1)',
    DarkGreen: "rgba(76, 177, 58, 1)",
    BrightRed: "rgba(255, 95, 109, 1)",
    "NotificationRed--Light": "rgba(255, 95, 109, 1)",
    DarkBlue: "rgba(37, 50, 65, 1)",
    ProgressBlue: "rgba(132, 198, 249, 1)",
    "InactiveGrey--List": "rgba(233, 233, 233, 1)",
    "InactiveGrey--Dark": "rgba(139, 145, 153, 1)",
  },
  Complementary: {
    Avocado: 'rgba(204, 247, 211, 1)',
    Clove: 'rgba(244, 168, 235, 1)',
    Sprout: 'rgba(177, 219, 105, 1)',
    Lavender: 'rgba(202, 177, 255, 1)',
    Sunset1: 'rgba(255, 237, 116, 1)',
    Sunset2: 'rgba(255, 214, 102, 1)',
    Sunset3: 'rgba(255, 187, 91, 1)',
    Sunset4: 'rgba(255, 165, 62, 1)',
    Ice1: 'rgba(151, 222, 249, 1)',
    Ice2: 'rgba(112, 189, 252, 1)',
    Ice3: 'rgba(82, 166, 244, 1)',
    Mint1: 'rgba(126, 231, 207, 1)',
    Mint2: 'rgba(85, 218, 187, 1)',
    Tangerine: 'rgba(246, 151, 133, 1)',
  },

}

export const ScaleColors = {
  Maturity: {
    Resolved: {
      Standard: UIColors.Interactions.BrightGreen,
      Dark: UIColors.Interactions.DarkGreen,
    },
    InProgress: {
      Standard: UIColors.Interactions.ProgressBlue,
      Dark: UIColors.Complementary.Ice3,
    },
    1: 'rgba(146, 192, 255, 1)',
    2: 'rgba(152, 221, 234, 1)',
    3: 'rgba(172, 239, 206, 1)',
    4: 'rgba(203, 239, 119, 1)',
    5: 'rgba(232, 239, 35, 1)',
    6: 'rgba(197, 237, 30, 1)',
    7: 'rgba(162, 235, 24, 1)',
    8: 'rgba(100, 224, 34, 1)',
    9: 'rgba(28, 209, 47, 1)'
  },
  Categories: {
    TeamComposition: UIColors.Complementary.Sunset1,
    ProblemVision: UIColors.Complementary.Sunset2,
    ValueProposition: UIColors.Complementary.Sunset3,
    Solution: UIColors.Complementary.Sunset4,
    FinancialModel: UIColors.Complementary.Clove,
    Market: UIColors.Complementary.Lavender,
    Sustainability: UIColors.Complementary.Sprout,
    Operations: UIColors.Complementary.Ice1,
    Sales: UIColors.Complementary.Ice2,
    RiskLegal: UIColors.Complementary.Ice3,
    BUCommitment: UIColors.Complementary.Mint1,
    ValueChain: UIColors.Complementary.Mint2,
  }
}

export const MATURITY_CATEGORY_COLORS = [
  ScaleColors.Categories.TeamComposition,
  ScaleColors.Categories.ProblemVision,
  ScaleColors.Categories.ValueProposition,
  ScaleColors.Categories.Solution,
  ScaleColors.Categories.FinancialModel,
  ScaleColors.Categories.Market,
  ScaleColors.Categories.Sustainability,
  ScaleColors.Categories.Operations,
  ScaleColors.Categories.Sales,
  ScaleColors.Categories.RiskLegal,
  ScaleColors.Categories.BUCommitment,
  ScaleColors.Categories.ValueChain,
];
export const MATURITY_CATEGORY_COLORS_RESOLVED = MATURITY_CATEGORY_COLORS.map(color => chroma.mix(ScaleColors.Maturity.Resolved.Dark, color).hex());

// const SET_COLORS = ['#636EFA', '#EF553B', '#00CC96', '#AB63FA', '#FFA15A', '#19D3F3', '#FF6692', '#B6E880', '#FF97FF', '#FECB52'];
// D3 colors

export const SET_COLORS = [
  // --- Set 1: (Balanced and Distinct - Good Starting Point) ---
  'rgba(31, 119, 180, 1)',  // '#1f77b4' - Medium Blue
  'rgba(244, 95, 116, 1)',  // '#f45f74' - Desaturated Red
  'rgba(152, 193, 39, 1)',  // '#98c127' - Green
  'rgba(255, 178, 85, 1)',  // '#ffb255' - Light Orange
  'rgba(148, 103, 189, 1)', // '#9467bd' - Muted Purple
  'rgba(143, 215, 215, 1)', // '#8fd7d7' - Light desaturated blue

  // --- Set 2: ---
  'rgba(214, 39, 40, 1)',   // '#d62728' - Strong Red
  'rgba(71, 192, 232, 1)',  // '#47c0e8' - Light, vibrant Blue
  'rgba(188, 189, 34, 1)',  // '#bcbd22' - Yellow-Green (olive)
  'rgba(239, 88, 111, 1)',  // '#ef586f' - Medium Pink-Red
  'rgba(39, 103, 142, 1)',  // '#27678e' - Desaturated Blue
  'rgba(147, 229, 103, 1)', // '#93e567' - Light Yellow-Green

  // --- Set 3: ---
  'rgba(44, 160, 44, 1)',   // '#2ca02c' - Strong Green
  'rgba(227, 119, 194, 1)', // '#e377c2' - Pink-Purple
  'rgba(255, 127, 14, 1)',  // '#ff7f0e' - Bright Orange
  'rgba(27, 89, 183, 1)',   // '#1b59b7' -  Blue
  'rgba(247, 27, 159, 1)',  // '#f71b9f' - Hot Pink
  'rgba(202, 226, 81, 1)',  // '#cae251' - Lime Green

  // --- Set 4: ---
  'rgba(23, 190, 207, 1)',  // '#17becf' - Bright Cyan
  'rgba(216, 78, 117, 1)',  // '#d84e75' - Red-Pink
  'rgba(61, 193, 46, 1)',   // '#3dc12e' - Bright Green
  'rgba(98, 35, 175, 1)',   // '#6223af' - Deep Purple
  'rgba(229, 103, 13, 1)',  // '#e5670d' - Orange
  'rgba(53, 221, 72, 1)',   // '#35dd48' - Bright, slightly cooler Green

  // --- Set 5: ---
  'rgba(28, 105, 188, 1)',  // '#1c69bc' - Slightly darker Blue
  'rgba(249, 92, 126, 1)',  // '#f95c7e' - Salmon Pink
  'rgba(172, 206, 35, 1)',  // '#acce23' - Yellow-Green
  'rgba(191, 13, 164, 1)',  // '#bf0da4' - Magenta
  'rgba(236, 247, 37, 1)',  // '#ecf725' - Bright Yellow
  'rgba(3, 163, 115, 1)',   // '#03a373' - Teal-Green

  // --- Set 6: ---
  'rgba(237, 68, 133, 1)',  // '#ed4485' -  Red-Pink
  'rgba(139, 249, 92, 1)',  // '#8bf95c' - Bright, light Green
  'rgba(79, 242, 236, 1)',  // '#4ff2ec' - Bright Cyan
  'rgba(255, 205, 142, 1)', // '#ffcd8e' - Light Orange
  'rgba(163, 10, 252, 1)',  // '#a30afc' - Bright Purple
  'rgba(49, 161, 165, 1)',  // '#31a1a5' - Teal

  // --- Set 7: ---
  'rgba(59, 74, 206, 1)',   // '#3b4ace' - Blue-Violet
  'rgba(255, 140, 161, 1)', // '#ff8ca1' - Light Pink
  'rgba(189, 211, 115, 1)', // '#bdd373' - muted green
  'rgba(78, 70, 168, 1)',   // '#4e46a8' - Muted Purple-Blue
  'rgba(181, 133, 38, 1)',  // '#b58526' - Brownish-Yellow
  'rgba(9, 133, 150, 1)',   // '#098596' - Teal

  // --- Set 8: ---
  'rgba(52, 170, 39, 1)',   //'#34aa27'
  'rgba(29, 188, 1, 1)',    //'#1dbc01'
  'rgba(76, 124, 4, 1)',   //'#4c7c04'
  'rgba(61, 33, 242, 1)',   //'#3d21f2'
  'rgba(4, 59, 117, 1)',    //'#043b75'
  'rgba(140, 86, 75, 1)',   // '#8c564b'
  'rgba(226, 74, 125, 1)',  //'#e24a7d'
  'rgba(221, 13, 34, 1)',   // '#dd0d22' - Strong Red
  'rgba(86, 10, 173, 1)',   // '#560aad' -  purple
  'rgba(127, 127, 127, 1)' // '#7f7f7f' - Gray
];

export const RedGreenScale = chroma.scale(['red', 'yellow', 'green']);

export const YellowGreenScale = chroma.scale(["yellow", '#CAE950', '#3BE256'])

export const BlueYellowGreenScale = chroma.scale(["#92C0FF", '#98DDEA', '#ACEFCE', '#CBEF77', '#E8EF23', '#C5ED1E', '#A2EB18', '#64E022', "#1CD12F"]);

//export const YellowGreenScale = chroma.scale(["#676AE3", '#E066E1', '#CAE950', '#3BE256', '#3BE256'])

export const maturityScoreColorScale = (value: number) => {
  return BlueYellowGreenScale(value).hex();
}

export const BlueGrayGreenScale = chroma.scale([ScaleColors.Maturity[1], UIColors.Interface.PureWhite, ScaleColors.Maturity[9]])

export const blueGrayGreenScaleColor = (value: number): string => {
  return BlueGrayGreenScale(value).hex();
}

export const GreenScale = chroma.scale([UIColors.Interface.LightGreen, ScaleColors.Maturity[9]])
export const BlueScale = chroma.scale([UIColors.Interface.LightBlue, ScaleColors.Maturity[1]])


export const redGreenScaleBackgroundColor = (value: number) => {
  return RedGreenScale(value).hex();
}

export const redGreenScaleTextColor = (value: number) => {
  return RedGreenScale(value).luminance() < 0.5 ? 'white' : 'black';
}

/**
 * Returns a color that can be used on a given background color so that it is visible.
 * @param backgroundColor on which the text is written
 * @returns 
 */
export function getTextColorForBackground(backgroundColor: string) {
  const luminance = chroma(backgroundColor).luminance();
  return luminance < 0.35 ? 'white' : 'black';
}


/**
 * Gives a fixed color for a name from our palette (unless the palette changes)
 * @param name 
 */
export function nameToColor(name: string): string {
  return SET_COLORS[stringToHash(name) % SET_COLORS.length]
}