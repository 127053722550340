import { useGetList, usePermissions } from 'react-admin';
import { ApiScoreByDay, Business, BusinessHistory, MaturityScoreOnly } from '../model/ScaleTypes';
import useMaturityModel from './useMaturityModel';
import { groupByReduce } from '../util/ScaleUtils';
import { buildBusinessHistories } from './useBusiness';
import { useEffect, useState } from 'react';

export default function useBusinessHistoryScores(businessId: string | undefined | null): { historyScoreData: MaturityScoreOnly[], isHistoryScoresLoading: boolean, historyScoreError: Error | null } {
  const { data: historyScoreData, isLoading: isHistoryScoresLoading, error: historyScoreError } = useGetList(
    'api_scoreByDayAndCategory',
    {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'day', order: 'DESC' },
      filter: { businessId }
    });
  return { historyScoreData: historyScoreData as MaturityScoreOnly[], isHistoryScoresLoading, historyScoreError };
}

/**
 * 
 * @returns businessHistory as sorted arrays by business Id
 */
export function useBusinessHistoryDataById() {
  const { permissions, isLoading: permissionLoading } = usePermissions();
  const { maturityModel, loading: maturityModelLoading } = useMaturityModel();

  // Determine the filter based on permissions
  const businessFilter = {
    "deleted@_neq": true,
    "businessId@_in": permissions?.isLimitedAccess ? permissions.allowedBusinessIds : undefined,
  };

  // Conditionally apply the filter only if permissions are loaded
  const { data: businessHistories, isLoading: businessHistoriesLoading } = useGetList<Business>(
    'api_history_business', {
    sort: { field: 'modifiedAt', order: 'DESC' },
    pagination: { page: 1, perPage: 10000 },
    filter: permissionLoading ? { "businessId@_nin": ["-1"] } : businessFilter, // if permission is loading, filter out everything
  }
  );

  // Only fetch scoresByDay if permissions are loaded.
  const { data: scoresByDay, isLoading: scoresByDayLoading } = useGetList<ApiScoreByDay>(
    'api_averageScoreByDay', {
    sort: { field: 'periodDate', order: 'DESC' },
    pagination: { page: 1, perPage: 10000 },
  }, { enabled: !permissionLoading } // Only enable this hook if permissions are loaded
  );

  const [businessHistoryDataById, setBusinessHistoryDataById] = useState<{ [key: string]: BusinessHistory[] } | null>(null);
  useEffect(() => {
    if (!maturityModel || !businessHistories || !scoresByDay) return; // Don't calculate until data is loaded

    const scoreArrayById = groupByReduce(scoresByDay, 'businessId');
    const businessArrayById = groupByReduce(businessHistories, 'businessId');

    // Construct BusinessHistory objects
    const newBusinessHistoryDataById: { [key: string]: BusinessHistory[] } = {}
    const businessIds = Object.keys(businessArrayById);
    for (const id of businessIds) {
      const histories = businessArrayById[id];
      const scores = scoreArrayById[id] ? scoreArrayById[id].slice() : [];
      newBusinessHistoryDataById[id] = buildBusinessHistories(histories, scores, maturityModel);
    }

    setBusinessHistoryDataById(newBusinessHistoryDataById);
  }, [maturityModel, businessHistories, scoresByDay]);

  // If permissions are loading, return early with null data and loading state.
  if (permissionLoading) {
    return { businessHistoryDataById: null, isLoading: true };
  }

  return { businessHistoryDataById, isLoading: maturityModelLoading || businessHistoriesLoading || scoresByDayLoading || businessHistoryDataById === null };

}
