import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField as MuiTextField,
  IconButton,
  Button,
} from '@mui/material';
import { useNotify } from 'react-admin';
import CancelIcon from '@mui/icons-material/Cancel';
import ScaleIcons, { ScaleSvg } from '../Icons';
import { ButtonVariant } from '../../layout/theme';

type ConfirmationLevel = 'none' | 'dialog' | 'input';

type ButtonType = 'text' | 'icon';

interface DeleteItemButtonProps<T> {
  label: string;
  disabled?: boolean;
  itemName?: string;
  itemType: string;
  variant?: ButtonVariant;
  onConfirmDelete: () => void;
  confirmationLevel: ConfirmationLevel;
  confirmationString?: string;
  buttonType?: ButtonType;
  icon?: React.ReactNode;
  tooltip?: string;
}

const DeleteItemButton = <T,>({
  label,
  disabled = true,
  itemName,
  itemType,
  onConfirmDelete,
  confirmationLevel,
  confirmationString,
  buttonType = 'text',
  variant = 'contained',
  icon,
  tooltip,
}: DeleteItemButtonProps<T>) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [inputConfirmation, setInputConfirmation] = useState<string>('');
  const notify = useNotify();

  const showDialog = () => setShowConfirmDialog(true);

  const closeDialog = () => {
    setShowConfirmDialog(false);
    setInputConfirmation('');
  };

  const confirmationMatches = () => {
    if (confirmationLevel === 'input') {
      const expectedString = confirmationString || itemName;
      return inputConfirmation.trim().toLowerCase() === expectedString?.trim().toLowerCase();
    }
    return true;
  };

  const handleDelete = () => {
    if (confirmationMatches()) {
      closeDialog();
      onConfirmDelete();
    } else {
      notify(`warnings.delete.${itemType}_name_not_matching`, { type: 'warning' });
    }
  };

  const renderButton = () => {
    const deleteIcon = icon || <ScaleIcons.Trash />;

    if (buttonType === 'icon') {
      return (
        <IconButton size="small" disabled={disabled} title={tooltip} onClick={confirmationLevel === 'none' ? onConfirmDelete : showDialog}>{deleteIcon}</IconButton>
      );
    } else { // buttonType === 'text'
      if (confirmationLevel === 'none') {
        return (
          <Button
            variant={variant}
            color='secondary'
            disabled={disabled}
            startIcon={ScaleSvg.Trash}
            onClick={onConfirmDelete}
          >{label}</Button>
        );
      }
      return (
        <Button
          variant={variant}
          color="secondary"
          disabled={disabled}
          startIcon={ScaleSvg.Trash}
          onClick={showDialog}
        >
          {label}
        </Button>
      );
    }
  };

  return (
    <div>
      {renderButton()}
      <Dialog open={showConfirmDialog} onClose={closeDialog}>
        <DialogTitle>Delete {itemType} {itemName ? `"${itemName}"` : ''}?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmationLevel === 'input' ? (
              <>
                To confirm the deletion of this {itemType}, please type the {itemType}
                {confirmationString ? `"${confirmationString}"` : itemName ? ` name ("${itemName}")` : ''} below.
              </>
            ) : (
              `Are you sure you want to delete this ${itemType}${itemName ? ` "${itemName}"` : ''}?`
            )}
          </DialogContentText>
          {confirmationLevel === 'input' && (
            <MuiTextField
              autoFocus
              margin='dense'
              label={`${itemType} Name`}
              fullWidth
              value={inputConfirmation}
              onChange={(e) => setInputConfirmation(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            startIcon={<CancelIcon />}
            onClick={closeDialog}
            sx={{ marginRight: '8px' }}
          >No, Cancel</Button>
          <Button
            variant='contained'
            startIcon={ScaleSvg.Trash}
            disabled={confirmationLevel === 'dialog' ? false : !confirmationMatches()}
            onClick={handleDelete}
            sx={{
              color: '#ffffff',
              backgroundColor: '#ff5f6d',
              marginRight: '16px',
              ':hover': {
                opacity: 0.8,
                color: '#ffffff',
                backgroundColor: '#ff5f6d',
              }
            }}
          >Yes, Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteItemButton;