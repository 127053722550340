import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import { BusinessExtended } from '../model/ScaleTypes';
import { EarlyInsightsChart } from '../components/charts/EarlyInsightsChart';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { useGetList, useRedirect, useTranslate } from 'react-admin';
import InfoIconTooltip from '../components/InfoIconTooltip';
import { Grid } from '@mui/material';
import { useScalePermissions, withIdentityChecks } from '../components/withIdentityChecks';
import useMaturityModel from '../state/useMaturityModel';

const EarlyInsightsView = (props: any) => {

  document.title = "Scale SaaS - Foresight (beta)"

  const redirect = useRedirect();
  const translate = useTranslate();
  const { permissions, isLoading } = useScalePermissions();
  const { maturityModel, loading: mmLoading } = useMaturityModel();

  const { data, isLoading: loading } = useGetList(
    'api_business', {
    pagination: { page: 1, perPage: 10000 },
    filter: {
      "deleted@_neq": true,
      "businessId@_in": permissions.isLimitedAccess ? permissions.allowedBusinessIds : undefined
    }
  }
  );//, { field: "scoreModifiedAt", order: 'desc'});

  if (loading || mmLoading || isLoading) {
    return <LoadingAnimation loadingText='Loading foresights' />
  }

  // @TODO Write a helper to handle flatten the aggregates automatically?
  // @TODO Push the MaturityModel and Organization to be available everywhere?
  if (!maturityModel) return <p>Failure loading maturity model</p>

  //@ts-ignore
  const dataInArray: BusinessExtended[] = data!
    .filter(project => project.status !== "cancelled" && project.deleted !== true && project.businessPotential !== undefined)

  var onClick = function (series: any, dataPoint: BusinessExtended) {
    if (dataPoint == null) return;
    var closestID = dataPoint.businessId;
    redirect("show", "api_business", closestID);
    return;
  };

  //@ts-ignore
  return (
    <div className="scaleDashboard">
      <div className="dashboardContainer">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Card className="bubbleChart" >
              <CardHeader title="Foresight" action={<InfoIconTooltip title={translate('pos.definitions.portfolioBubbleChart')} />} />
              <CardContent><Typography>Total of {dataInArray.length} Cases in the portfolio. You can zoom the picture and double click on a bubble to open it.</Typography></CardContent>
              <EarlyInsightsChart maturityModel={maturityModel} seriesProperty={"organizationUnitName"} /*compareMonthsAgo={6}*/ data={dataInArray} onClickDataPoint={onClick} />
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withIdentityChecks(EarlyInsightsView);