import { Typography, IconButton } from '@mui/material';
import { MaturityCategory, MaturityLevel, MaturityMilestone, MaturityModel, MaturityScoreOnly, Plan } from '../../model/ScaleTypes';
import { useNavigate } from 'react-router-dom';
import SortableTable, { Column } from '../common/SortableTable';
import { groupByReduce } from '../../util/ScaleUtils';
import dayjs from '../../configuration/configuredDayjs';
import { UIColors } from '../Colors';
import { ScaleSvg } from '../Icons';
import { useState } from 'react';
import { MilestoneChip } from './MilestoneChip';

export function createDisplayNameFromPlanSelectionValue(selectionValue: string, maturityModel: MaturityModel): MaturityLevel | MaturityMilestone | null {
  const delimPosition = selectionValue.indexOf('-');
  const value = Number(selectionValue.substring(0, delimPosition));
  const type = selectionValue.substring(delimPosition + 1)

  var selectionObject: MaturityLevel | MaturityMilestone | undefined;
  switch (type) {
    case 'milestone':
      selectionObject = maturityModel?.milestones?.find(milestone => milestone.level === value);
      break;
    case 'level':
      selectionObject = maturityModel?.levels?.find(level => level.level === value);
      break;
  }
  if (selectionObject) return selectionObject;
  return null;
}

const columnStyle = {
  maxWidth: "180px",
  minWidth: "50px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}

const rowFormatter = (item: Plan): React.CSSProperties => { return { background: UIColors.Interface.DimmedWhite } }

export type MilestoneTableProps = {
  maturityModel: MaturityModel,
  maturityScoreHistory: MaturityScoreOnly[],
  plans?: Plan[],
  onEditClick?: (plan: Plan) => void
}

export const MilestoneTable = ({
  maturityModel,
  maturityScoreHistory,
  plans,
  onEditClick = undefined,
}: MilestoneTableProps) => {
  const navigate = useNavigate()
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  };

  const handleCancel = async () => {
    handleCloseDialog()
  }

  const lookupById = groupByReduce(maturityScoreHistory, 'maturityCategoryId')

  // this is a kind of a hack, trying to somehow pass what to do with edit button press in.
  function openPlan(plan: Plan) { navigate(`/scale_plan/${plan.id}`) }
  onEditClick = onEditClick || openPlan;
  if (!onEditClick) return <></>;

  // @TODO sort the data!
  const categories: MaturityCategory[] = maturityModel.categories
    .filter(cat => lookupById[cat.id] && lookupById[cat.id].find(val => val.score));
  //  if (categories.length === 0) return <Alert severity="warning">{translate('errors.assessment_missing')}</Alert>;
  const categoryScores: (number | undefined)[] = categories.map(cat => lookupById[cat.id] ? lookupById[cat.id][0]?.score : undefined);
  const columns: Column<Plan>[] = [
    { id: 'name', header: 'Name', style: columnStyle, format: (_, item) => (<Typography fontWeight={700}>{item.name}</Typography>) },
    { id: 'targetDate', header: 'Target date', style: columnStyle },
    {
      id: 'targetSelection',
      header: 'Target', style: columnStyle,
      format: (value: string, plan) => <MilestoneChip item={createDisplayNameFromPlanSelectionValue(value, maturityModel)} />
    },
    {
      id: 'businessId',
      header: 'Progress',
      style: columnStyle,
      format: (_, plan) => {
        if (plan.targetLevel === null) return "N/A";
        if (dayjs().isBefore(plan.startDate)) return `Not started`;// in ${-dayjs().diff(dayjs(plan.startDate), "month")}`
        const startLevel: number = plan.startLevel || 0;
        const levelDiff = plan.targetLevel - startLevel;
        const totalLevelsToComplete = (categoryScores.length * levelDiff);

        var levelsCompleted = 0;
        // the levels which are even below the start level are considered to be at start level.
        // the levels which are above the target level are considered to be at the target level.
        categoryScores.forEach(currentScore => {
          levelsCompleted += Math.min(Math.max(currentScore || 0, startLevel), plan.targetLevel || 0) - startLevel
        });
        return levelsCompleted + "/" + totalLevelsToComplete + (levelsCompleted > 0 && levelsCompleted === totalLevelsToComplete ? " 🎉" : "");
      }
    },
    {
      id: 'id', header: '', style: { ...columnStyle, width: '30px' },
      format: (_, plan) => (
        <IconButton disabled={!onEditClick} onClick={() => onEditClick && onEditClick(plan)}>{ScaleSvg.Pencil}</IconButton>
      )
    }
  ];

  return plans
    ? <SortableTable
      columns={columns}
      data={plans}
      defaultSortColumn={columns[1]}
      rowFormatter={rowFormatter}
      initialRowsPerPage={5}
    />
    : null
}

