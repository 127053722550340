import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import React, { useState, ReactNode } from 'react';
import { useTranslate } from 'react-admin';
import { UIColors } from '../Colors';
import dayjs from '../../configuration/configuredDayjs';

function getSorting<T>(order: 'asc' | 'desc', orderBy: Column<T>): (a: T, b: T) => number {
  const direction = order === 'asc' ? 1 : -1;
  if (orderBy.sortValue) {
    return (a, b) => {
      //@ts-ignore
      return direction * compare(orderBy.sortValue(a[orderBy.id], a), orderBy.sortValue(b[orderBy.id], b))
    }
    //@ts-ignore
  } else return (a, b) => direction * compare(a[orderBy.id], b[orderBy.id])
}

function compare(a: any, b: any): number {
  if (a === null || a === undefined) return -1;
  if (b === null || b === undefined) return 1;

  // Check if both are valid date strings using dayjs
  const dateA = dayjs(a);
  const dateB = dayjs(b);

  if (dateA.isValid() && dateB.isValid()) {
    return dateA.valueOf() - dateB.valueOf();
  }

  // Handle numeric comparison
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  // Handle string comparison
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }

  // Final fallback for different data types
  return 0;
}


export type Column<T> = {
  id: keyof T | string;
  header: string | ReactNode;
  headerStyle?: React.CSSProperties;
  style?: React.CSSProperties;

  /**
   * Formatter used to render the content of the table cell
   * @param value the specific item that is the value in that specific row
   * @param rowObject the object that represents the row, from where the value is gotten
   * @returns ReactNode that represents the rendered value
   */
  format?: (value: any, rowObject: T) => ReactNode;
  sortValue?: (value: any, rowObject: T) => any;
  disableSorting?: boolean
  disableEvents?: boolean
  width?: string;
};

type MuiTablePaginationProps = Pick<TablePaginationProps, 'labelRowsPerPage' | 'sx'>

type SortableTableProps<T> = MuiTablePaginationProps & {
  columns: Column<T>[];
  data: T[];
  defaultSortColumn?: Column<T>;
  defaultSortDirection?: 'asc' | 'desc';
  initialRowsPerPage?: number;
  headerRowStyle?: React.CSSProperties,
  /**
   * Formatter used to give style each row for the table.
   * @param rowObject the data object for the row
   * @param rowIndex index of the row in the table
   * @returns CSS styles for the row
   */
  rowFormatter?: (rowObject: T, rowIndex?: number) => React.CSSProperties;
  onRowClick?: (rowObject: T, rowIndex?: number) => void;
  disablePageSizeChange?: boolean;
  disableHeader?: boolean;
};

function SortableTable<T>({
  columns,
  data,
  defaultSortColumn,
  defaultSortDirection,
  headerRowStyle = {},
  rowFormatter,
  initialRowsPerPage = undefined,
  labelRowsPerPage,
  disablePageSizeChange = true,
  disableHeader = false,
  onRowClick,
  sx,
}: SortableTableProps<T> & { rowsPerPage?: number }) {
  const translate = useTranslate();
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>(defaultSortDirection || 'desc');
  const [orderBy, setOrderBy] = useState<Column<T> | null>(defaultSortColumn || null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const handleSortRequest = (column: Column<T>) => {
    const isAsc = orderBy === column && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = orderBy
    ? [...(data || [])].sort(getSorting(orderDirection, orderBy))
    : data;

  const paginatedData = rowsPerPage ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedData;

  const makeColumnHeader = (column: Column<T>) => {
    if (column.disableSorting) {
      return typeof column.header === 'string'
        ? translate(column.header, { _: column.header }) : column.header;
    }

    return (
      <TableSortLabel
        hideSortIcon={true}
        disabled={!!column.disableSorting}
        active={orderBy?.id === column.id}
        direction={orderBy?.id === column.id ? orderDirection : 'asc'}
        onClick={() => handleSortRequest(column)}
      >
        {typeof column.header === 'string' ? translate(column.header, { _: column.header }) : column.header}
      </TableSortLabel>
    );
  };

  return (
    <Table size="small" sx={sx}>
      {!disableHeader &&
        <TableHead>
          <TableRow style={headerRowStyle}>
            {columns.map(column => (
              <TableCell key={column.id as string} style={column.headerStyle}>
                {makeColumnHeader(column)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      }
      <TableBody>
        {paginatedData.map((row, index) => (
          <TableRow onClick={() => { onRowClick && onRowClick(row, index) }} key={index} sx={(theme) => ({
            ...rowFormatter ? rowFormatter(row, index) : undefined,
            backgroundColor: UIColors.Interface.DimmedWhite,
            ...(onRowClick ? {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: UIColors.Interface.DetailGrey,
              },
            } : {}),
          })}>
            {columns.map(column => (
              <TableCell onClick={(event) => column.disableEvents && event.stopPropagation()} key={column.id as string} width={column.width} style={column.style}>
                {/* @ts-ignore */}
                {column.format ? column.format(row[column.id], row) : row[column.id]}
              </TableCell>
            ))}
          </TableRow>
        ))}
        {paginatedData.length === 0 && (
          <TableRow key={0}>
            <TableCell style={{ textAlign: 'center', fontStyle: 'italic', backgroundColor: UIColors.Interface.DetailGrey }} colSpan={columns.length}>
              No data to show
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      {rowsPerPage && <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={disablePageSizeChange ? [] : [5, 10, 25]} // Can customize as needed
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={labelRowsPerPage}
          />
        </TableRow>
      </TableFooter>}
    </Table>
  );
}

export default SortableTable;