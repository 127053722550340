import React, { useState, useMemo } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import { useTranslate } from 'react-admin';
import { Business, BusinessHistory, MaturityModel, Tag } from '../../model/ScaleTypes';
import { PortfolioBubbleChart, PortfolioBubbleLegend } from '../charts/PortfolioBubbleChart';
import InfoIconTooltip from '../InfoIconTooltip';
import VariantBox from '../VariantBox';
import { InputLabel, MenuItem, Select, SelectChangeEvent, OutlinedInput, Stack } from '@mui/material';
import { ScaleTypography } from '../Styles';
import { Predicate, MultiSelect, TruePredicate } from '../common/MultiSelect';


interface PortfolioBubbleCardProps {
  businessData: BusinessHistory[],
  comparisonBusinessData?: BusinessHistory[],
  maturityModel: MaturityModel,
  onClick: (series: any, dataPoint: BusinessHistory) => void
}

export type FilterState<T> = {
  filter: Predicate<T>
}

export const PortfolioBubbleCard = (props: PortfolioBubbleCardProps) => {
  const { businessData, comparisonBusinessData, maturityModel, onClick } = props;

  const [bubbleColorProperty, setBubbleColorProperty] = useState<keyof Business>("name");

  // Filter state needs to be separate object, cannot store the predicate directly state, as it will be evaluated
  const [filterState, setFilterState] = useState<FilterState<BusinessHistory>>({ filter: TruePredicate })
  const translate = useTranslate();

  const shownData = useMemo(() => {
    const filtered = businessData.filter(filterState.filter);
    return filtered;
  }, [businessData, filterState]);

  const comparisonData = useMemo(() => {
    if (!comparisonBusinessData) { return undefined; }
    return comparisonBusinessData.filter(filterState.filter);
  }, [comparisonBusinessData, filterState]);

  const projectsMissingBusinessCases = useMemo(() => {
    return shownData.filter(item => Math.max(item.businessPotential, 0) + Math.max(item.budget, 0) === 0);
  }, [shownData]);

  const handleColorChange = (event: SelectChangeEvent<string>) => {
    setBubbleColorProperty(event.target.value as keyof Business);
  };

  return (
    <Card className="bubbleChart">
      <CardHeader title="Portfolio View" action={<InfoIconTooltip title={translate('pos.definitions.portfolioBubbleChart')} />} />
      <CardContent>
        <VariantBox sx={{ display: 'flex', flexFlow: 'row' }}>
          <VariantBox sx={{ display: 'flex', flexFlow: 'column', width: '80%', gap: 1.5 }}>
            <PortfolioBubbleChart maturityModel={maturityModel}
              seriesProperty={bubbleColorProperty as keyof BusinessHistory}
              data={shownData}
              comparisonData={comparisonData}
              onClickDataPoint={onClick} width='100%'
              height='300px' />
            <PortfolioBubbleLegend data={shownData} seriesProperty={bubbleColorProperty} />
          </VariantBox>

          <VariantBox sx={{ display: 'flex', flexFlow: 'column' }}>
            <MultiSelect
              onChangeFilter={(filter) => setFilterState({ filter })}
              items={businessData}
              label="Projects"
              renderItem={(item) => ({ display: item.name, id: item.businessId })} />

            <FormControl sx={{ m: 1, minWidth: 256 }} variant='outlined'>
              <InputLabel id="demo-select-small-label">Bubble color</InputLabel>
              <Select
                value={bubbleColorProperty}
                label="Bubble color"
                defaultValue={"name"}
                onChange={handleColorChange}
                input={<OutlinedInput id="demo-select-small-label" label="Bubble color" />}
              >
                <MenuItem value="" disabled>Select Bubble Color</MenuItem>
                <MenuItem value="name">Project</MenuItem>
                <MenuItem value="organizationUnitName">Organization</MenuItem>
                <MenuItem value="status">Status</MenuItem>
              </Select>
            </FormControl>

            {projectsMissingBusinessCases.length > 0 &&
              <Stack direction="row" spacing={1} padding={1} margin={1}>
                <Typography sx={ScaleTypography.Bold.Big}>{projectsMissingBusinessCases.length}</Typography> <Typography sx={ScaleTypography.Body.Big}>missing business cases</Typography>
              </Stack>}
            <PortfolioBubbleLegend data={projectsMissingBusinessCases} seriesProperty={bubbleColorProperty} flexDirection='column' alignItems='left' padding={1} />
          </VariantBox>
        </VariantBox>
      </CardContent>
    </Card>);
}