import { useGetList } from "react-admin";
import { Tag } from "../model/ScaleTypes";
import { MultiSelect, Predicate } from "./common/MultiSelect";
import VariantBox from "./VariantBox";
import ScaleIcons from "./Icons";
import { Typography } from "@mui/material";
import { nameToColor } from "./Colors";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

/**
 * 
 * @param callback to tell when the filter has changed with parameter as the new filtering element
 * @returns 
 */
export const ThemeFilterComponent: React.FC<{ onChangeFilter: (filter: Predicate<Tag>) => void }> = ({ onChangeFilter }) => {
  const { data: tags, isLoading } = useGetList<Tag>("api_tagList", { sort: { field: 'name', order: 'ASC' }, });
  if (isLoading) return <></>
  return <VariantBox variant='row-nowrap'>
    <ScaleIcons.Adjust />
    <Typography variant='Label'>Filters: </Typography>
    <MultiSelect
      width={200}
      onChangeFilter={onChangeFilter}
      items={tags!} label="Themes" renderItem={(item) => ({
        display: <VariantBox variant='row-nowrap'>
          {item.name}
          <FiberManualRecordIcon fontSize="medium" sx={{ marginLeft: "-2px", marginRight: "-8px", color: nameToColor(item.name) }} />
        </VariantBox>,
        id: item.id
      })} />
  </VariantBox>
}