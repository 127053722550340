import { ResponseSchema, TextPart } from '@google/generative-ai';
import { z } from 'zod'
import { invokeLlm } from '../../integrations/GeminiAPI';
import { readSlidesFromPPTXFile, Slide } from '../../util/PPTUtils';
import { zodToGeminiSchema } from '../../util/zodUtils';
import { readSlidesFromPDFFile } from '../pdf/PDFUtils';
import { ScaleSvg } from '../Icons';
import FileDialog from '../project/FileDialog';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useGetList, useNotify } from 'react-admin';
import { Tag } from '../../model/ScaleTypes';

const ProjectSchema = z.object({
  name: z.string().describe("The name of the project."),
  tags: z.array(z.string()).nullable().optional().describe("Tags or themes that describe the project, selected from existing tags and themes."),
  description: z.string().describe("Description of the project, ie what the project is about and what is it's objective."),
  estimatedLaunchDate: z.string().datetime().nullable().optional().describe("The date and time of the estimated launch in ISO 8601 format (YYYY-MM-DDTHH:mm:ssZ)."),
  leadPersonEmail: z.string().email().nullable().optional().describe("The email of the person who is leading the project"),
  teamMemberNames: z.array(z.string()).optional().describe("Names of the key people working actively in the poject."),
  horizon: z.number().int().positive().nullable().optional().describe("Horizon of the project: 1) Maintain and defend core business, 2) Nurture emerging business, 3) Create genuinely new - possibly disruptive - business"),
  budget: z.number().int().positive().nullable().optional().describe("The budget of the project."),
  businessPotential: z.number().int().positive().nullable().optional().describe("The business potential of the project."),
}).describe("Represents details about a new business innovation project.");

export type ProjectExtract = z.infer<typeof ProjectSchema>

export async function extractProjectInfo(slides: Slide[], possibleTags: Tag[]) {

  const textPart: TextPart = {
    text: "Slides or Pages in json format that contain the relevant information:\n\n" + JSON.stringify(slides, undefined, 2)
  }

  const zodSchema = z.array(ProjectSchema);
  const modelParams = {
    model: 'gemini-2.0-flash',
    generationConfig: {
      responseMimeType: 'application/json',
      responseSchema: zodToGeminiSchema(zodSchema) as ResponseSchema,
    },
  }

  const result = await invokeLlm(
    {
      modelParams,
      generativeParts: [
        textPart,
        { text: `Please ensure that if you propose tags or themes, that they are from this list: ${possibleTags.map(tag => tag.id).join(",")}` },
        {
          text: `Please extract key project information from the above Slides or Pages.
  
Project name:
Project description & Objective:
Business Potential (ie.Total Addressable market, SAM, TAM or similar if exists)
Lead person name and email:  
Key Project team members(active doers in the project) and their roles: ` }
      ]
    }
  );

  console.log(result, result.responseText);
  try {
    const parsedResult = zodSchema.parse(JSON.parse(result.responseText));
    console.log(parsedResult);
    return parsedResult[0];
  } catch (error) {
    console.error("Error parsing project information:", error);
    throw new Error("Failed to parse project information.");
  }
}

export const StartFromFile: React.FC<{ onProjectExtracted: (project: ProjectExtract, fromFile: File) => void }> = ({ onProjectExtracted }) => {
  const notify = useNotify();
  const [showFileUploadDialog, setShowFileUploadDialog] = useState<boolean>(false);
  const { data: tags, isLoading } = useGetList<Tag>("api_tagList", { sort: { field: 'name', order: 'ASC' }, });

  const openDialog = () => setShowFileUploadDialog(true);
  const closeDialog = () => setShowFileUploadDialog(false);

  const handleFiles = async (files: File[]) => {
    var slides: Slide[] = []
    console.log(files[0].type)
    if (files[0].type === 'application/pdf') {
      slides = await readSlidesFromPDFFile(files[0])
    } else if (files[0].type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
      slides = await readSlidesFromPPTXFile(files[0])
    }
    try {
      onProjectExtracted(await extractProjectInfo(slides, tags || []), files[0]);
    } catch (e) {
      console.error(e);
      notify("Problem extracting project information from file", { type: "error" });
    }
    closeDialog();
  };

  return <><Button sx={{ alignSelf: 'flex-end' }} disabled={isLoading} onClick={openDialog} startIcon={ScaleSvg.Generate} variant='QuartaryCTA'>
    Start with ext. file
  </Button>
    <FileDialog
      title="Select a file to start the project from"
      accept='.pdf,.pptx'
      acceptText='pdf and pptx'
      open={showFileUploadDialog}
      onClose={closeDialog}
      onFilesSelected={handleFiles} />
  </>
}
