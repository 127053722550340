import React, { useEffect, useRef, useState } from 'react';
import { GlobalWorkerOptions, getDocument, RenderTask } from 'pdfjs-dist';
import { Alert } from '@mui/material';

// The worker file has been copied to the public directory
GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;

interface PDFViewerProps {
  url: string;
  dpi?: number;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ url, dpi = 96 }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const isRenderingRef = useRef(false); // Track if a render is already in progress

  useEffect(() => {
    if (!url) return;

    const renderPDF = async () => {
      if (isRenderingRef.current) {
        console.log('Render already in progress, ignoring new request.');
        return; // Ignore if already rendering
      }
      console.log("starting render");
      setIsLoading(true); // Set loading state to true
      isRenderingRef.current = true;

      const canvas = canvasRef.current;
      if (canvas) {
        const context = canvas.getContext('2d');
        if (context) {
          context.clearRect(0, 0, canvas.width, canvas.height);

          try {
            const pdf = await getDocument(url).promise;
            const page = await pdf.getPage(1);
            const viewport = page.getViewport({ scale: 1.5 });

            const outputScale = dpi / 96.0;
            canvas.width = Math.floor(viewport.width * outputScale);
            canvas.height = Math.floor(viewport.height * outputScale);

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
              transform: [outputScale, 0, 0, outputScale, 0, 0],
            };

            const renderTask: RenderTask = page.render(renderContext);
            await renderTask.promise;
          } catch (error) {
            console.error('Error rendering PDF:', error);
            return <Alert severity="error">Error rendering PDF</Alert>;
          } finally {
            setIsLoading(false); // Set loading state to false
            isRenderingRef.current = false; // Reset rendering flag
          }
        }
      }
    };
    renderPDF();
  }, [url, dpi]);

  return (
    <>
      <canvas ref={canvasRef} />
      {isLoading && <div>Loading PDF...</div>}
    </>
  );
};

export default PDFViewer;
