import React from 'react';

import { Plan, defaultMaturityModel } from '../../model/ScaleTypes';
import { UsesBusinessHistoryDataProps, UsesMaturityModelProps } from './ScaleGraphTypes';
import { groupByReduce } from '../../util/ScaleUtils';
import { SET_COLORS, UIColors } from '../Colors';
import { addMonths, closestIndexTo, differenceInDays, max, min, subMonths } from '../../util/DayUtils';
import { useTranslate } from 'react-admin';
import { Alert } from '@mui/material';
import { addMaturityMilestoneToLayout, addPlanToLayout } from './MaturityChart';
import { Annotations, Layout, Margin, Shape } from 'plotly.js';
import { SCALE_STANDARD_FONT_SIZE } from '../../layout/theme';
import dayjs from '../../configuration/configuredDayjs';
import { PlotlyChart, PlotlyChartParams } from './PlotlyCharts';

export const STANDARD_MARGIN: Margin = {
  l: 50,
  r: 50,
  b: 100,
  t: 20,
  pad: 5
}

const PLAN_STYLE_PAST: Partial<Shape> = {
  fillcolor: 'rgba(0, 0, 0, 0.2)',
  line: { width: 0.5 },
  opacity: 0.4
};
export const PLAN_STYLE_ONGOING: Partial<Shape> = {
  line: {
    color: 'black',
    dash: 'solid',
    width: 2,
  },
  opacity: 0.4
};
const PLAN_STYLE_FUTURE: Partial<Shape> = {
  fillcolor: 'rgba(0, 0, 0, 0.2)',
  line: {
    color: 'black',
    dash: 'dot',
    width: 0.5,
  },
  opacity: 0.4
}

const MS_IN_DAY = 1000 * 60 * 60 * 24;

enum ForecastMode {
  NOT_SHOWN,
  SLOPE_ONLY,
  ALL,
}


export interface BusinessHistoryGraphProps extends UsesMaturityModelProps, UsesBusinessHistoryDataProps {
  width?: string;
  height?: string;
  /**
   * Show today
   */
  showToday?: boolean;

  /**
   * Show forecast trendline
   */
  showForecast?: boolean;

  /**
   * Number of months to use for the trendline velocity since the latest measurement
   */

  forecastWindowMonths?: number;

  /**
   * Months to show to the future
   */
  futureMonths?: number;

  /**
  * Estimated PMF date
  */
  estimatedLaunchDate?: Date;

  /**
   * Defines the quantile of the velocities to use for the max and min respectively.
   * 1.0 == uses the maximum number
   * 0.8 == uses 80%
   * 0.5 == uses the median (50%) 
   */
  minMaxQuantile?: number;

  countDaysFromStart: boolean;

  /**
   * Current Plans
   */
  plans?: Plan[],
}


export const createBusinessHistoryGraphData = ({
  maturityModel = defaultMaturityModel,
  data = [],
  plans,
  futureMonths = 6,
  forecastWindowMonths = 6,
  showForecast = true,
  showToday = true,
  estimatedLaunchDate,
  minMaxQuantile = 0.9,
  countDaysFromStart = false }: BusinessHistoryGraphProps): PlotlyChartParams => {

  const plotData: Plotly.Data[] = [];

  if (data.length === 0) {
    return {
      data: [],
      layout: {},
      error: 'business.assessment_missing'
    }
  }

  let startDate: Date = new Date();;
  let endDate: Date = new Date();
  data.forEach(history => {
    startDate = min([startDate, new Date(history.periodDate)]);
    endDate = (max([addMonths(new Date(), futureMonths), addMonths((history.estimatedLaunchDate ? new Date(history.estimatedLaunchDate) : new Date()), futureMonths)]));
  });

  const historyByBusiness = groupByReduce(data, "businessId");

  const forecastMode = showForecast ?
    Object.keys(historyByBusiness).length === 1 ? ForecastMode.ALL : ForecastMode.SLOPE_ONLY
    : ForecastMode.NOT_SHOWN;

  Object.keys(historyByBusiness).forEach((id, i) => {
    const businessData = historyByBusiness[id];
    // ensure sorted data
    businessData.sort((a, b) => new Date(a.periodDate).getTime() - new Date(b.periodDate).getTime());

    if (countDaysFromStart) {

      const possibleStartDates = businessData.map(history => new Date(history.periodDate));
      const latestInformation = businessData[businessData.length - 1];
      if (latestInformation.startDate) possibleStartDates.push(new Date(latestInformation.startDate));

      const startDate = min(possibleStartDates);

      const days: number[] = [];
      const scores: number[] = [];
      const texts: string[] = [];
      businessData.forEach(history => {
        texts.push(history.name);
        days.push(Math.trunc((new Date(history.periodDate).getTime() - new Date(startDate).getTime()) / MS_IN_DAY));
        scores.push(history.score);
      });

      const plots = createHistoryGraphFromZero(days, scores, texts, minMaxQuantile, forecastWindowMonths, forecastMode, endDate, SET_COLORS[i]);
      plotData.push(...plots);

    } else {
      const days: Date[] = [];
      const scores: number[] = [];
      const texts: string[] = [];
      businessData.forEach(history => {
        texts.push(history.name);
        days.push(new Date(history.periodDate));
        scores.push(history.score);
      });

      const plots = createHistoryGraph(days, scores, texts, minMaxQuantile, forecastWindowMonths, forecastMode, endDate, SET_COLORS[i]);
      plotData.push(...plots);
    }
  });

  const xAxisStart = startDate ? startDate : new Date();
  const xAxisEnd = endDate;

  const layout = createLayout(xAxisStart, xAxisEnd);
  layout.yaxis!.range = [0, maturityModel.maxLevel];

  if (showToday && !countDaysFromStart) {
    const annotation: Partial<Annotations> = {
      yref: 'paper',
      y: 1,
      yanchor: 'auto',
      // @ts-ignore
      x: new Date(),
      xanchor: 'right',
      text: '<b>Today<b>',
      font: {
        size: SCALE_STANDARD_FONT_SIZE * 0.8
      },
      showarrow: false,
      textangle: "90"
    };
    layout.annotations!.push(annotation);

    const todayLine: Partial<Shape> = {
      type: 'line',
      yref: 'paper',
      y0: 0,
      x0: new Date(),
      y1: 1,
      x1: new Date(),
      line: {
        color: UIColors.Text.Primary,
        width: 0.6,
        dash: 'solid'
      },
      opacity: 0.5
    };
    layout.shapes!.push(todayLine);
  }


  // Month 'Bars'
  const numbers = Array.from({ length: 50 }, (_, i) => i - 10);
  numbers.forEach((val, index) => {
    const rectShape: Partial<Shape> = {
      type: 'rect',
      xref: 'x',
      yref: 'paper',
      x0: dayjs(startDate).add(val, "month").startOf("month").add(1, 'days').toDate(),
      x1: dayjs(startDate).add(val, "month").endOf("month").subtract(1, 'days').toDate(),
      y0: 0,
      y1: 1,
      fillcolor: UIColors.Interface.DimmedWhite,
      line: {
        width: 0
      },
      layer: 'below'
    };
    layout.shapes!.push(rectShape);
  });


  if (estimatedLaunchDate && !countDaysFromStart) {
    const pmfAnnotation: Partial<Annotations> = {
      yref: 'paper',
      y: 1,
      yanchor: 'auto',
      // @ts-ignore
      x: estimatedLaunchDate,
      xanchor: 'right',
      text: 'estimated launch',
      showarrow: false,
      textangle: "90",
      font: {
        size: SCALE_STANDARD_FONT_SIZE * 0.8
      },
    };
    layout.annotations!.push(pmfAnnotation);

    const pmfLine: Partial<Shape> = {
      type: 'line',
      yref: 'paper',
      y0: 0,
      x0: estimatedLaunchDate,
      y1: 1,
      x1: estimatedLaunchDate,
      line: {
        color: 'rgb(0, 0, 0)',
        width: 1,
        dash: 'dot'
      }
    };
    layout.shapes!.push(pmfLine);
  }


  plans?.forEach(plan => {
    if (dayjs().isAfter(plan.targetDate)) addPlanToLayout(layout, plan, maturityModel, PLAN_STYLE_PAST);
    if (dayjs().isBetween(plan.startDate, plan.targetDate)) addPlanToLayout(layout, plan, maturityModel, PLAN_STYLE_ONGOING);
    if (dayjs().isBefore(plan.startDate)) addPlanToLayout(layout, plan, maturityModel, PLAN_STYLE_FUTURE);
  });
  const milestoneOpacity = plans && plans.length > 0 ? 0.2 : 0.7;
  maturityModel.milestones?.forEach(milestone => addMaturityMilestoneToLayout(layout, milestone, maturityModel, milestoneOpacity));

  const params: PlotlyChartParams = {
    data: plotData,
    layout
  }
  return params;
}


/**
 * Primary UI component for user interaction
 * @deprecated Use PlotlyChart 
 */
export const BusinessHistoryGraph: React.FC<BusinessHistoryGraphProps> = ({
  height,
  width,
  ...props
}) => {
  const translate = useTranslate();
  if (!props.data || props.data.length === 0) return <Alert severity='warning'>{translate('business.assessment_missing')}</Alert>
  const params = createBusinessHistoryGraphData(props)
  return <PlotlyChart height={height} width={width} params={params} />
};

function createLayout(startDate: Date | number, endDate: Date | number): Partial<Layout> {
  return {
    showlegend: false,
    margin: STANDARD_MARGIN,
    xaxis: {
      autotick: true,
      tickangle: 0,
      range: [startDate, endDate],
      ticklabelposition: 'outside'
    },
    yaxis: {
      automargin: false,
      autotick: false,
      showgrid: false,
      showline: false,
      zeroline: false
    },
    annotations: [],
    shapes: []
  }
};

function createHistoryGraphFromZero(days: number[], scores: number[], texts: string[], minMaxQuantile: number, forecastWindowMonths: number, forecastMode: ForecastMode, forecastEndDate: Date, color: string): Plotly.Data[] {

  const plotData: Plotly.Data[] = [];
  const historyData: Plotly.Data = {
    x: days,
    y: scores,
    text: texts,
    automargin: true,
    name: texts[texts.length - 1],
    type: 'scatter',
    mode: 'lines', // mode: 'lines+markers',
    line: {
      color
    },
    hovertemplate:
      '<br>%{text}' +
      '<br>Day: %{x}' +
      '<br>Score: %{y:,.2f}' +
      '<extra></extra>',
  }
  plotData.push(historyData);
  return plotData;
}



function createHistoryGraph(days: Date[], scores: number[], texts: string[], minMaxQuantile: number, forecastWindowMonths: number, forecastMode: ForecastMode, forecastEndDate: Date, color: string): Plotly.Data[] {
  const MAX_QUANTILE_TO_SHOW = minMaxQuantile;
  const MIN_QUANTILE_TO_SHOW = 1 - minMaxQuantile;

  const plotData: Plotly.Data[] = [];
  const historyPlot: Plotly.Data = {
    x: days,
    y: scores,
    text: texts,
    automargin: true,
    name: texts[texts.length - 1],
    type: 'scatter',
    mode: 'lines+markers',
    line: {
      color
    },
    hovertemplate:
      '<br>%{text}' +
      '<br>Date: %{x|%d-%b-%Y}' +
      '<br>Score: %{y:,.2f}' +
      '<extra></extra>',
  }
  plotData.push(historyPlot);

  if (forecastMode === ForecastMode.NOT_SHOWN || days.length === 0 || scores.length === 0) return plotData;

  const periodStartDate = subMonths(days[days.length - 1], forecastWindowMonths);
  var periodStartIndex = closestIndexTo(periodStartDate, days);
  if (!periodStartIndex) periodStartIndex = 0;

  const velocities = []
  for (var i = Math.max(periodStartIndex, 1); i < days.length; i++) {
    velocities.push((scores[i] - scores[i - 1]) / differenceInDays(days[i], days[i - 1]));
  }

  const firstMeasurementOfPeriod = {
    score: scores[periodStartIndex],
    day: days[periodStartIndex]
  }

  const lastMeasurementOfPeriod = {
    score: scores[scores.length - 1],
    day: days[days.length - 1]
  }

  //@ts-ignore
  const asc = arr => arr.sort((a, b) => a - b);

  const quantile = (arr: any, q: number) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
      return sorted[base];
    }
  };

  const daysFromEndtoForecastEnd = differenceInDays(forecastEndDate, lastMeasurementOfPeriod.day)

  if (forecastMode === ForecastMode.ALL) {
    const maxD: Plotly.Data = {
      name: 'forecast (max velocity)',
      x: [lastMeasurementOfPeriod.day, forecastEndDate],
      y: [lastMeasurementOfPeriod.score, Number(lastMeasurementOfPeriod.score) + Number(daysFromEndtoForecastEnd * quantile(velocities, MAX_QUANTILE_TO_SHOW))],
      type: 'scatter',
      hoverinfo: 'skip',
      mode: 'lines',
      line: {
        color: color, //'green',
        dash: 'dot',
        width: 0.5,
      }
    }
    plotData.push(maxD)
  }

  const periodDeltaScore: number = lastMeasurementOfPeriod.score - firstMeasurementOfPeriod.score;
  const periodDeltaDays: number = differenceInDays(lastMeasurementOfPeriod.day, firstMeasurementOfPeriod.day);
  const slope: number = velocities.length === 0 ? 0 : periodDeltaScore / periodDeltaDays;
  const forecastData: Plotly.Data = {
    name: 'forecast (avg velocity)',
    x: [lastMeasurementOfPeriod.day, forecastEndDate],
    y: [lastMeasurementOfPeriod.score, Number(lastMeasurementOfPeriod.score) + Number(daysFromEndtoForecastEnd * slope)],
    type: 'scatter',
    hoverinfo: 'skip',
    mode: 'lines',
    fill: forecastMode === ForecastMode.ALL ? 'tonexty' : 'none',
    fillcolor: 'rgba(0,150,0,0.05)',
    line: {
      color: color, // 'blue',
      dash: 'dot',
      width: 1,
    },
    text: ["test"],
    textposition: "auto"
  }
  plotData.push(forecastData)

  if (forecastMode === ForecastMode.ALL) {
    const minD: Plotly.Data = {
      name: 'forecast (min velocity)',
      x: [lastMeasurementOfPeriod.day, forecastEndDate],
      y: [lastMeasurementOfPeriod.score, Number(lastMeasurementOfPeriod.score) + Number(daysFromEndtoForecastEnd * quantile(velocities, MIN_QUANTILE_TO_SHOW))],
      type: 'scatter',
      hoverinfo: 'skip',
      mode: 'lines',
      fill: 'tonexty',
      fillcolor: 'rgba(150,0,0,0.05)',
      line: {
        color,
        dash: 'dot',
        width: 0.5,
      }
    }
    plotData.push(minD)
  }

  return plotData;
}