import { UIColors } from "./Colors";
export const ScaleTypography = {
  Title: {
    Big: {
      fontFamily: 'Roboto Slab',
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '32px' // 24px / 16px
      //      lineHeight: '2.25em', // 36px / 16px
    },
    Small: {
      fontFamily: 'Roboto',
      fontWeight: '700',
      fontSize: '1rem', // 16px / 16px
      lineHeight: '1.5rem', // 24px / 16px
    },
  },
  Input: {
    Label: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '0.75rem', // 12px / 16px
      lineHeight: '0.75rem',
      color: UIColors.Text.Secondary
    }
  },
  Bold: {
    Big: {
      fontFamily: 'Roboto',
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '1.5rem'
    },
    Small: {
      fontFamily: 'Roboto',
      fontWeight: '700',
      fontSize: '0.7rem',
      lineHeight: '1rem' // 16px / 16px
    }
  },
  Body: {
    Small: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '1rem'
    },
    Medium: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '1rem' // 20px / 16px
    },
    Big: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px'
    }
  }
}