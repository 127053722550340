import dayjs from 'dayjs';
import 'dayjs/locale/en'
import minMax from 'dayjs/plugin/minMax';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime'
import isBetween from 'dayjs/plugin/isBetween';

var AdvancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(AdvancedFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime)
dayjs.extend(isBetween);
dayjs.extend(minMax);

/*
const scaleLocale: ILocale = {
  ...dayjs.Ls.en, // Copy everything from English locale
  formats: {
    ...dayjs.Ls.en.formats,
    L: "YYYY-MM-DD",
  },
};
dayjs.locale(scaleLocale);
*/
export default dayjs;

